/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';
import './enums';
import '../../common/money';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "merchant_catalog": {
                "nested": {
                  "resources": {
                    "nested": {
                      "CatalogItem": {
                        "nested": {
                          "FoodAndBeverageDetails": {
                            "fields": {
                              "calorieCount": {
                                "type": "int32",
                                "id": 1,
                                "rule": "optional"
                              },
                              "dietaryPreferences": {
                                "type": "squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.DietaryPreference",
                                "id": 2,
                                "rule": "repeated"
                              },
                              "ingredients": {
                                "type": "squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.Ingredient",
                                "id": 3,
                                "rule": "repeated"
                              }
                            },
                            "nested": {
                              "DietaryPreferenceType": {
                                "values": {
                                  "DIETARY_PREFERENCE_TYPE_DO_NOT_USE": 0,
                                  "STANDARD": 1,
                                  "CUSTOM": 2
                                }
                              },
                              "DietaryPreference": {
                                "fields": {
                                  "type": {
                                    "type": "squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.DietaryPreferenceType",
                                    "id": 1,
                                    "rule": "optional"
                                  },
                                  "standardName": {
                                    "type": "squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.DietaryPreference.StandardDietaryPreference",
                                    "id": 2,
                                    "rule": "optional"
                                  },
                                  "customName": {
                                    "type": "string",
                                    "id": 3,
                                    "rule": "optional"
                                  }
                                },
                                "nested": {
                                  "StandardDietaryPreference": {
                                    "values": {
                                      "STANDARD_DIETARY_PREFERENCE_DO_NOT_USE": 0,
                                      "DAIRY_FREE": 1,
                                      "GLUTEN_FREE": 2,
                                      "HALAL": 3,
                                      "KOSHER": 4,
                                      "NUT_FREE": 5,
                                      "VEGAN": 6,
                                      "VEGETARIAN": 7
                                    }
                                  }
                                }
                              },
                              "Ingredient": {
                                "fields": {
                                  "type": {
                                    "type": "squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.DietaryPreferenceType",
                                    "id": 1,
                                    "rule": "optional"
                                  },
                                  "standardName": {
                                    "type": "squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.Ingredient.StandardIngredient",
                                    "id": 2,
                                    "rule": "optional"
                                  },
                                  "customName": {
                                    "type": "string",
                                    "id": 3,
                                    "rule": "optional"
                                  }
                                },
                                "nested": {
                                  "StandardIngredient": {
                                    "values": {
                                      "STANDARD_INGREDIENT_DO_NOT_USE": 0,
                                      "CELERY": 1,
                                      "CRUSTACEANS": 2,
                                      "EGGS": 3,
                                      "FISH": 4,
                                      "GLUTEN": 5,
                                      "LUPIN": 6,
                                      "MILK": 7,
                                      "MOLLUSCS": 8,
                                      "MUSTARD": 9,
                                      "PEANUTS": 10,
                                      "SESAME": 11,
                                      "SOY": 12,
                                      "SULPHITES": 13,
                                      "TREE_NUTS": 14
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      "ItemVariationWeight": {
                        "fields": {
                          "amount": {
                            "type": "string",
                            "id": 1,
                            "rule": "optional"
                          },
                          "weightUnit": {
                            "type": "squareup.connect.v2.merchant_catalog.resources.CatalogItemVariationWeightUnit",
                            "id": 2,
                            "rule": "optional"
                          }
                        }
                      },
                      "ServiceCost": {
                        "fields": {
                          "pricingType": {
                            "type": "squareup.connect.v2.merchant_catalog.resources.ServiceCostType",
                            "id": 1,
                            "rule": "optional"
                          },
                          "amountFlat": {
                            "type": "squareup.connect.v2.common.Money",
                            "id": 2,
                            "rule": "optional"
                          },
                          "amountPercent": {
                            "type": "string",
                            "id": 3,
                            "rule": "optional"
                          }
                        }
                      },
                      "QuantityRatio": {
                        "fields": {
                          "quantity": {
                            "type": "uint32",
                            "id": 1,
                            "rule": "optional"
                          },
                          "quantityDenominator": {
                            "type": "uint32",
                            "id": 2,
                            "rule": "optional"
                          }
                        }
                      },
                      "CatalogCustomAttributeValue": {
                        "fields": {
                          "name": {
                            "type": "string",
                            "id": 1,
                            "rule": "optional"
                          },
                          "stringValue": {
                            "type": "string",
                            "id": 2,
                            "rule": "optional"
                          },
                          "intValue": {
                            "type": "int64",
                            "id": 3,
                            "rule": "optional"
                          },
                          "customAttributeDefinitionId": {
                            "type": "string",
                            "id": 4,
                            "rule": "optional"
                          },
                          "type": {
                            "type": "squareup.connect.v2.merchant_catalog.resources.CatalogCustomAttributeDefinition.Type",
                            "id": 5,
                            "rule": "optional"
                          },
                          "numberValue": {
                            "type": "string",
                            "id": 6,
                            "rule": "optional"
                          },
                          "booleanValue": {
                            "type": "bool",
                            "id": 7,
                            "rule": "optional"
                          },
                          "selectionUidValues": {
                            "type": "string",
                            "id": 8,
                            "rule": "repeated"
                          },
                          "key": {
                            "type": "string",
                            "id": 9,
                            "rule": "optional"
                          }
                        }
                      },
                      "CatalogObjectReference": {
                        "fields": {
                          "objectId": {
                            "type": "string",
                            "id": 1,
                            "rule": "optional"
                          },
                          "catalogVersion": {
                            "type": "int64",
                            "id": 2,
                            "rule": "optional"
                          }
                        }
                      },
                      "CatalogCustomAttributeDefinition": {
                        "nested": {
                          "Type": {
                            "values": {
                              "ATTRIBUTE_TYPE_DO_NOT_USE": 0,
                              "STRING": 1,
                              "INTEGER": 2,
                              "BOOLEAN": 3,
                              "NUMBER": 9,
                              "SELECTION": 10,
                              "STRING_INT": 11,
                              "TOKEN_INT": 12
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.CatalogItem?.nested?.FoodAndBeverageDetails && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.CatalogItem?.nested?.FoodAndBeverageDetails?.nested?.DietaryPreferenceType && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.CatalogItem?.nested?.FoodAndBeverageDetails?.nested?.DietaryPreference && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.CatalogItem?.nested?.FoodAndBeverageDetails?.nested?.DietaryPreference?.nested?.StandardDietaryPreference && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.CatalogItem?.nested?.FoodAndBeverageDetails?.nested?.Ingredient && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.CatalogItem?.nested?.FoodAndBeverageDetails?.nested?.Ingredient?.nested?.StandardIngredient && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.ItemVariationWeight && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.ServiceCost && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.QuantityRatio && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.CatalogCustomAttributeValue && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.CatalogObjectReference && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog?.nested?.resources?.nested?.CatalogCustomAttributeDefinition?.nested?.Type) {
	$root.addJSON(jsonData);
}

/**
 * @desc A [CatalogObject](entity:CatalogObject) instance of the `ITEM` type, also referred to as an item, in the catalog.
 *
 * SOURCE CatalogItem @ squareup/connect/v2/catalog/resources/datatypes.proto at 700:1
 */
export const CatalogItem = {};
/**
 * @desc The food and beverage-specific details of a `FOOD_AND_BEV` item.
 *
 * SOURCE FoodAndBeverageDetails @ squareup/connect/v2/catalog/resources/datatypes.proto at 936:5
 */
CatalogItem.FoodAndBeverageDetails = $root.lookupType('squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails');
fixType(CatalogItem.FoodAndBeverageDetails);
Builder.createAndAttachToType(CatalogItem.FoodAndBeverageDetails);
/**
 * @desc The type of dietary preference for the `FOOD_AND_BEV` type of items and integredients.
 *
 * SOURCE DietaryPreferenceType @ squareup/connect/v2/catalog/resources/datatypes.proto at 943:9
 */
CatalogItem.FoodAndBeverageDetails.DietaryPreferenceType = $root.lookupEnum('squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.DietaryPreferenceType').values;
/**
 * @desc Dietary preferences that can be assigned to an `FOOD_AND_BEV` item and its ingredients.
 *
 * SOURCE DietaryPreference @ squareup/connect/v2/catalog/resources/datatypes.proto at 952:9
 */
CatalogItem.FoodAndBeverageDetails.DietaryPreference = $root.lookupType('squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.DietaryPreference');
fixType(CatalogItem.FoodAndBeverageDetails.DietaryPreference);
Builder.createAndAttachToType(CatalogItem.FoodAndBeverageDetails.DietaryPreference);
/**
 * @desc Standard dietary preferences for food and beverage items that are recommended on item creation.
 *
 * SOURCE StandardDietaryPreference @ squareup/connect/v2/catalog/resources/datatypes.proto at 957:13
 */
CatalogItem.FoodAndBeverageDetails.DietaryPreference.StandardDietaryPreference = $root.lookupEnum('squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.DietaryPreference.StandardDietaryPreference').values;
/**
 * @desc Describes the ingredient used in a `FOOD_AND_BEV` item.
 *
 * SOURCE Ingredient @ squareup/connect/v2/catalog/resources/datatypes.proto at 978:9
 */
CatalogItem.FoodAndBeverageDetails.Ingredient = $root.lookupType('squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.Ingredient');
fixType(CatalogItem.FoodAndBeverageDetails.Ingredient);
Builder.createAndAttachToType(CatalogItem.FoodAndBeverageDetails.Ingredient);
/**
 * @desc Standard ingredients for food and beverage items that are recommended on item creation.
 *
 * SOURCE StandardIngredient @ squareup/connect/v2/catalog/resources/datatypes.proto at 983:13
 */
CatalogItem.FoodAndBeverageDetails.Ingredient.StandardIngredient = $root.lookupEnum('squareup.connect.v2.merchant_catalog.resources.CatalogItem.FoodAndBeverageDetails.Ingredient.StandardIngredient').values;
/**
 * --
 * @desc Represent the weight of an ItemVariation
 * --
 *
 * SOURCE ItemVariationWeight @ squareup/connect/v2/catalog/resources/datatypes.proto at 1238:1
 */
export const ItemVariationWeight = $root.lookupType('squareup.connect.v2.merchant_catalog.resources.ItemVariationWeight');
fixType(ItemVariationWeight);
Builder.createAndAttachToType(ItemVariationWeight);
/**
 * --
 * @desc Cost of materials required to perform an appointments service.
 * --
 *
 * SOURCE ServiceCost @ squareup/connect/v2/catalog/resources/datatypes.proto at 1283:1
 */
export const ServiceCost = $root.lookupType('squareup.connect.v2.merchant_catalog.resources.ServiceCost');
fixType(ServiceCost);
Builder.createAndAttachToType(ServiceCost);
/**
 * @desc A whole number or unreduced fractional ratio.
 *
 * SOURCE QuantityRatio @ squareup/connect/v2/catalog/resources/datatypes.proto at 1613:1
 */
export const QuantityRatio = $root.lookupType('squareup.connect.v2.merchant_catalog.resources.QuantityRatio');
fixType(QuantityRatio);
Builder.createAndAttachToType(QuantityRatio);
/**
 * --
 * @desc An instance of a custom attribute. Custom attributes can be defined and
 * added to `ITEM` and `ITEM_VARIATION` type catalog objects.
 * [Read more about custom attributes](https://developer.squareup.com/docs/catalog-api/add-custom-attributes).
 * --
 *
 * SOURCE CatalogCustomAttributeValue @ squareup/connect/v2/catalog/resources/datatypes.proto at 2306:1
 */
export const CatalogCustomAttributeValue = $root.lookupType('squareup.connect.v2.merchant_catalog.resources.CatalogCustomAttributeValue');
fixType(CatalogCustomAttributeValue);
Builder.createAndAttachToType(CatalogCustomAttributeValue);
/**
 * --
 * @desc A reference to a Catalog object at a specific version. In general this is
 * used as an entry point into a graph of catalog objects, where the objects exist
 * at a specific version.
 * --
 *
 * SOURCE CatalogObjectReference @ squareup/connect/v2/catalog/resources/datatypes.proto at 3705:1
 */
export const CatalogObjectReference = $root.lookupType('squareup.connect.v2.merchant_catalog.resources.CatalogObjectReference');
fixType(CatalogObjectReference);
Builder.createAndAttachToType(CatalogObjectReference);
/**
 * --
 * @desc Contains information defining a custom attribute. Custom attributes are
 * intended to store additional information about a catalog object or to associate a
 * catalog object with an entity in another system. Do not use custom attributes
 * to store any sensitive information (personally identifiable information, card details, etc.).
 * [Read more about custom attributes](https://developer.squareup.com/docs/catalog-api/add-custom-attributes)
 * --
 *
 * SOURCE CatalogCustomAttributeDefinition @ squareup/connect/v2/catalog/resources/datatypes.proto at 3726:1
 */
export const CatalogCustomAttributeDefinition = {};
/**
 * --
 * @desc Defines the possible types for a custom attribute.
 * --
 *
 * SOURCE Type @ squareup/connect/v2/catalog/resources/datatypes.proto at 3733:5
 */
CatalogCustomAttributeDefinition.Type = $root.lookupEnum('squareup.connect.v2.merchant_catalog.resources.CatalogCustomAttributeDefinition.Type').values;
