import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import './PaymentAlternativesModal.scss';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { MarketButton } from 'src/components/Market';
import WalletIcon from 'src/svgs/WalletIcon';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { getCreateInvoiceUrl } from 'src/utils/url';

/**
 * Modal to present payment alternatives to the merchant, when their message
 * has been blocked due to containing PANs (Personal Account Numbers).
 *
 * @example
 * Basic usage:
 * <PaymentAlternativesModal />
 * @author teresalin
 */
const PaymentAlternativesModal = observer((): ReactElement => {
  const { t } = useTranslation();
  const { modal, event, transcriptView, featureFlag } =
    useMessengerControllerContext();
  const { transcript } = transcriptView;

  return (
    <MessengerModalDialog
      icon={<WalletIcon />}
      title={t('PaymentAlternativesModal.title')}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={() => {
            modal.closeModal();
            modal.openCheckoutLinkModal();
          }}
        >
          {t('PaymentAlternativesModal.send_payment_link_text')}
        </MarketButton>
      }
      secondaryButton={
        <MarketButton
          onClick={() => {
            event.track('Click Create Action', {
              action_type_name: 'send_invoice',
              needs_upgrade: false,
              transcript_id: transcript.id,
            });
            window.open(
              getCreateInvoiceUrl(
                transcript.customerToken,
                transcript.sellerKey,
                featureFlag.useAppSubdomain,
              ),
              '_blank',
            );
            modal.closeModal();
          }}
        >
          {t('PaymentAlternativesModal.send_invoice_text')}
        </MarketButton>
      }
      tertiaryButton={
        <MarketButton onClick={modal.closeModal}>
          {t('PaymentAlternativesModal.dismiss')}
        </MarketButton>
      }
      buttonGroupAlign="stack"
      close={modal.closeModal}
    >
      <div className="PaymentAlternativesModal__description">
        {t('PaymentAlternativesModal.description')}
      </div>
    </MessengerModalDialog>
  );
});

export default PaymentAlternativesModal;
