import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  MarketEmptyState,
  MarketButton,
  MarketSelect,
  MarketList,
  MarketRow,
} from 'src/components/Market';
import CheckoutLinkNullStateIcon from 'src/svgs/CheckoutLinkNullStateIcon';
import { getCheckoutLinksUrl } from 'src/utils/url';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { CheckoutLink } from 'src/MessengerTypes';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import { SHADOW_REACT_ROOT_SELECTOR } from 'src/utils/shadowDomUtils';

export type ExistingLinkTabProps = {
  selectedCheckoutLink?: CheckoutLink;
  setSelectedCheckoutLink: (link: CheckoutLink) => void;
};

/**
 * The page to render when the existing tab is selected in <CheckoutLinkModal />.
 * Handles the rendering and selection logic for existing checkout links.
 *
 * @example
 * Basic usage:
 * <ExistingLinkTab
 *   selectedCheckoutLink="123"
 *   setSelectedCheckoutLink={setLink}
 * />
 * @param {CheckoutLink} [selectedCheckoutLink]
 * (Optional) The selected checkout link.
 * @param {Function} setSelectedCheckoutLink
 * Function to call when selection of checkout link changes.
 * @author klim
 */
const ExistingLinkTab = observer(
  (props: ExistingLinkTabProps): ReactElement => {
    const { selectedCheckoutLink, setSelectedCheckoutLink } = props;
    const { checkoutLinks, featureFlag } = useMessengerControllerContext();
    const { t } = useTranslation();

    // Load the list of links if not loaded yet.
    useEffect(() => {
      if (checkoutLinks.status === 'NOT_STARTED') {
        checkoutLinks.getCheckoutLinks();
      }
    }, [checkoutLinks]);

    let content;
    switch (checkoutLinks.status) {
      case 'SUCCESS': {
        if (checkoutLinks.existingLinks.length === 0) {
          // Case: Links fetched but none are present, show null state to create link
          content = (
            <MarketEmptyState>
              <h3 slot="primary-text">
                <div className="CheckoutLinkModal__null__icon">
                  <CheckoutLinkNullStateIcon />
                </div>
                {t('CheckoutLinkModal.existing.null_state.title')}
              </h3>
              <p slot="secondary-text">
                {t('CheckoutLinkModal.existing.null_state.description')}
              </p>
              <MarketButton
                rank="primary"
                slot="actions"
                onClick={() =>
                  window.open(
                    getCheckoutLinksUrl(featureFlag.useAppSubdomain),
                    '_blank',
                  )
                }
              >
                {t('CheckoutLinkModal.existing.null_state.cta')}
              </MarketButton>
            </MarketEmptyState>
          );
        } else {
          // Case: Links fetched; have >=1 link
          // TODO(klim): Support pagination
          content = (
            <MarketSelect
              value={selectedCheckoutLink?.id}
              onClick={(e) => e.stopPropagation()}
              onMarketSelectValueDidChange={(e) => {
                const selected = checkoutLinks.existingLinks.find(
                  (link) => link.id === e.detail.value,
                ) as CheckoutLink;
                setSelectedCheckoutLink(selected);
              }}
              data-testid="CheckoutLinkModal__select"
              popoverContainer={SHADOW_REACT_ROOT_SELECTOR}
            >
              <label>{t('CheckoutLinkModal.existing.inputLabel')}</label>
              <MarketList
                slot="list"
                className="CheckoutLinkModal__select__list"
              >
                {checkoutLinks.existingLinks.map((link) => (
                  <MarketRow key={link.id} value={link.id}>
                    {link.title}
                  </MarketRow>
                ))}
              </MarketList>
            </MarketSelect>
          );
        }
        break;
      }
      case 'ERROR': {
        // TODO(klim): Enable to retry if loading fails
        content = (
          <p>{t('CheckoutLinkModal.error.failed_to_load_description')}</p>
        );
        break;
      }
      case 'LOADING':
      default:
        content = <LoadingIndicator />;
    }

    return content;
  },
);

export default ExistingLinkTab;
