import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  KEY_MESSAGES_PLUS,
  KEY_VOICEMAIL_CUSTOMIZATION,
} from 'src/stores/FeatureFlagStore';
import { Unit } from 'src/MessengerTypes';
import VoicemailNumberRow from 'src/components/VoicemailNumberRow/VoicemailNumberRow';
import BusinessNumberRow from 'src/components/BusinessNumberRow/BusinessNumberRow';
import VerificationRow from 'src/components/NumberRow/components/VerificationRow/VerificationRow';

export type NumberRowProps = {
  unit: Unit;
};

/**
 * Component that selects the correct row UI to display for a given number/unit.
 *
 * @param {Unit} unit
 * The unit to render in the row.
 */
const NumberRow = observer(({ unit }: NumberRowProps): ReactElement => {
  const { featureFlag, event, subscription } = useMessengerControllerContext();

  const isMessagesPlusEnabled = featureFlag.get(KEY_MESSAGES_PLUS);

  if (
    isMessagesPlusEnabled &&
    (subscription.isUnitPendingVerification(unit.token) ||
      subscription.isUnitFailedNonretryableAndNotProhibited(unit.token))
  ) {
    return (
      <VerificationRow
        unitName={unit.name}
        unitToken={unit.token}
        variant="PENDING"
      />
    );
  }

  if (
    isMessagesPlusEnabled &&
    subscription.isUnitRetryableFailure(unit.token)
  ) {
    return (
      <VerificationRow
        unitName={unit.name}
        unitToken={unit.token}
        variant="FAILED_RETRYABLE"
      />
    );
  }

  if (featureFlag.get(KEY_VOICEMAIL_CUSTOMIZATION)) {
    return <VoicemailNumberRow unit={unit} />;
  }

  return (
    <BusinessNumberRow
      unit={unit}
      onCopy={() => {
        event.track('Click Settings Number Action', {
          free_trial: subscription.isFreeTrial,
          action_type_name: 'copy',
        });
      }}
    />
  );
});

export default NumberRow;
