import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketAccessory, MarketBanner } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import TranscriptsListSkeletonState from 'src/components/TranscriptsListSkeletonState/TranscriptsListSkeletonState';
import TranscriptsList from 'src/components/TranscriptsList/TranscriptsList';
import TranscriptsListItem from 'src/components/TranscriptsListItem/TranscriptsListItem';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import EmailIcon from 'src/svgs/EmailIcon';
import SmsIcon from 'src/svgs/SmsIcon';
import TranscriptsListStore from 'src/stores/TranscriptsListStore';
import { TranscriptMessengerPage } from 'src/MessengerTypes';
import './TranscriptsHistoryList.scss';

export type TranscriptsHistoryListProps = {
  history: TranscriptsListStore;
};

/**
 * Component that renders the list of transcripts shown in the conversations history
 * section found on the customer detail view.
 *
 * @example <TranscriptsHistoryList history={history} />
 * @param {TranscriptsListStore} history
 * The transcripts list store that contains the set of transcripts to display.
 */
const TranscriptsHistoryList = observer(
  ({ history }: TranscriptsHistoryListProps): ReactElement => {
    const { navigation, transcripts, user, event } =
      useMessengerControllerContext();
    const { t } = useTranslation();
    const page = navigation.navStoreForUrl
      .currentPage as TranscriptMessengerPage;

    if (history.status === 'LOADING') {
      return <TranscriptsListSkeletonState />;
    }

    if (history.status === 'ERROR') {
      return (
        <MarketBanner variant="critical">
          {t('TranscriptsHistoryList.error_label')}
        </MarketBanner>
      );
    }

    if (history.size === 0) {
      return (
        <p className="TranscriptsHistoryList__empty paragraph-30">
          {t('TranscriptsHistoryList.empty_conversation_history')}
        </p>
      );
    }

    return (
      <div className="TranscriptsHistoryList">
        <TranscriptsList transcriptsList={history} initialScrollPosition={0}>
          {history.ids.map((id: number) => {
            const transcript = transcripts.get(id);
            const displayName: string =
              user.units.get(transcript.sellerKey)?.name ||
              t('TranscriptsHistoryList.unknown_location_label');

            const accessory = (
              <MarketAccessory slot="leading-accessory" size="icon">
                {transcript.medium === Medium.EMAIL && <EmailIcon />}
                {transcript.medium === Medium.SMS && <SmsIcon />}
              </MarketAccessory>
            );

            return (
              <TranscriptsListItem
                key={id}
                className="TranscriptsHistoryList__item"
                transcript={transcript}
                accessory={accessory}
                title={displayName}
                onClick={() => {
                  event.track('Click Conversation in Conversation History');
                  navigation.openTranscript(
                    transcript.id,
                    'conversations_history_list',
                  );
                }}
                isSelected={transcript.id === page?.transcriptId}
              />
            );
          })}
        </TranscriptsList>
      </div>
    );
  },
);

export default TranscriptsHistoryList;
