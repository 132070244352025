import React, { ReactElement, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import SearchIcon from 'src/svgs/SearchIcon';
import ClearIcon from 'src/svgs/ClearIcon';
import './SearchBar.scss';
import { MarketAccessory, MarketInputText } from 'src/components/Market';
import { MarketInputValueChangeEvent } from 'src/MarketTypes';

export type SearchBarProps = {
  query: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  onClear?: () => void;
  onClick?: () => void;
};

/**
 * An input bar styled as a search bar (with a magnifying
 * glass and clear button).
 *
 * @example
 * Basic usage:
 * <SearchBar query="" onChange={() => this.navigate('MY_PAGE')} />
 *
 * With optional
 * <SearchBar
 *   query={query}
 *   onChange={(newQuery) => setQuery(newQuery)}
 *   autoFocus
 *   onClear={() => exitSearch()}
 *   onClick={() => track('Click')}
 * />
 * @param {string} query - input bar value
 * @param {(string) => void} onChange - called with the new value
 * whenever the input bar updates
 * @param {boolean} [autoFocus]
 * Will focus the bar on render if true
 * @param {() => void} [onClear] - an optional function to call
 * when the input bar is cleared (either by deleting all characters
 * or hitting the clear button)
 * @param {() => void} [onClick]
 * (Optional) Called when input is clicked. Used mostly for event tracking
 * purposes.
 * @author eblaine
 */
const SearchBar = observer((props: SearchBarProps): ReactElement => {
  const { onChange, query, autoFocus, onClear, onClick } = props;
  const { t } = useTranslation();
  const inputRef = useRef<HTMLMarketInputTextElement>(null);

  // Focus when autofocus is true
  useEffect(() => {
    if (autoFocus && inputRef && inputRef.current) {
      inputRef.current.setFocus();
    }
  }, [autoFocus]);

  return (
    <div className="SearchBar">
      <MarketInputText
        value={query}
        onMarketInputValueChange={(event: MarketInputValueChangeEvent) =>
          onChange(event.detail.value)
        }
        onClick={onClick}
        placeholder={t('NewMessagePage.search_placeholder')}
        ref={inputRef}
        className="SearchBar__input"
        data-testid="SearchBar__input"
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <SearchIcon />
        </MarketAccessory>
        {/* Only render the clear icon if the user has typed a query */}
        {query !== '' && (
          <MarketAccessory
            slot="trailing-accessory"
            onClick={() => {
              onChange('');
              if (onClear) {
                onClear();
              }
            }}
            className="SearchBar__clear"
            data-testid="SearchBar__clear"
            size="icon"
          >
            <ClearIcon />
          </MarketAccessory>
        )}
      </MarketInputText>
    </div>
  );
});

export default SearchBar;
