import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import './RequestConsentModal.scss';
import { CONSENT_URL } from 'src/utils/url';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { MarketButton } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { DEFAULT_STATUS_TIMEOUT } from 'src/stores/StatusStore';
import {
  RequestConsentResponse,
  Status as ResponseStatus,
} from 'src/gen/squareup/messenger/v3/messenger_service';

/**
 * A modal that allows merchant to request consent when the marketing classifier
 * blocks an utterance from being sent.
 *
 * @example
 * Basic usage:
 * <RequestConsentModal />
 * @author klim
 */
const RequestConsentModal = observer((): ReactElement => {
  const { modal, event, status, transcriptView } =
    useMessengerControllerContext();
  const { requestConsentUtteranceId, closeModal, openMessageBlockedModal } =
    modal;
  const { transcript } = transcriptView;
  const { t } = useTranslation();

  useEffect(() => {
    event.track('View Looks Like Marketing Modal', {
      transcript_id: transcript.id,
    });
  }, [event, transcript.id]);

  // State for requesting
  const [isRequesting, setIsRequesting] = useState(false);
  const request = async (): Promise<void> => {
    setIsRequesting(true);
    event.track('Click Looks Like Marketing Modal Request Consent', {
      transcript_id: transcript.id,
    });
    try {
      await transcript.requestConsent();
      status.setSuccess({
        label: t('RequestConsentModal.request_sent'),
      });
      closeModal();
    } catch (error) {
      if (
        (error as RequestConsentResponse)?.status?.code ===
        ResponseStatus.Code.BLOCKED_BY_SELLER
      ) {
        closeModal();
        // setTimeout used to avoid flash in UI due to immediate close and open
        setTimeout(openMessageBlockedModal, 200);
        return;
      }
      status.setModalError();
    }
    setIsRequesting(false);
  };

  // State for sending feedback
  const [feedbackSent, setFeedbackSent] = useState(false);
  const sendFeedback = (): void => {
    setFeedbackSent(true);
    if (requestConsentUtteranceId) {
      event.track('Click Request Consent Feedback', {
        utterance_id: requestConsentUtteranceId,
      });
    }
    status.set({
      label: t('RequestConsentModal.feedback_sent'),
      type: 'SUCCESS',
      display: 'BANNER',
      scope: 'MODAL',
      timerMillis: DEFAULT_STATUS_TIMEOUT,
    });
  };

  let feedbackDescription;
  if (!feedbackSent && requestConsentUtteranceId) {
    feedbackDescription = (
      <p>
        <Trans i18nKey="RequestConsentModal.feedback_description">
          {"If this message doesn't contain marketing content, "}
          <span className="RequestConsentModal__link" onClick={sendFeedback}>
            let us know
          </span>
          .
        </Trans>
      </p>
    );
  }

  const onClose = (): void => {
    closeModal();
    event.track('Click Looks Like Marketing Modal Dismiss', {
      transcript_id: transcript.id,
    });
  };

  return (
    <MessengerModalDialog
      title={t('RequestConsentModal.title')}
      close={onClose}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={request}
          isLoading={isRequesting || undefined}
          role="button"
        >
          {t('RequestConsentModal.title')}
        </MarketButton>
      }
      secondaryButton={
        <MarketButton onClick={onClose}>{t('common.dismiss')}</MarketButton>
      }
      status={status.value}
    >
      <p>
        <Trans i18nKey="RequestConsentModal.description">
          {
            "To send texts about sales, promotions, coupons and more, you'll need your customer's consent. "
          }
          <a
            className="RequestConsentModal__link"
            href={CONSENT_URL}
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn more
          </a>
        </Trans>
      </p>
      {feedbackDescription}
    </MessengerModalDialog>
  );
});

export default RequestConsentModal;
