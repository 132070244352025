import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton, MarketHeader } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  CustomerDetailMessengerPage,
  Status,
  Transition,
} from 'src/MessengerTypes';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import BackIcon from 'src/svgs/MarketBackIcon';
import CustomerInfo from './components/CustomerInfo/CustomerInfo';
import MoreMenu from './components/MoreMenu/MoreMenu';
import TranscriptsHistoryList from './components/TranscriptsHistoryList/TranscriptsHistoryList';
import './CustomerDetailPage.scss';
import CustomerDetailSkeletonState from './components/CustomerDetailSkeletonState/CustomerDetailSkeletonState';

export type CustomerDetailPageProps = {
  transitionDirection: Transition;
};

/**
 * Page used to display information regarding an individual customer.
 * Also contains the conversation history with the customer.
 */
const CustomerDetailPage = observer(
  ({ transitionDirection }: CustomerDetailPageProps): ReactElement | null => {
    const { navigation, transcripts, customers, status, transcriptsHistory } =
      useMessengerControllerContext();
    const { t } = useTranslation();

    const nav = navigation.tertiary.isOpen
      ? navigation.tertiary
      : navigation.primary;
    const page = nav.currentPage as CustomerDetailMessengerPage;

    if (!page || !page.transcriptId || !page.customerToken) {
      return null;
    }

    const transcript = transcripts.get(page.transcriptId);
    const customer = customers.get(page.customerToken);
    const history = transcriptsHistory.get(page.customerToken);
    const isLoading =
      transcript.customerDetailsStatus === 'LOADING' || !customer;

    let statusValue: Status | null;
    if (transcript.customerDetailsStatus === 'ERROR') {
      statusValue = {
        scope: 'BLADE',
        display: 'SCREEN',
        type: 'ERROR',
        label: t('common.error.try_again'),
        action: {
          action: transcript.loadCustomers,
        },
        actionLabel: t('common.retry'),
      };
    } else {
      // Hide status on the full page as otherwise both views on the full page show separate status banners
      statusValue = !navigation.secondary.isOpen ? status.value : null;
    }

    return (
      <>
        <MarketHeader
          className="MessagesPageHeader CustomerDetailPage__header"
          compact
        >
          <div slot="navigation">
            {nav.canNavigateBack && (
              <MarketButton
                aria-label={t('common.back')}
                onClick={nav.navigateBack}
              >
                <BackIcon slot="icon" />
              </MarketButton>
            )}
          </div>
          {!navigation.secondary.isOpen && <h2>{customer?.name}</h2>}
          <div slot="actions">
            {customer && <MoreMenu customer={customer} />}
          </div>
        </MarketHeader>
        <MessengerContent
          transitionDirection={transitionDirection}
          isLoading={isLoading}
          loadingIndicator={<CustomerDetailSkeletonState />}
          status={statusValue}
        >
          <div className="CustomerDetailPage__content-container">
            <div className="CustomerDetailPage__info-container">
              {customer && <CustomerInfo customer={customer} />}
              {history &&
                (history.status === 'LOADING' ? (
                  <div className="CustomerDetailSkeletonState__conv-history-title" />
                ) : (
                  history.size > 0 && (
                    <h3 className="CustomerDetailPage__conv-history-title">
                      {t('CustomerDetailPage.conversation_history_title')}
                    </h3>
                  )
                ))}
            </div>
            {history && <TranscriptsHistoryList history={history} />}
          </div>
        </MessengerContent>
      </>
    );
  },
);

export default CustomerDetailPage;
