/**
 * An icon representing gif.
 */
import React, { ReactElement } from 'react';

function GifIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      fill="none"
      viewBox="0 0 22 21"
    >
      <path
        fill="#000"
        fillOpacity=".9"
        d="M16.997 3V0h2v3h3v2h-3v3h-2V5h-3V3h3Zm-3 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-6.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm2.5 4a3.001 3.001 0 0 1-2.782-1.875l-1.854.75a5.001 5.001 0 0 0 9.272 0l-1.854-.75A3.001 3.001 0 0 1 9.997 15Z"
      />
      <path
        fill="#000"
        fillOpacity=".9"
        d="M2.997 12a7 7 0 0 1 7-7h2V3h-2a9 9 0 1 0 9 9v-2h-2v2a7 7 0 1 1-14 0Z"
      />
    </svg>
  );
}

export default GifIcon;
