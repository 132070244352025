import type MessengerController from 'src/MessengerController';
import type Api from 'src/api/Api';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  CurrentEmployee,
  LoadingStatus,
  SupportedCountry,
  SupportedLocale,
  Unit,
} from 'src/MessengerTypes';
import {
  MessagesAuthenticationError,
  MessagesAuthorizationError,
} from 'src/types/Errors';
import { currencyCodeToSymbol } from 'src/utils/moneyUtils';
import {
  filterAndSortUnits,
  isSupportedLocale,
  isPendingDedicatedNumberStatus,
  unitsComparator,
} from 'src/stores/UserStore/utils';

/**
 * Store responsible for managing the state of the current user.
 */
class UserStore {
  private _stores: MessengerController;
  private _api: Api;

  status: LoadingStatus = 'LOADING';

  isAuthorized = true;

  isAuthenticated = true;

  merchantToken = '';

  mainUnitToken = '';

  countryCode: SupportedCountry = 'US';

  currencyCode = '';

  locale: SupportedLocale;

  businessName = '';

  timezone = '';

  currentEmployee: CurrentEmployee = {} as CurrentEmployee;

  unreadTranscriptsCount = 0;

  isIdvComplete = false;

  // A map of unit token to the unit details, which contains the name and address of that unit
  units: Map<string, Unit> = new Map();

  constructor(stores: MessengerController) {
    makeAutoObservable<UserStore, '_fetchCurrentUserData'>(this);

    this._stores = stores;
    this._api = stores.api;

    this.locale = isSupportedLocale(navigator.language)
      ? navigator.language
      : 'en-US';
    this._setHtmlLangValue();

    this._fetchCurrentUserData().then(() => {
      this.loadUnreadTranscriptsCount();
    });
  }

  get isInitialized(): boolean {
    return this.status !== 'LOADING';
  }

  get currencySymbol(): string {
    return currencyCodeToSymbol(this.currencyCode, this.locale);
  }

  get isSingleUnit(): boolean {
    return this.activeUnits.length <= 1;
  }

  setCurrencyCode = (currencyCode: string): void => {
    this.currencyCode = currencyCode;
  };

  setMerchantToken = (merchantToken: string): void => {
    this.merchantToken = merchantToken;
  };

  setTimezone = (timezone: string): void => {
    this.timezone = timezone;
  };

  /**
   * Helper method to call the API to load the current user data
   * and set the response values in state. Sets the isAuthenticated
   * and isAuthorized values correspondingly if errors are thrown.
   *
   * @returns {Promise<void>}
   */
  private _fetchCurrentUserData = async (): Promise<void> => {
    this.status = 'LOADING';
    try {
      const [currentUser, units] = await Promise.all([
        this._api.merchant.getCurrentUserData(),
        this._api.merchant.getUnits(),
      ]);

      runInAction(() => {
        this.merchantToken = currentUser.merchantToken;
        this.mainUnitToken = currentUser.mainUnitToken;
        this.countryCode = currentUser.countryCode;
        this.currencyCode = currentUser.currencyCode;
        this.businessName = currentUser.businessName;
        this.timezone = currentUser.timezone;
        this.currentEmployee = currentUser.currentEmployee;
        this.status = 'SUCCESS';
      });

      units.forEach((unit) => {
        this.setUnit(unit.token, {
          ...unit,
          address: `${this.businessName} ${unit.address}`,
        });
      });
    } catch (error) {
      if (error instanceof MessagesAuthenticationError) {
        runInAction(() => {
          this.isAuthenticated = false;
        });
      }
      if (error instanceof MessagesAuthorizationError) {
        runInAction(() => {
          this.isAuthorized = false;
        });
      }

      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  };

  loadUnreadTranscriptsCount = async (): Promise<void> => {
    const count = await this._api.transcripts.getUnreadTranscriptsCount(
      this.merchantToken,
    );
    runInAction(() => {
      this.unreadTranscriptsCount = count;
    });
  };

  /**
   * Sets the lang value on the html element if not already set.
   * Typically, this is already set where ever the blade is used but
   * not on the full page app, so we will set this to the browser language.
   */
  private _setHtmlLangValue = (): void => {
    // Set language code on HTML element only if not already present
    const htmlElement = document.querySelector('html');
    if (htmlElement?.getAttribute('lang') === null) {
      htmlElement?.setAttribute('lang', this.locale);
    }
  };

  /**
   * Sets a unit with partial information. Overrides existing information if there is
   * an overlap. If the unit is not defined yet, set with default values.
   *
   * @param {string} unitToken - the token of the unit to set
   * @param {Unit} unit - a partial unit information you want to set
   */
  setUnit = (unitToken: string, unit: Partial<Unit>): void => {
    const existingUnit = this.units.get(unitToken);
    this.units.set(unitToken, {
      token: unitToken,
      name: '',
      address: '',
      isActive: false,
      businessName: '',
      ...existingUnit,
      ...unit,
    });
  };

  /**
   * Returns a filtered list of the units that have dedicated numbers and are active and subscribed,
   * and unsubscribed units that are pending, failed retryable, or failed nonretryable.
   */
  get unitsWithPendingOrDedicatedNumbers(): Unit[] {
    const units: Unit[] = [];
    this.units.forEach((unit) => {
      if (
        unit.subscription?.dedicatedNumber?.dedicatedNumber &&
        unit.subscription.isSubscribed &&
        unit.isActive
      ) {
        units.push(unit);
      }
    });

    units.push(...this.unitsWithPendingDedicatedNumbers);

    return units.sort(unitsComparator);
  }

  /**
   * Returns all the units that has a dedicated number that is pending TFV.
   * The unit has to not be subscribed, and is active.
   */
  get unitsWithPendingDedicatedNumbers(): Unit[] {
    const units: Unit[] = [];
    this.units.forEach((unit) => {
      if (
        !unit.subscription?.isSubscribed &&
        isPendingDedicatedNumberStatus(
          unit.subscription?.dedicatedNumber?.status,
        ) &&
        unit.isActive
      ) {
        units.push(unit);
      }
    });
    return units.sort(unitsComparator);
  }

  /**
   * Returns a filtered list of the units that are pending cancellation from their M+ subscription.
   */
  get unitsPendingCancellation(): Unit[] {
    const units: Unit[] = [];
    this.units.forEach((unit) => {
      if (unit.subscription?.isPendingCancellation) {
        units.push(unit);
      }
    });
    return units;
  }

  /**
   * Returns a filtered list of active units, sorted by unit name and grouped by M+ status.
   * Units with M+ comes first.
   */
  get activeUnits(): Unit[] {
    return [...this.unitsWithMessagesPlus, ...this.unitsWithoutMessagesPlus];
  }

  /**
   * Returns a flag indicating if the current merchant has more than one active unit.
   */
  get isMultiUnit(): boolean {
    return this.activeUnits.length > 1;
  }

  /**
   * Returns a filtered list of the units that are subscribed to M+.
   * Only includes units that are active, and are sorted in alphabetical order.
   */
  get unitsWithMessagesPlus(): Unit[] {
    return filterAndSortUnits([...this.units.values()], (unit) =>
      Boolean(unit.subscription?.isSubscribed),
    );
  }

  /**
   * Returns a filtered list of the units that are not subscribed to M+.
   * Only includes units that are active, and are sorted in alphabetical order.
   */
  get unitsWithoutMessagesPlus(): Unit[] {
    return filterAndSortUnits(
      [...this.units.values()],
      (unit) => !unit.subscription?.isSubscribed,
    );
  }

  /**
   * Refreshes the IDV completion status for the current merchant.
   */
  getIdvCompletionStatus = async (): Promise<boolean> => {
    const completed = await this._api.merchant.getIdvCompletionStatus();
    this.isIdvComplete = completed ?? false;
    return this.isIdvComplete;
  };
}

export default UserStore;
