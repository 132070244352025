import { when, makeObservable, observable, action } from 'mobx';
import { Bucket } from './gen/squareup/messenger/v3/messenger_service';
import Logger from './Logger';
import { MessengerPageName } from './MessengerTypes';
import ModalController from './ModalController';
import FeatureFlagStore, {
  KEY_USE_MESSAGING,
} from 'src/stores/FeatureFlagStore';
import {
  getEnvironment,
  initDatadogRum,
  initEcosystemHeader,
} from './utils/initUtils';
import setupI18n from './i18n/setupI18n';
import {
  getLoginPageWithReturnToUrl,
  INBOX_PROD_ORIGIN,
  INBOX_STAGING_ORIGIN,
  getLaunchPadUrl,
} from './utils/url';
import MessengerNavigationStore from 'src/stores/navigation/MessengerNavigationStore';
import Services from './services/Services';
import Api from './api/Api';
import UserStore from 'src/stores/UserStore/UserStore';
import EventTrackingStore from 'src/stores/EventTrackingStore';
import SearchContactsStore from 'src/stores/SearchContactsStore';
import SettingsStore from 'src/stores/SettingsStore';
import StatusStore from 'src/stores/StatusStore';
import TranscriptsListStore from 'src/stores/TranscriptsListStore';
import TranscriptsStore from 'src/stores/TranscriptsStore';
import NotificationsStore from 'src/stores/NotificationsStore';
import TranscriptViewStore from 'src/stores/ui/TranscriptViewStore';
import CustomersStore from 'src/stores/CustomersStore';
import SubscriptionStore from 'src/stores/SubscriptionStore';
import UnitVerificationStore from 'src/stores/ui/UnitVerificationStore/UnitVerificationStore';
import TooltipStore from 'src/stores/TooltipStore';
import SoundRecordingStore from 'src/stores/SoundRecordingStore';
import SearchStore from 'src/stores/SearchStore';
import ExperimentStore from 'src/stores/ExperimentStore';
import CheckoutLinksStore from 'src/stores/CheckoutLinksStore';
import { LocalUtterancesStore } from 'src/stores/LocalUtterancesStore';
import SearchLoggingStore from 'src/stores/SearchLoggingStore';
import { SessionStore } from 'src/stores/SessionStore';

// TODO(wdetlor): Rename MessengerController to Stores
export default class MessengerController {
  /**
   * Controls the modals in Messenger.
   */
  modal: ModalController;

  /**
   * Controls tooltip state using localStorage and respecting OneTrust.
   */
  tooltip: TooltipStore;

  /**
   * Used to track the scroll positions of list pages so that we can return to the
   * same position between navigations. This will reset whenever the list changes, for example
   * when new utterances updates the conversations.
   */
  listScrollPosition: Partial<Record<MessengerPageName, number>> = {};

  /**
   * Flag indicating whether the root Messages store is initialized.
   */
  isInitialized = false;

  /**
   * Reference to the object that defines all the services used.
   */
  services: Services;

  /**
   * Reference to the object defining all the API's used.
   */
  api: Api;

  /**
   * The store containing the logic to track user events.
   */
  event: EventTrackingStore;

  /**
   * The store containing all user meta data.
   */
  user: UserStore;

  /**
   * The store containing the feature flags and their values for the current merchant.
   */
  featureFlag: FeatureFlagStore;

  /**
   * The store containing the global app status state.
   */
  status: StatusStore;

  /**
   * Controls the drawer and page navigation.
   */
  navigation: MessengerNavigationStore;

  /**
   * The store containing the state for the search results on the transcripts list page.
   * This will be replaced by searchV2 after the new search is fully rolled out.
   * TODO (teresalin): Remove this after the new search is fully rolled out.
   */
  search: SearchContactsStore;

  /**
   * The store containing the state for the search results on the new message page.
   */
  newMessageSearch: SearchContactsStore;

  /**
   * The store containing the state for revamped search feature that combines both
   * utterances and contacts search results.
   */
  searchV2: SearchStore;

  /**
   * The store containing the state for logging search (v2) events.
   */
  searchLogger: SearchLoggingStore;

  /**
   * The store containing the settings states.
   */
  settings: SettingsStore;

  /**
   * The store containing references to all the transcript objects
   */
  transcripts: TranscriptsStore;

  /**
   * The store containing the state of the transcripts list.
   */
  transcriptsList: TranscriptsListStore;

  /**
   * The store containing the state of the assistant transcripts list.
   */
  assistantTranscriptsList: TranscriptsListStore;

  /**
   * The store containing the state and lifecycle of push updates for the app.
   */
  notifications: NotificationsStore;

  /**
   * The store containing the shared state of the transcript view and underlying UI components.
   */
  transcriptView: TranscriptViewStore;

  /**
   * The store containing cached data on individual customers.
   */
  customers: CustomersStore;

  /**
   * The mapping of customer tokens to lists of transcripts representing the conversation history for that customer.
   */
  transcriptsHistory: Map<string, TranscriptsListStore> = new Map();

  /**
   * The store containing Messages Plus subscription states.
   */
  subscription: SubscriptionStore;

  /**
   * The store containing Messages Plus unit verification form and UI states.
   */
  unitVerification: UnitVerificationStore;

  /**
   * The store used to record audio from the user. One use case is for recording new voicemail messages.
   */
  soundRecording: SoundRecordingStore;

  /**
   * The store containing experiments from Optimizely.
   */
  experiments: ExperimentStore;

  /**
   * The store that handles setting up checkout links.
   */
  checkoutLinks: CheckoutLinksStore;

  /**
   * The store that handles locally stored utterances, such as during sending or failed sent.
   */
  localUtterances: LocalUtterancesStore;

  /**
   * The store that handles the current user session.
   */
  session: SessionStore;

  /**
   * A constructor to initialize any variables that need to be initialized after the class
   * is completely loaded.
   *
   * @param {Services} [overrideServices] - overrides the services instance used, intended only as a helper for testing
   * @param {Record<string, boolean>} [overrideFeatureFlags] - overrides the feature flag values, intended only as a helper for testing
   */
  constructor(
    overrideServices?: Services,
    overrideFeatureFlags?: Record<string, boolean>,
  ) {
    makeObservable(this, {
      isInitialized: observable,
      init: action,
    });

    this._initDatadog();
    Logger.initSentry(getEnvironment());

    this.services = overrideServices || new Services();
    this.api = new Api(this.services);
    this.session = new SessionStore(this);
    this.user = new UserStore(this);
    this.featureFlag = new FeatureFlagStore(this);
    this.experiments = new ExperimentStore(this);
    this.event = new EventTrackingStore(this);
    this.status = new StatusStore(this);
    this.navigation = new MessengerNavigationStore(this);
    this.search = new SearchContactsStore(this, false);
    this.newMessageSearch = new SearchContactsStore(this);
    this.searchV2 = new SearchStore(this);
    this.searchLogger = new SearchLoggingStore(this);
    this.settings = new SettingsStore(this);
    this.transcripts = new TranscriptsStore(this);
    this.transcriptsList = new TranscriptsListStore(this, {
      bucket: Bucket.ACTIVE,
    });
    this.assistantTranscriptsList = new TranscriptsListStore(this, {
      bucket: Bucket.ASSISTANT,
    });
    this.notifications = new NotificationsStore(this);
    this.transcriptView = new TranscriptViewStore(this);
    this.customers = new CustomersStore(this);
    this.subscription = new SubscriptionStore(this);
    this.unitVerification = new UnitVerificationStore(this);
    this.tooltip = new TooltipStore(this);
    this.modal = new ModalController(this.event);
    this.soundRecording = new SoundRecordingStore(this);
    this.checkoutLinks = new CheckoutLinksStore(this);
    this.localUtterances = new LocalUtterancesStore(this);

    if (overrideFeatureFlags) {
      this.featureFlag.setOverrides(overrideFeatureFlags);
    }
  }

  /**
   * Initialization logic that should only occur after the user and feature flag data is loaded.
   */
  init = async (): Promise<void> => {
    if (this.isInitialized) {
      // No-op if the root store has already been initialized
      return;
    }
    this.isInitialized = true;

    await when(() => this.user.isInitialized && this.featureFlag.isInitialized);

    this.checkoutLinks.init();
    this.transcriptsList.init();
    this.assistantTranscriptsList.init();
    this.newMessageSearch.init();
    this.search.init();
    this.settings.init();
    this.tooltip.init();
    this.searchV2.init();
  };

  /**
   * Initialize the Datadog RUM instance if we are on the full page Inbox and not in
   * a development env. This is called by the constructor so that datadog is
   * initialized before the first network requests are made by individual stores.
   */
  private _initDatadog = (): void => {
    if (
      window.location.origin === INBOX_PROD_ORIGIN ||
      window.location.origin === INBOX_STAGING_ORIGIN
    ) {
      initDatadogRum();
    }
  };

  setListScrollPosition = (
    pageId: MessengerPageName,
    listScrollPosition: number,
  ): void => {
    this.listScrollPosition[pageId] = listScrollPosition;
  };

  /**
   * Configures the CSRF token in the csrf-token meta tag for use with the ecosystem-header.
   * Only async in development where /mp/status is called to get the initial CSRF token.
   */
  _initCsrfToken = async (): Promise<void> => {
    // Need to handle async case for development mode when CSRF token
    // may be loaded from call to /mp/status
    const token = await this.services.csrfToken;

    // Set CSRF token in meta tag for use by ecosystem header
    const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
    csrfMetaTag?.setAttribute('content', token || '');
  };

  /**
   * Method containing the initialization logic for the Messages Full Page App.
   *
   * @param {boolean} isMobile
   * Flag indicating if the application is on a mobile sized screen.
   * @returns {boolean} - Flag indicating if the setup was a success.
   */
  setupFullPageApp = async (isMobile: boolean): Promise<boolean> => {
    await when(() => this.user.isInitialized);

    if (!this.user.isAuthenticated) {
      // If the user isn't authenticated, redirect to the login page
      await when(() => this.featureFlag.isInitialized);
      window.location.replace(
        getLoginPageWithReturnToUrl(this.featureFlag.useAppSubdomain),
      );
      return false;
    }

    if (!this.user.isAuthorized) {
      // If the user doesn't have permissions to access Messages, redirect to launchpad
      await when(() => this.featureFlag.isInitialized);
      window.location.replace(
        getLaunchPadUrl(this.featureFlag.useAppSubdomain),
      );
      return false;
    }

    if (this.user.status === 'ERROR') {
      // If the user status is in an error state, we won't have a merchant token to
      // use for subsequent calls so bail out early and display an error to the user
      await setupI18n(this.user.locale);
      this.modal.openModal('UNEXPECTED_ERROR');
      return false;
    }

    this.init();

    const environment = getEnvironment();
    this.notifications.init(environment);
    this.session.monitorMultipassStatus();
    this.event.init(environment, 'FULL_PAGE_INBOX');
    this.event.track('View Full Page Inbox');

    await setupI18n(this.user.locale);

    await this._initCsrfToken();

    initEcosystemHeader(this.user.locale);

    await when(() => this.featureFlag.isInitialized);

    if (!this.featureFlag.get(KEY_USE_MESSAGING)) {
      // If Messages is not launched in a region, redirect to launchpad
      window.location.replace(
        getLaunchPadUrl(this.featureFlag.useAppSubdomain),
      );
      return false;
    }

    await when(() => this.subscription.isInitialized);

    this.navigation.openMessengerFullPage(isMobile);

    return true;
  };
}
