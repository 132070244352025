import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { getLoginPageWithReturnToUrl } from 'src/utils/url';

/**
 * Dialog that informs the user that their session has timed out and that they need to reauthenticate.
 *
 * @example <MessengerEmptyState track={messenger.track} navigateTo={messenger.navigateTo} />
 * @returns {ReactElement}
 */
const SessionTimeoutDialog = observer((): ReactElement => {
  const { featureFlag } = useMessengerControllerContext();
  const { t } = useTranslation();

  const onClose = (): void => {
    window.open(
      getLoginPageWithReturnToUrl(featureFlag.useAppSubdomain),
      '_self',
    );
  };

  return (
    <MessengerModalDialog
      title={t('SessionTimeoutDialog.title')}
      close={onClose}
      primaryButton={
        <MarketButton rank="primary" onClick={onClose}>
          {t('SessionTimeoutDialog.sign_in')}
        </MarketButton>
      }
    >
      {t('SessionTimeoutDialog.message')}
    </MessengerModalDialog>
  );
});

export default SessionTimeoutDialog;
