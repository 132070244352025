import React, { ReactElement, RefObject, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { MarketAccessory, MarketInputText } from 'src/components/Market';
import { MarketInputValueChangeEvent } from 'src/MarketTypes';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import SearchIcon from 'src/svgs/SearchIcon';
import MarketBackIcon from 'src/svgs/MarketBackIcon';
import MarketCloseIcon from 'src/svgs/MarketCloseIcon';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import './SearchBarV2.scss';
import SearchAssistantToggle from 'src/components/SearchAssistantToggle/SearchAssistantToggle';

const SEARCH_MAX_CHARACTER_COUNT = 50;

export type SearchBarV2Props = {
  inputRef?: RefObject<HTMLMarketInputTextElement>;
  onNavigateBack?: () => void;
};

/**
 * An input bar styled as a search bar (with a magnifying-glass and clear button).
 *
 * @param {RefObject} [inputRef]
 * (Optional) Ref to the search bar input element, used to blur the input when search is exited.
 * @param {Function} [onNavigateBack]
 * (Optional) Callback to be called when the search back button is clicked.
 */
const SearchBarV2 = observer(
  ({ inputRef, onNavigateBack }: SearchBarV2Props): ReactElement => {
    const { navigation, searchV2, searchLogger } =
      useMessengerControllerContext();
    const { t } = useTranslation();
    const { query, setQuery } = searchV2;

    const isOpen = navigation.primary.currentPageName === 'SEARCH';

    const onClickLeadingAccessory = (): void => {
      if (isOpen) {
        navigation.primary.navigateBack();
        onNavigateBack?.();
      }
    };

    useEffect(() => {
      // If window/tab is closed, end the search session
      const endSearchSession = (): void => {
        searchLogger.endSession('exit');
      };
      window.addEventListener('beforeunload', endSearchSession);

      return () => {
        window.removeEventListener('beforeunload', endSearchSession);
      };
    }, [searchLogger]);

    return (
      <div className="SearchBarV2">
        <MarketInputText
          value={query}
          onMarketInputValueChange={(event: MarketInputValueChangeEvent) => {
            setQuery(event.detail.value);
          }}
          placeholder={t('SearchBar.search_inbox')}
          ref={inputRef}
          data-testid="SearchBar__input"
          size="medium"
          onFocus={() => {
            if (navigation.primary.currentPageName !== 'SEARCH') {
              navigation.primary.navigateTo('SEARCH');
            }
          }}
          maxlength={SEARCH_MAX_CHARACTER_COUNT}
        >
          <label>{t('SearchBar.search_inbox')}</label>
          <MarketAccessory
            slot="leading-accessory"
            size="icon"
            className={classNames({ 'SearchBarV2__back-button': isOpen })}
            onClick={(e) => {
              onClickLeadingAccessory();
              e.stopPropagation();
            }}
            onKeyDown={(e) =>
              onKeyDownEnter(e, () => {
                onClickLeadingAccessory();
                e.stopPropagation();
              })
            }
            tabIndex={0}
          >
            {isOpen ? <MarketBackIcon /> : <SearchIcon />}
          </MarketAccessory>
          {query !== '' && (
            <MarketAccessory
              slot="trailing-accessory"
              onClick={(e) => {
                setQuery('', 'exit');
                e.stopPropagation();
              }}
              className="SearchBarV2__clear"
              size="icon"
              tabIndex={0}
            >
              <MarketCloseIcon />
            </MarketAccessory>
          )}
        </MarketInputText>
        {isOpen && <SearchAssistantToggle />}
      </div>
    );
  },
);

export default SearchBarV2;
