import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MESSAGES_PLUS_PRICING_EXPERIMENT } from 'src/utils/experimentUtils';
import MessagesPlusPricingPage from 'src/pages/MessagesPlusPricingPage/MessagesPlusPricingPage';
import MessagesPlusPricingPageV2 from 'src/pages/MessagesPlusPricingPageV2/MessagesPlusPricingPageV2';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import useActivatedExperiment from 'src/hooks/useActivatedExperiment';

/**
 * Component that routes to the correct Messages Plus Pricing page based on what
 * variant of the Messages Plus Pricing experiment the user should see.
 */
const MessagesPlusPricingExperimentPage = observer((): ReactElement => {
  const { status, variant } = useActivatedExperiment(
    MESSAGES_PLUS_PRICING_EXPERIMENT,
  );

  if (status === 'LOADING') {
    return <LoadingIndicator />;
  }

  return variant === 'control' ? (
    <MessagesPlusPricingPage />
  ) : (
    <MessagesPlusPricingPageV2 />
  );
});

export default MessagesPlusPricingExperimentPage;
