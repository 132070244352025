import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MarketAccessory,
  MarketButton,
  MarketLink,
  MarketList,
  MarketRow,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import useIsMobile from 'src/hooks/useIsMobile';
import {
  MessengerModalFullHeader,
  MessengerModalFullContent,
} from 'src/components/MessengerModalFull';
import UnverifiedModal from 'src/pages/TranscriptViewPage/components/UnverifiedModal/UnverifiedModal';
import { KEY_GATE_MESSAGES_PLUS_ON_IDV } from 'src/stores/FeatureFlagStore';
import NoUnitsAvailableToRegisterBanner from 'src/pages/MessagesPlusPricingPageV2/components/NoUnitsAvailableToRegisterBanner/NoUnitsAvailableToRegisterBanner';
import { M_PLUS_GET_STARTED_URL } from 'src/utils/url';
import CheckmarkIcon from 'src/svgs/CheckmarkIcon';
import CouponPercentageIcon from 'src/svgs/CouponPercentageIcon';
import PhoneIcon from 'src/svgs/PhoneIcon';
import PhoneMessageIcon from 'src/svgs/PhoneMessageIcon';
import SoundIcon from 'src/svgs/SoundIcon';
import './MessagesPlusPricingPageV2.scss';
import classNames from 'classnames';

type Feature = {
  icon: ReactElement;
  text: string;
};

/**
 * This sheet displays the details of Messages Plus, displaying the price
 * and the lists of features, with a CTA to start subscribing.
 */
const MessagesPlusPricingPageV2 = observer((): ReactElement => {
  const { user, modal, subscription, featureFlag, navigation, event } =
    useMessengerControllerContext();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { getIdvCompletionStatus, isIdvComplete: isIdvAlreadyComplete } = user;

  useEffect(() => {
    event.track('View Subscription Pricing');
  }, [event]);

  const isNoUnitsAvailableToRegister =
    subscription.unitsAvailableForMPlusRegistration.length === 0;

  const FEATURES: Feature[] = [
    {
      icon: <PhoneIcon />,
      text: t('MessagesPlusPricingPageV2.features.one'),
    },
    {
      icon: <PhoneMessageIcon />,
      text: t('MessagesPlusPricingPageV2.features.two'),
    },
    {
      icon: <SoundIcon />,
      text: t('MessagesPlusPricingPageV2.features.three'),
    },
    {
      icon: <CouponPercentageIcon />,
      text: t('MessagesPlusPricingPageV2.features.four'),
    },
  ];

  const mPlusDemoImage = (
    <img
      src="https://conversations-production-f.squarecdn.com/resources/messages-plus/pricing-page-demo.png"
      alt={t('MessagesPlusPricingPageV2.image_alt_text')}
    />
  );

  return (
    <>
      <MessengerModalFullHeader
        primaryButton={
          <MarketButton
            rank="primary"
            disabled={isNoUnitsAvailableToRegister || undefined}
            onClick={async () => {
              if (
                featureFlag.get(KEY_GATE_MESSAGES_PLUS_ON_IDV) &&
                !isIdvAlreadyComplete
              ) {
                const isIdvComplete = await getIdvCompletionStatus();

                if (!isIdvComplete) {
                  event.track('Click Subscription Pricing Action', {
                    action_type_name: 'next',
                    flow_type: 'idv',
                  });

                  modal.openModal('UNVERIFIED_IDENTITY');
                  return;
                }
              }

              event.track('Click Subscription Pricing Action', {
                action_type_name: 'next',
                flow_type: 'onboarding',
              });

              if (
                subscription.unitsAvailableForMPlusRegistration.length === 1
              ) {
                navigation.sheet.navigateTo({
                  name: 'UNIT_VERIFICATION_FORM',
                  unitToken:
                    subscription.unitsAvailableForMPlusRegistration[0].token,
                });
                return;
              }

              navigation.sheet.navigateTo('MESSAGES_PLUS_START_VERIFICATION');
            }}
          >
            {t('common.next')}
          </MarketButton>
        }
      />
      <MessengerModalFullContent status={null}>
        {isNoUnitsAvailableToRegister && <NoUnitsAvailableToRegisterBanner />}
        <div className="MessagesPlusPricingPageV2__container">
          <div className="MessagesPlusPricingPageV2__content MessagesPlusPricingPageV2__text-container">
            <h3 className="MessagesPlusPricingPageV2__title-highlight">
              {t('MessagesPlusPricingPageV2.title_highlight_text')}
            </h3>
            <h1
              className={classNames('MessagesPlusPricingPageV2__title', {
                'display-20': !isMobile,
                'display-10': isMobile,
              })}
            >
              {t('MessagesPlusPricingPageV2.title')}
            </h1>
            <p className="paragraph-30">
              <Trans i18nKey="MessagesPlusPricingPageV2.description">
                {
                  'Keep your business and personal life separate with a unique toll-free phone number for sending and receiving text messages and capturing voicemails. '
                }
                <MarketLink href={M_PLUS_GET_STARTED_URL} target="_blank">
                  Learn more
                </MarketLink>
              </Trans>
            </p>
            <h3 className="MessagesPlusPricingPageV2__price">
              {t('MessagesPlusPricingPageV2.price')}
            </h3>
            <p className="paragraph-30">
              {t('MessagesPlusPricingPageV2.per_location')}
            </p>
            <h3 className="MessagesPlusPricingPageV2__features">
              {t('MessagesPlusPricingPageV2.features.title')}
            </h3>
            <MarketList>
              {FEATURES.map((feature) => (
                <MarketRow key={feature.text}>
                  <MarketAccessory size="icon" slot="leading-accessory">
                    {feature.icon}
                  </MarketAccessory>
                  <label slot="label">{feature.text}</label>
                  <CheckmarkIcon slot="trailing-accessory" />
                </MarketRow>
              ))}
            </MarketList>
            {isMobile && (
              <div className="MessagesPlusPricingPageV2__mobile-image">
                {mPlusDemoImage}
              </div>
            )}
          </div>
          {!isMobile && (
            <div className="MessagesPlusPricingPageV2__content">
              {mPlusDemoImage}
            </div>
          )}
          {modal.currentModal === 'UNVERIFIED_IDENTITY' && (
            <UnverifiedModal variant="SUBSCRIPTION_BLOCKED" />
          )}
        </div>
      </MessengerModalFullContent>
    </>
  );
});

export default MessagesPlusPricingPageV2;
