import React, { ReactElement, Component, createRef, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import {
  MarketList,
  MESSAGES_MARKET_COMPONENT_PREFIX,
} from 'src/components/Market';
import type MessengerController from 'src/MessengerController';
import MessengerControllerContext from 'src/context/MessengerControllerContext';
import { LoadingStatus } from 'src/MessengerTypes';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import StatusBlock from 'src/components/StatusBlock/StatusBlock';
import { marketComponentsOnReady } from 'src/utils/renderUtils';
import './MessengerList.scss';

/**
 * When the current scroll position to the bottom of the content is less than this amount,
 * getNextPage() will be triggered to load more items.
 */
const SCROLL_BOTTOM_TO_LOAD_MORE = 100;

/**
 * The primary Market component that defines the height of this component.
 */
const MAIN_MARKET_COMPONENT_TAG = `${MESSAGES_MARKET_COMPONENT_PREFIX}-market-row`;

export type MessengerListProps = {
  loadNextPage: () => Promise<void>;
  loadMoreStatus: LoadingStatus;
  hasNextPage: boolean;
  initialScrollPosition: number;
  setScrollPosition?: (scrollPosition: number) => void;
  content?: ReactElement;
  children: ReactNode;
};

/**
 * A generic list implementation to render common list items in Messages.
 * Includes functionality to load more, including loading indicator and error state.
 *
 * @param {() => Promise<void>} loadNextPage
 * Callback that loads the next page of results when called.
 * @param {LoadingStatus} loadMoreStatus
 * The current load status of the load more operation i.e. LOADING, SUCCESS, ERROR, etc.
 * @param {boolean} hasNextPage
 * Flag indicating whether the list has a next page that can be loaded.
 * @param {number} initialScrollPosition
 * The scroll position that the list should be at.
 * @param {(scrollPosition: number) => void} [setScrollPosition]
 * (Optional) Callback that sets a new value for the scroll position.
 * @param {ReactElement} [content]
 * (Optional) Content that will be included above the list but in the scrollable area.
 * @param {ReactNode} children
 * Individual list items to be rendered in the list.
 */
class MessengerList extends Component<MessengerListProps> {
  static contextType = MessengerControllerContext;
  declare context: MessengerController;

  /**
   * A reference to the div wrapping the list to control the scrolling and trigger
   * to load more when at the bottom of the list
   */
  listRef = createRef<HTMLDivElement>();

  componentDidMount(): void {
    if (this.listRef && this.listRef.current) {
      // Attach scroll listener to component to get more items when the scroll hits the bottom.
      this.listRef.current.addEventListener('scroll', this.onListScroll);

      // The expected scrolling behavior of the list is to 'remember' the position
      // between navigations. Since navigating away from TranscriptsListPage
      // dismounts the component, there needs to be away to remember the scroll
      // position. This is done by saving the value in MessengerController. This value
      // will be reset to 0 if there is a change in the array of transcripts, i.e.
      // when a transcript bumps to the top of the list because there is a new
      // utterance, and the expect behavior is to reset the scroll position to the top.
      // We have to wait for the market components to be hydrated first so that the list
      // height is accurate.
      marketComponentsOnReady(
        this.listRef.current,
        MAIN_MARKET_COMPONENT_TAG,
      ).then((listElement) => {
        // eslint-disable-next-line no-param-reassign
        listElement.scrollTop = this.props.initialScrollPosition;
      });
    }
  }

  componentWillUnmount(): void {
    if (this.listRef && this.listRef.current) {
      this.listRef.current.removeEventListener('scroll', this.onListScroll);
    }
  }

  /**
   * This is called on scroll events for the list. It's responsible
   * for checking if we should try to load more items.
   */
  onListScroll = (): void => {
    const { loadMoreStatus, hasNextPage, setScrollPosition } = this.props;

    if (this.listRef && this.listRef.current) {
      setScrollPosition?.(this.listRef.current.scrollTop);
      // Load more when we have less than SCROLL_BOTTOM_TO_LOAD_MORE more to scroll
      // If the list has error (e.g offline), it should show a retry button instead of triggering
      // this scrolling logic.
      if (
        this.listRef.current.scrollTop >
          this.listRef.current.scrollHeight -
            this.listRef.current.clientHeight -
            SCROLL_BOTTOM_TO_LOAD_MORE &&
        loadMoreStatus !== 'ERROR' &&
        hasNextPage
      ) {
        this.getMoreItems();
      }
    }
  };

  /**
   * Gets more items by calling getNextPage(). This can be called either when scrolling
   * to the bottom, or via retry button when it previously failed (e.g offline)
   */
  getMoreItems = (): Promise<void> => {
    const { loadNextPage, loadMoreStatus, hasNextPage } = this.props;

    if (loadMoreStatus !== 'LOADING' && hasNextPage) {
      return loadNextPage();
    }

    // This is no-op if above conditions fail, so it is okay to just return a resolve
    return Promise.resolve();
  };

  render(): ReactElement {
    const { loadMoreStatus, children, content } = this.props;

    // Show loading indicator if we are getting more items
    let loadingIndicator: ReactNode = null;
    if (loadMoreStatus === 'LOADING') {
      loadingIndicator = (
        <div
          className="MessengerList__loading-indicator"
          data-testid="MessengerList__loading-indicator"
        >
          <LoadingIndicator isSmall />
        </div>
      );
    }

    // Show retry load more button if it previously failed to load more
    if (loadMoreStatus === 'ERROR') {
      loadingIndicator = (
        <div
          className="MessengerList__loading-indicator"
          data-testid="MessengerList__loading-failed-indicator"
        >
          <StatusBlock
            message={t('TranscriptsListPage.error_loading_more')}
            retry={() => this.getMoreItems()}
          />
        </div>
      );
    }

    return (
      <div
        className="MessengerList"
        data-testid="MessengerList"
        ref={this.listRef}
      >
        {content}
        <MarketList data-testid="MessengerList__content" interactive>
          {children}
          {loadingIndicator}
        </MarketList>
      </div>
    );
  }
}

export default observer(MessengerList);
