import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './LoadingIndicator.scss';
import { MarketActivityIndicator } from 'src/components/Market';

export type LoadingIndicatorProps = {
  isSmall?: boolean;
};

/**
 * A spinning loading indicator centered over its parent.
 *
 * @example
 * Basic usage:
 * <LoadingIndicator />
 *
 * With optional:
 * <LoadingIndicator isSmall />
 * @param {boolean} [isSmall=false]
 * (Optional) If true, the spinner will be small, used for buttons.
 * The default is a large spinner used for pages.
 * @author klim
 */
const LoadingIndicator = observer(
  (props: LoadingIndicatorProps): ReactElement => {
    const { isSmall } = props;

    return (
      <div data-testid="LoadingIndicator" className="LoadingIndicator">
        <MarketActivityIndicator size={isSmall ? 'small' : 'large'} />
      </div>
    );
  },
);

export default LoadingIndicator;
