import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import BetaPill from 'src/components/BetaPill/BetaPill';
import { Trans, useTranslation } from 'react-i18next';
import { MarketBanner, MarketLink } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  MESSAGES_PLUGIN_PROD_JS_BUNDLE_URL,
  MESSAGES_PLUGIN_STAGING_JS_BUNDLE_URL,
  PLUGIN_FEEDBACK_URL,
  PLUGIN_HELP_URL,
} from 'src/utils/url';
import CodeSnippet from 'src/components/CodeSnippet/CodeSnippet';
import { getEnvironment } from 'src/utils/initUtils';

/**
 * Generate the plugin code snippet.
 *
 * @param {string} mainUnitToken
 */
function generateSnippet(mainUnitToken: string): string {
  const srcUrl =
    getEnvironment() === 'production'
      ? MESSAGES_PLUGIN_PROD_JS_BUNDLE_URL
      : MESSAGES_PLUGIN_STAGING_JS_BUNDLE_URL;
  return `<script
  id="sq-messages-plugin"
  src="${srcUrl}"
  data-seller-key="${mainUnitToken}">
</script>`;
}

/**
 * Settings for Messages Plugin. For now it shows a code snippet for the merchant
 * to copy paste to their website.
 *
 * @example
 * Basic usage:
 * <PluginSetting />
 * @author klim
 */
const PluginSetting = observer((): ReactElement => {
  const { t } = useTranslation();
  const { user, tooltip } = useMessengerControllerContext();

  const snippet = generateSnippet(user.mainUnitToken);

  return (
    <>
      <h3>
        {t('SettingsPage.plugin.title')}
        <BetaPill />
      </h3>
      <p className="paragraph-30">{t('SettingsPage.plugin.description')}</p>
      <p className="paragraph-30" data-testid="PluginSetting__copy-paste">
        <Trans i18nKey="SettingsPage.plugin.copy_paste">
          To add the plugin to your website, simply <b>copy and paste</b> the
          following code snippet to your <b>{"site's HTML"}</b>.
        </Trans>
      </p>
      <CodeSnippet
        title={t('SettingsPage.plugin.code_snippet')}
        snippet={snippet}
      />
      <p>
        <MarketLink href={PLUGIN_HELP_URL} target="_blank">
          {t('SettingsPage.plugin.help_link')}
        </MarketLink>
      </p>
      {tooltip.isVisible('PLUGIN_FEEDBACK') && (
        <MarketBanner
          onMarketBannerDismissed={() => {
            tooltip.dismiss('PLUGIN_FEEDBACK');
          }}
          dismissable
        >
          {t('SettingsPage.plugin.feedback.description')}
          <a
            slot="action"
            href={PLUGIN_FEEDBACK_URL}
            target="_blank"
            rel="noreferrer"
          >
            {t('SettingsPage.plugin.feedback.button_label')}
          </a>
        </MarketBanner>
      )}
    </>
  );
});

export default PluginSetting;
