import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import { MarketBanner } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import SoundRecorder from 'src/components/SoundRecorder/SoundRecorder';
import './VoicemailRecorder.scss';

export type VoicemailRecorderProps = {
  unitToken: string;
  onRecordingEnd: (blob: Blob) => Promise<void>;
  errorText?: string;
};

/**
 * Component to record a voicemail greeting.
 *
 * @param {string} unitToken
 * The unit token to record the voicemail greeting for.
 * @param {(blob: Blob) => Promise<void>} [onRecordingEnd]
 * Callback to be called when the recording ends.
 * @param {string} [errorText]
 * The error text to display.
 * @returns {ReactElement}
 */
const VoicemailRecorder = observer(
  ({
    unitToken,
    onRecordingEnd,
    errorText,
  }: VoicemailRecorderProps): ReactElement => {
    const { settings, event } = useMessengerControllerContext();

    return (
      <>
        <SoundRecorder
          onRecordingEnd={onRecordingEnd}
          isLoading={settings.voicemailUploadStatus === 'LOADING'}
          onStartRecording={() => {
            event.track('Click Record Voicemail Greeting', {
              unit_token: unitToken,
            });
          }}
          errorText={errorText}
        />
        <MarketBanner className="VoicemailRecorder__banner">
          <Trans i18nKey="EditVoicemailPage.recording_advice_text">
            {''}
            <span className="VoicemailRecorder__tip-text">Tip:</span>
            {
              ' For best results, record in a quiet room with minimal background noise.'
            }
          </Trans>
        </MarketBanner>
      </>
    );
  },
);

export default VoicemailRecorder;
