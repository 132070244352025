/* eslint-disable no-console, @typescript-eslint/no-explicit-any, no-param-reassign */

import { observable, toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { developerToolsConsoleId } from './components/DeveloperTools/DeveloperTools';
import { Environment } from './MessengerTypes';
import Sentry from './Sentry';
import { scrollElementTo } from './utils/renderUtils';
import { getShadowRoot } from './utils/shadowDomUtils';

// Originally defined in url.ts but imported here to prevent circular import
const INBOX_STAGING_ORIGIN = 'https://inbox.squareupstaging.com';

/**
 * A cached variable for whether we should be logging to the console.
 * In practice, we don't log in production, and we don't log in unit tests
 * (for debugging in unit tests, sprinkle console.log() -- the linter will warn
 * us to remove these before merging).
 */
const shouldLog: boolean =
  (process.env.NODE_ENV !== 'production' &&
    process.env.JEST_WORKER_ID === undefined) ||
  window.location.origin === INBOX_STAGING_ORIGIN;

/**
 * The Tableau 10 color scheme. See
 * https://www.tableau.com/about/blog/2016/7/colors-upgrade-tableau-10-56782
 */
const TABLEAU_10 = [
  '#1f77b4',
  '#2ca02c',
  '#7f7f7f',
  '#8c564b',
  '#17becf',
  '#9467bd',
  '#bcbd22',
  '#d62728',
  '#e377c2',
  '#ff7f0e',
];

/**
 * The elements of a collapsible component. See _createCollapsibleElements()
 */
type CollapsibleElements = {
  group: HTMLElement;
  label: HTMLElement;
  body: HTMLElement;
};

/**
 * A class that helps with logging, such as warnings, errors, and network call
 * request and response information. Also contains the logic for developer tools,
 * which will render all console log messages into DeveloperTools component.
 */
export default class Logger {
  /**
   * To track if dev tools is activated.
   */
  @observable
  static showDevTools = false;

  /**
   * A Sentry instance, for logging errors remotely. initSentry() must be called
   * before this instance can be used.
   */
  private static _sentry: Sentry = new Sentry();

  /**
   * A running count of the RPC calls that have been made.
   */
  private static _rpcCount = 0;

  /**
   * Initialize the Sentry instance. Called in index.tsx.
   *
   * @param {Environment} environment
   */
  static initSentry(environment: Environment): void {
    Logger.log(`Init Sentry ${environment}`);
    this._sentry.init(environment);
  }

  /**
   * A random color chosen from among the Tableau 10 color palette, seeded from the given string.
   *
   * @param {string} value
   */
  static randomColor(value: string): string {
    const hash: number = [...value].reduce((a, b) => {
      a = (a << 5) - a + (b.codePointAt(0) ?? 0);
      return a & a;
    }, 0);
    return TABLEAU_10[Math.abs(hash) % 10];
  }

  /**
   * Group log messages together into a dropdown. The group is automatically
   * closed when the callback returns; all log messages in the callback are logged
   * inside the group.
   *
   * @param {(element?: HTMLElement) => void} callback
   * A callback for printing log messages within the group.
   * @param {string} groupLabel
   * The label to display for the group, in the form of `%cRPC#${counter} ↓ %c${name}`
   * @param {string} counterStyle
   * The style to apply on the label from the first %c to second %c, e.g. 'color:blue'
   * @param {string} nameStyle
   * The style to apply on the label from the second %c, e.g. 'color:blue'
   */
  static group(
    callback: (element?: HTMLElement) => void,
    groupLabel: string,
    counterStyle: string,
    nameStyle: string,
  ): void {
    let bodyElement: HTMLElement | undefined = undefined;
    if (this.showDevTools) {
      // Extract information counter, name and color styles.
      const counterText = groupLabel.slice(
        groupLabel.indexOf('%c') + 2,
        groupLabel.lastIndexOf('%c'),
      );
      const nameText = groupLabel.slice(groupLabel.lastIndexOf('%c') + 2);
      const counterColor = counterStyle.replace('color:', '');
      const nameColor = nameStyle.replace('color:', '');

      // Create collapsible component
      const { group, label, body } = this._createCollapsibleElements();
      group.className = 'DeveloperTools__console__group';
      bodyElement = body;

      // Style and add content for label
      label.style.fontWeight = 'bold';
      const counter = document.createElement('span');
      counter.style.color = counterColor;
      counter.innerText = counterText;
      const name = document.createElement('span');
      name.style.color = nameColor;
      name.innerText = nameText;
      label.appendChild(counter);
      label.appendChild(name);

      // Call callback to insert into body and insert new group into head element
      if (!shouldLog) {
        callback(body);
      }
      this._insertElementIntoConsole(group);
    }

    if (shouldLog) {
      console.groupCollapsed(groupLabel, counterStyle, nameStyle);
      callback(bodyElement);
      console.groupEnd();
    }
  }

  /**
   * Print a message to the console.
   * If dev tools is activated, also render the log into DeveloperTools.
   *
   * @param {any} [message] - optional because response.debugText is optional
   * @param {HTMLElement} [element] - Element to render into if dev tools is active
   * @param {Record<string, ?>} [context] - optional context to log with the message
   */
  static log(
    message: any,
    element?: HTMLElement,
    context?: Record<string, unknown>,
  ): void {
    if (shouldLog && message) {
      context ? console.log(message, context) : console.log(message);
    }
    if (this.showDevTools) {
      this._renderMessage(message, element);
    }
  }

  /**
   * Print a warning to the console.
   * If dev tools is activated, also render the warning into DeveloperTools.
   *
   * @param {any} [message] - optional because response.debugText is optional
   * @param {HTMLElement} [element] - Element to render into if dev tools is active
   * @param {Record<string, ?>} [context] - optional context to log with the message
   */
  static warn(
    message: any,
    element?: HTMLElement,
    context?: Record<string, unknown>,
  ): void {
    if (shouldLog && message) {
      context ? console.warn(message, context) : console.warn(message);
    }
    if (this.showDevTools) {
      this._renderMessage(message, element);
    }
  }

  /**
   * Print an error to the console.
   * If dev tools is activated, also render the error into DeveloperTools.
   *
   * @param {any} [message] - optional because response.debugText is optional
   * @param {HTMLElement} [element] - Element to render into if dev tools is active
   * @param {Record<string, ?>} [context] - optional context to log with the message
   */
  static error(
    message: any,
    element?: HTMLElement,
    context?: Record<string, unknown>,
  ): void {
    if (shouldLog && message) {
      context ? console.error(message, context) : console.error(message);
    }
    if (this.showDevTools) {
      this._renderMessage(message, element);
    }
  }

  /**
   * Print the current stack trace, with an optional message at the top of the stack trace.
   * If dev tools is activated, also render the stack trace into DeveloperTools.
   *
   * @param {string} traceLabel An optional message to show at the top of the trace.
   * @param {HTMLElement} element Element to render into if dev tools is active
   */
  static stackTrace(traceLabel?: string, element?: HTMLElement): void {
    console.trace(traceLabel);
    if (this.showDevTools && element) {
      // Create collapsible component
      const { group, label, body } = this._createCollapsibleElements();

      // Add content to label
      label.innerText = traceLabel ?? 'Stack trace:';

      // Add content to body
      body.innerText = this._getStackTraceString();

      // Add group to element
      element.appendChild(group);
    }
  }

  /**
   * Log a message to Sentry and also printing it out on the console.
   *
   * @param {string | Error} event - the message to pass to sentry or an
   * Error that Sentry can display a trace for
   * @param {string} level - what kind of alert this is
   * @param {Record<string, ?>} data - any additional data you'd like to
   * send to Sentry with this message
   */
  static logWithSentry(
    event: string | Error,
    level?: 'warning' | 'error',
    data?: Record<string, unknown>,
  ): void {
    switch (level) {
      case 'warning':
        Logger.warn(event, undefined, data);
        break;
      case 'error':
        Logger.error(event, undefined, data);
        break;
      default:
        Logger.log(event, undefined, data);
    }
    this._sentry.logEvent(event, level, data);
  }

  /**
   * Log a message and return a rejection Promise with that message as the error.
   *
   * @param {string} message - the message to log
   * @param {string} level - what kind of alert this is
   * @param {Record<string, ?>} data - any additional data to log
   * @returns the rejection
   */
  static logAndReject(
    message: string,
    level?: 'warning' | 'error',
    data?: Record<string, unknown>,
  ): Promise<never> {
    switch (level) {
      case 'warning':
        Logger.warn(message, undefined, data);
        break;
      case 'error':
        Logger.error(message, undefined, data);
        break;
      default:
        Logger.log(message, undefined, data);
    }
    return Promise.reject(new Error(message));
  }

  /**
   * Helper method to log an RPC request.
   *
   * @param {string} name
   * The name of the RPC being logged.
   * @param {any} request
   * The request made in the RPC call.
   * @returns {number}
   * The rpcId pertaining to the request logged.
   */
  public static logRpcRequest = (name: string, request: any): number => {
    const rpcId = this._rpcCount;
    this._rpcCount += 1;
    Logger.group(
      (element) => {
        Logger.log(toJS(request), element);
        Logger.stackTrace('Called from:', element);
      },
      `%cRPC#${rpcId} ↑ %c${name}`,
      'color:lightblue',
      `color:${Logger.randomColor(name)}`,
    );
    return rpcId;
  };

  /**
   * Helper method to log an RPC response.
   *
   * @param {number} rpcId
   * The ID of the RPC request.
   * @param {string} name
   * The name of the RPC being logged.
   * @param {any} response
   * The response returned from the RPC call.
   */
  public static logRpcResponse = (
    rpcId: number,
    name: string,
    response: any,
  ): void => {
    Logger.group(
      (element) => {
        Logger.log(response, element);
      },
      `%cRPC#${rpcId} ↓ %c${name}`,
      'color:green',
      `color:${Logger.randomColor(name)}`,
    );
  };

  /**
   * Helper method to log an RPC error.
   *
   * @param {number} rpcId
   * The ID of the RPC request.
   * @param {string} name
   * The name of the RPC being logged.
   * @param {any} response
   * The response returned from the RPC call.
   */
  public static logRpcError = (
    rpcId: number,
    name: string,
    response: any,
  ): void => {
    Logger.group(
      (element) => {
        Logger.log(response, element);
      },
      `%cRPC#${rpcId} ↓ %c${name}`,
      'color:red',
      `color:${Logger.randomColor(name)}`,
    );
  };

  /**
   * Get the current stack trace as a string.
   */
  private static _getStackTraceString(): string {
    const result = { stack: '', name: '' };
    Error.captureStackTrace(result, this._getStackTraceString);
    // remove the first \n and tab space on each line
    return result.stack.slice(1).replaceAll('    ', '');
  }

  /**
   * Insert an element into DeveloperTools console div.
   * Also scrolls to the bottom after the element is inserted if scroll was
   * already at the bottom
   *
   * @param {HTMLElement} element The element to be inserted.
   */
  private static _insertElementIntoConsole(element: HTMLElement): void {
    const devToolsConsole = getShadowRoot()?.getElementById(
      developerToolsConsoleId,
    );
    if (devToolsConsole) {
      // Check if scroll is at the bottom
      const scrollAtBottom =
        devToolsConsole.scrollHeight -
          devToolsConsole.scrollTop -
          devToolsConsole.offsetHeight <
        300;

      // Insert the element
      devToolsConsole.appendChild(element);

      // If scroll was at the bottom, scroll down again after element is inserted
      if (scrollAtBottom) {
        scrollElementTo(devToolsConsole, devToolsConsole.scrollHeight, true);
      }
    }
  }

  /**
   * Create an element that contains the message, and insert it into parent
   * if given, else insert it into DeveloperTool console's element.
   *
   * Example (pseudo-html) when parent is specified:
   * A group (collapsible component) is created, and the message is nested inside
   * <collapsible>
   *   <label>Status</label>
   *   <body>{insert message here}</body>
   * </collapsible>
   *
   * Example when parent is not specified:
   * Inserting a plain console.log message like "Recomputing mediums"
   *
   * @param {any} message The message to render
   * @param {HTMLElement} parent If given, render message into it
   */
  private static _renderMessage(message: any, parent?: HTMLElement): void {
    let name = message.constructor.name ?? '';
    if (!parent) {
      parent = document.createElement('div');
      parent.className = 'DeveloperTools__console__group';
      this._insertElementIntoConsole(parent);
      name = undefined;
    }
    parent.appendChild(this._createMessageElement(message, name));
  }

  /**
   * Create an element that holds the message as the content. Message
   * could either be an array, object, or primitive type data. If it is an
   * array or object, a collapsible will be created and each key will call
   * this method again until a primitive type is reached.
   *
   * @param {any} message The array/object/primitive to display
   * @param {string} name Name of the current message, such as the key
   * @param {string} type The protobuf type if an object, or Array
   */
  private static _createMessageElement(
    message: any,
    name?: string,
    type?: string,
  ): HTMLElement {
    if (Array.isArray(message) || typeof message === 'object') {
      // Case: This is an array or object, create collapsible

      // Create collapsible component
      const { group, label, body } = this._createCollapsibleElements();

      // Add content for label
      const nameSpan = document.createElement('span');
      nameSpan.style.color = 'orchid';
      nameSpan.innerText = name ? name : '';
      const typeSpan = document.createElement('span');
      typeSpan.style.color = 'gray';
      typeSpan.innerText = type ? `: ${type}` : '';
      label.appendChild(nameSpan);
      label.appendChild(typeSpan);

      // Remove prototype and constructor keys
      const messageObject = JSON.parse(JSON.stringify(message));
      // For each key, create element and add to body
      for (const key in messageObject) {
        const type = message[key].constructor.name;
        body.appendChild(this._createMessageElement(message[key], key, type));
      }

      return group;
    } else {
      // Case: This is primitive type, just render content as it is

      // Create content in the form of {name}: {message}
      const content = document.createElement('div');
      const nameSpan = document.createElement('span');
      nameSpan.style.color = 'orchid';
      nameSpan.innerText = name ? `${name}: ` : '';
      const messageSpan = document.createElement('span');
      messageSpan.innerText = message;
      content.appendChild(nameSpan);
      content.appendChild(messageSpan);

      return content;
    }
  }

  /**
   * Create elements required for a collapsible component. The 'group'
   * element is the container, 'button' contains a hidden checkbox,
   * 'label' for the text shown for the button, and 'body' for contents
   * when uncollapsed.
   */
  private static _createCollapsibleElements(): CollapsibleElements {
    // Create container element for group
    const group = document.createElement('div');

    // Create input as collapse/expand button. When checked, it will
    // body to be display: block
    const button = document.createElement('input');
    button.id = uuidv4();
    button.type = 'checkbox';
    button.className = 'DeveloperTools__console__collapse';
    group.appendChild(button);

    // Create label for button
    const label = document.createElement('label');
    label.htmlFor = button.id;
    group.appendChild(label);

    // Create the body for message, by default dislay: none
    const body = document.createElement('div');
    body.className = 'DeveloperTools__console__body';
    group.appendChild(body);

    return { group, label, body };
  }
}
/* eslint-enable no-console, @typescript-eslint/no-explicit-any, no-param-reassign */
