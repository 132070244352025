/**
 * We will replace the Customer Data Platform DataApi methods
 * with these methods when run under Jest. If they wish, the test
 * writer can re-mock these methods to ensure they
 * were called with the right arguments. By default, they
 * print silent log messages.
 */
import Logger from 'src/Logger';

export default {
  /**
   * The following methods are CDP boilerplate. To be
   * honest, I'm not sure why they both exist and neither
   * has to wait on the other one.
   */
  identify(identifier: string, identifierType: string): void {
    Logger.log(
      `identify called with ID ${identifier} and type ${identifierType}`,
    );
  },
  load(apiKey: string, environment: string): void {
    Logger.log(`load called with key ${apiKey} and env ${environment}`);
  },

  /**
   * The event that's called each time a user interaction
   * occurs that we're interested in.
   * You should call this with a key from Tracking.ts rather
   * than a raw string.
   * @param {string} eventName
   */
  track(eventName: string): void {
    Logger.log(`track called with eventName ${eventName}`);
  },
};
