/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './countries';
import './script';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "common": {
        "nested": {
          "location": {
            "nested": {
              "GeoLocation": {
                "fields": {
                  "latitude": {
                    "type": "double",
                    "id": 1,
                    "rule": "optional"
                  },
                  "longitude": {
                    "type": "double",
                    "id": 2,
                    "rule": "optional"
                  },
                  "altitudeMeters": {
                    "type": "double",
                    "id": 3,
                    "rule": "optional"
                  },
                  "uncertaintyMeters": {
                    "type": "double",
                    "id": 4,
                    "rule": "optional"
                  }
                }
              },
              "Coordinates": {
                "fields": {
                  "latitude": {
                    "type": "double",
                    "id": 1,
                    "rule": "optional"
                  },
                  "longitude": {
                    "type": "double",
                    "id": 2,
                    "rule": "optional"
                  },
                  "altitude": {
                    "type": "double",
                    "id": 3,
                    "rule": "optional"
                  },
                  "geographicAccuracy": {
                    "type": "double",
                    "id": 4,
                    "rule": "optional"
                  },
                  "altitudinalAccuracy": {
                    "type": "double",
                    "id": 5,
                    "rule": "optional"
                  },
                  "heading": {
                    "type": "double",
                    "id": 6,
                    "rule": "optional"
                  },
                  "speed": {
                    "type": "double",
                    "id": 7,
                    "rule": "optional"
                  }
                }
              },
              "Address": {
                "fields": {
                  "street1": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "street2": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "city": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "stateProvince": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "postalCode": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "countryCode": {
                    "type": "squareup.common.countries.Country",
                    "id": 6,
                    "rule": "optional"
                  }
                }
              },
              "GlobalAddress": {
                "fields": {
                  "addressLine1": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "addressLine2": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "addressLine3": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "locality": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "sublocality": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "administrativeDistrictLevel1": {
                    "type": "string",
                    "id": 13,
                    "rule": "optional"
                  },
                  "postalCode": {
                    "type": "string",
                    "id": 16,
                    "rule": "optional"
                  },
                  "countryCode": {
                    "type": "squareup.common.countries.Country",
                    "id": 17,
                    "rule": "optional"
                  },
                  "script": {
                    "type": "squareup.common.script.Script",
                    "id": 20,
                    "rule": "optional"
                  },
                  "addressLine4": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "addressLine5": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "sublocality1": {
                    "type": "string",
                    "id": 8,
                    "rule": "optional"
                  },
                  "sublocality2": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  },
                  "sublocality3": {
                    "type": "string",
                    "id": 10,
                    "rule": "optional"
                  },
                  "sublocality4": {
                    "type": "string",
                    "id": 11,
                    "rule": "optional"
                  },
                  "sublocality5": {
                    "type": "string",
                    "id": 12,
                    "rule": "optional"
                  },
                  "administrativeDistrictLevel2": {
                    "type": "string",
                    "id": 14,
                    "rule": "optional"
                  },
                  "administrativeDistrictLevel3": {
                    "type": "string",
                    "id": 15,
                    "rule": "optional"
                  },
                  "addressCoordinates": {
                    "type": "squareup.common.location.Coordinates",
                    "id": 18,
                    "rule": "optional"
                  },
                  "neighborhood": {
                    "type": "string",
                    "id": 19,
                    "rule": "optional"
                  }
                }
              },
              "Phone": {
                "fields": {
                  "callingCode": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "number": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.location?.nested?.GeoLocation && !$root.nested?.squareup?.nested?.common?.nested?.location?.nested?.Coordinates && !$root.nested?.squareup?.nested?.common?.nested?.location?.nested?.Address && !$root.nested?.squareup?.nested?.common?.nested?.location?.nested?.GlobalAddress && !$root.nested?.squareup?.nested?.common?.nested?.location?.nested?.Phone) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE GeoLocation @ squareup/common/location.proto at 10:1
 */
export const GeoLocation = $root.lookupType('squareup.common.location.GeoLocation');
fixType(GeoLocation);
Builder.createAndAttachToType(GeoLocation);
/**
 * Geographic coordinates specified in decimal degrees.
 *
 * SOURCE Coordinates @ squareup/common/location.proto at 20:1
 */
export const Coordinates = $root.lookupType('squareup.common.location.Coordinates');
fixType(Coordinates, {
  latitude: { required: true },
  longitude: { required: true },
});
Builder.createAndAttachToType(Coordinates);
/**
 * Deprecated. Use GlobalAddress.
 *
 * SOURCE Address @ squareup/common/location.proto at 53:1
 */
export const Address = $root.lookupType('squareup.common.location.Address');
fixType(Address);
Builder.createAndAttachToType(Address);
/**
 * Full documentation can be found here:
 * https://coda.io/d/Multi-Seller-Document-Hub_ds0w_lkE3Tz/Global-Address_suygD#_lumaO
 *
 * *WARNING** - There are MANY deprecated fields in this proto!
 * Please DO NOT USE these deprecated fields.
 * We recommend turning on "Hide Deprecated Fields" when viewing this in go/proto.
 *
 * SOURCE GlobalAddress @ squareup/common/location.proto at 72:1
 */
export const GlobalAddress = $root.lookupType('squareup.common.location.GlobalAddress');
fixType(GlobalAddress);
Builder.createAndAttachToType(GlobalAddress);
/**
 * SOURCE Phone @ squareup/common/location.proto at 144:1
 */
export const Phone = $root.lookupType('squareup.common.location.Phone');
fixType(Phone);
Builder.createAndAttachToType(Phone);
