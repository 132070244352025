import { useState, useEffect } from 'react';
import { Attributes } from '@squareup/browser-experiments/dist/types/types/types';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { ExperimentName } from 'src/utils/experimentUtils';
import { LoadingStatus } from 'src/MessengerTypes';
import Logger from 'src/Logger';

type ActivatedExperiment = {
  status: LoadingStatus;
  variant?: string;
};

/**
 * Hook to get (and activate) the experiment for the user.
 * Returns a loading state and the variant the user is bucketed into.
 *
 * @param {ExperimentName} experimentName
 * Name of the experiment to activate.
 * @param {Attributes} userAttributes
 * Optional user attributes to use for bucketing.
 */
const useActivatedExperiment = (
  experimentName: ExperimentName,
  userAttributes?: Attributes,
): ActivatedExperiment => {
  const [status, setStatus] = useState<LoadingStatus>('LOADING');
  const [variant, setVariant] = useState<string>();
  const { experiments, user } = useMessengerControllerContext();

  useEffect(() => {
    experiments
      .getVariationForExperiment(experimentName, userAttributes)
      .then((result) => {
        const { success, variantName } = result;
        setVariant(variantName);
        setStatus(success ? 'SUCCESS' : 'ERROR');

        if (!success) {
          Logger.logWithSentry(
            `getVariationForExperiment: failed to activate experiment`,
            'warning',
            {
              experimentName,
              userAttributes,
              merchantToken: user.merchantToken,
            },
          );
        }
      });
  }, [experiments, experimentName, userAttributes, user.merchantToken]);

  return {
    status,
    variant,
  };
};

export default useActivatedExperiment;
