import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import MessengerList from 'src/components/MessengerList/MessengerList';
import TranscriptsListStore from 'src/stores/TranscriptsListStore';

export type TranscriptsListProps = {
  transcriptsList: TranscriptsListStore;
  initialScrollPosition: number;
  setScrollPosition?: (scrollPosition: number) => void;
  content?: ReactElement;
  children: ReactNode;
};

/**
 * A scrollable list that visually renders a transcripts list data structure.
 *
 * @param {TranscriptsListStore} transcriptsList
 * The transcripts list data to render.
 * @param {number} initialScrollPosition
 * The scroll position that the list should be at.
 * @param {(scrollPosition: number) => void} [setScrollPosition]
 * Callback that sets a new value for the scroll position.
 * @param {ReactElement} [content]
 * (Optional) Content that will be included above the list but in the scrollable area.
 * @param {ReactNode} children
 * Individual list items to be rendered in the list.
 */
const TranscriptsList = observer(
  ({
    transcriptsList,
    initialScrollPosition,
    setScrollPosition,
    content,
    children,
  }: TranscriptsListProps): ReactElement => (
    <MessengerList
      loadNextPage={transcriptsList.loadNextPage}
      loadMoreStatus={transcriptsList.loadMoreStatus}
      hasNextPage={transcriptsList.hasNextPage}
      initialScrollPosition={initialScrollPosition}
      setScrollPosition={setScrollPosition}
      content={content}
    >
      {children}
    </MessengerList>
  ),
);

export default TranscriptsList;
