import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MessagesPluginEntry } from 'src/MessengerTypes';
import CustomerEventCard from 'src/pages/TranscriptViewPage/components/CustomerEventCard/CustomerEventCard';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { i18nPhoneNumber } from 'src/i18n/formatValues';
import { BaseContextualEventCardProps } from 'src/types/BaseContextualEventCardProps';
import MessagesPluginOnBuyerBookingFlowLaunchBanner from 'src/components/MessagesPluginOnBuyerBookingFlowLaunchBanner/MessagesPluginOnBuyerBookingFlowLaunchBanner';
import {
  KEY_MESSAGES_PLUGIN_GA,
  KEY_MESSAGES_PLUGIN_ON_BBF,
} from 'src/stores/FeatureFlagStore';
import useIsMobile from 'src/hooks/useIsMobile';
import './MessagesPluginEntryEventCard.scss';
import PhoneMessageIcon from 'src/svgs/PhoneMessageIcon';

/**
 * Renders an event card for a Messages Plugin Entry.
 */
const MessagesPluginEntryEventCard = observer(
  ({ item, customerImage, cardRef }: BaseContextualEventCardProps) => {
    const { timestampMillis, data, attachedUtterance } = item;
    const { name, contactId, message, url, urlLabel } =
      data as MessagesPluginEntry;
    const {
      user: { countryCode },
      featureFlag,
      tooltip,
      transcriptView,
      navigation,
    } = useMessengerControllerContext();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const utteranceId = attachedUtterance?.utterance.id;

    return (
      <CustomerEventCard
        timestampMillis={timestampMillis}
        icon={<PhoneMessageIcon />}
        title={t('ContextualEvent.messagesPlugin.title')}
        subtitle={
          featureFlag.get(KEY_MESSAGES_PLUGIN_GA) ? (
            url ? (
              <a
                className="MessagesPluginEntryEventCard__booking-site-url"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {urlLabel ||
                  t(
                    'ContextualEvent.messagesPlugin.default_booking_link_label',
                  )}
              </a>
            ) : undefined
          ) : (
            t('ContextualEvent.messagesPlugin.subtitle')
          )
        }
        customerImage={customerImage}
        body={
          featureFlag.get(KEY_MESSAGES_PLUGIN_GA) ? (
            <div className="paragraph-30">{message}</div>
          ) : (
            <>
              <div className="TranscriptViewItem__plugin__row">
                <div className="medium-30">
                  {t('ContextualEvent.messagesPlugin.name')}
                </div>
                <div className="paragraph-30">{name}</div>
              </div>
              <div className="TranscriptViewItem__plugin__row">
                <div className="medium-30">
                  {t('ContextualEvent.messagesPlugin.phone')}
                </div>
                <div className="paragraph-30">
                  {i18nPhoneNumber(contactId ?? '', countryCode) ?? contactId}
                </div>
              </div>
              <div className="TranscriptViewItem__plugin__row">
                <div className="medium-30">
                  {t('ContextualEvent.messagesPlugin.message')}
                </div>
                <div className="paragraph-30">{message}</div>
              </div>
            </>
          )
        }
        cardRef={cardRef}
        id={utteranceId}
        className="MessagesPluginEntryEventCard"
      >
        {(featureFlag.get(KEY_MESSAGES_PLUGIN_ON_BBF) ||
          featureFlag.get(KEY_MESSAGES_PLUGIN_GA)) &&
          tooltip.isVisible('MESSAGES_PLUGIN_ON_BBF_LAUNCH_BANNER') &&
          !isMobile &&
          navigation.application === 'FULL_PAGE' &&
          utteranceId &&
          transcriptView.mostRecentMessagesPluginEntryUtteranceId ===
            utteranceId && <MessagesPluginOnBuyerBookingFlowLaunchBanner />}
      </CustomerEventCard>
    );
  },
);

export default MessagesPluginEntryEventCard;
