import { getEnvironment } from 'src/utils/initUtils';
import { getTenorFeaturedUrl, getTenorSearchUrl } from 'src/utils/url';

const TENOR_API_KEY_STAGING = 'AIzaSyBWYCRkjOVa0LzOsphJpiEPNYasO2fbszU';
const TENOR_API_KEY_PRODUCTION = 'AIzaSyCICGiTybAF7n3zHJQ2qRv4lt0oaGgP8eU';

export default class GifApi {
  private apiKey: string;

  /**
   * Constructor.
   */
  constructor() {
    this.apiKey =
      getEnvironment() === 'production'
        ? TENOR_API_KEY_PRODUCTION
        : TENOR_API_KEY_STAGING;
  }

  /**
   * Search for gifs based on the query and filter.
   *
   * @param {string} query The search query to use.
   * @param {string} searchfilter
   * Comma-separated list of non-GIF content types to filter the Response Objects. By default, searchfilter returns GIF content only.
   * @returns Tenor search response including the resultant gifs.
   */
  search = async (
    query: string,
    searchfilter?: string,
  ): Promise<TenorSearchResponse> => {
    const request: Record<string, string> = {
      key: this.apiKey,
      q: query,
      contentfilter: 'high',
    };
    if (searchfilter) {
      request.searchfilter = searchfilter;
    }
    const params = new URLSearchParams(request);
    const fetchRequest = new Request(getTenorSearchUrl(params), {
      method: 'GET',
    });

    const response = await fetch(fetchRequest);
    if (response.status !== 200) {
      throw new Error('Tenor returned http error');
    }
    return response.json();
  };

  /**
   * Get the latest featured gifs from Tenor.
   *
   * @param {string} searchfilter
   * Comma-separated list of non-GIF content types to filter the Response Objects. By default, searchfilter returns GIF content only.
   * @returns Tenor search response with the latest featured gifs.
   */
  featured = async (searchfilter?: string): Promise<TenorSearchResponse> => {
    const request: Record<string, string> = {
      key: this.apiKey,
      contentfilter: 'high',
    };
    if (searchfilter) {
      request.searchfilter = searchfilter;
    }
    const params = new URLSearchParams(request);
    const fetchRequest = new Request(getTenorFeaturedUrl(params), {
      method: 'GET',
    });

    const response = await fetch(fetchRequest);
    if (response.status !== 200) {
      throw new Error('Tenor returned http error');
    }
    return response.json();
  };
}

// Tenor types.  See: https://developers.google.com/tenor/guides/response-objects-and-errors
export type TenorResponse = {
  created: number;
  hasaudio: boolean;
  id: string;
  media_formats: Record<TenorMediaFormat, TenorMedia>;
  tags: string[];
  title: string;
  content_description: string;
  itemurl: string;
  hascaption: boolean;
  flags: string;
  bg_color: string;
  url: string;
};

export type TenorMediaFormat =
  | 'gif'
  | 'mediumgif'
  | 'tinygif'
  | 'nanogif'
  | 'mp4'
  | 'loopedmp4'
  | 'tinymp4'
  | 'nanomp4'
  | 'webm'
  | 'tinywebm'
  | 'nanowebm'
  | 'webp_transparent'
  | 'tinywebp_transparent'
  | 'nanowebp_transparent'
  | 'gif_transparent'
  | 'tinygif_transparent'
  | 'nanogif_transparent';

export type TenorMedia = {
  url: string;
  dims: number[];
  duration: number;
  size: number;
};

export type TenorCategory = {
  searchterm: string;
  path: string;
  image: string;
  name: string;
};

export type TenorSearchResponse = {
  next: string;
  results: TenorResponse[];
};
