import { MarketAccessory, MarketInputText } from 'src/components/Market';
import { observer } from 'mobx-react';
import React, { useState, useEffect, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MarketInputValueChangeEvent } from 'src/MarketTypes';
import { TenorResponse } from 'src/api/GifApi';
import ClearIcon from 'src/svgs/ClearIcon';
import SearchIcon from 'src/svgs/SearchIcon';
import Logger from 'src/Logger';
import './SendGifModal.scss';

const INPUT_TYPE_DELAY = 500;

const SendGifModal = observer(() => {
  const { t } = useTranslation();
  const {
    modal,
    status,
    api: { gif },
    event,
    transcriptView,
  } = useMessengerControllerContext();
  const { closeModal: close } = modal;

  const [gifResults, setGifResults] = useState<TenorResponse[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const inputRef = createRef<HTMLMarketInputTextElement>();
  useEffect(() => {
    inputRef.current?.setFocus?.();
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Input to enter gif query
  // after a typing delay (to prevent firing the endpoint too often)
  const textDebounceOnChange = useDebouncedCallback((text) => {
    if (text !== '') {
      gif
        .search(text)
        .then((response) => {
          setGifResults(response.results);
        })
        .catch((reason) => {
          Logger.logWithSentry(
            'SendGifModal:search - Error searching for gifs.',
            'error',
            {
              reason,
            },
          );
          status.setModalError();
        });
    }
  }, INPUT_TYPE_DELAY);
  useEffect(() => {
    // Cancel the debounce on component unmount
    return textDebounceOnChange.cancel;
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    gif
      .featured()
      .then((response) => {
        setGifResults(response.results);
      })
      .catch((reason) => {
        Logger.logWithSentry(
          'SendGifModal:featured - Error getting featured gifs.',
          'error',
          {
            reason,
          },
        );
        status.setModalError();
      });
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // split into 3 columns;
  const gifsForColumns: TenorResponse[][] = [[], [], []];
  for (const [i, gifResult] of gifResults.entries()) {
    gifsForColumns[i % gifsForColumns.length].push(gifResult);
  }
  const columnElements = gifsForColumns.map((columnGifs, idx) => {
    return (
      <div
        className="SendGifModal__results_column"
        key={`gif_results_column_${idx}`}
      >
        {columnGifs.map((gif) => {
          return (
            <div className="SendGifModal__gif" key={gif.id}>
              <img
                className="SendGifModal__gif__img"
                src={gif.media_formats.tinygif.url}
                alt={gif.content_description}
                onClick={async () => {
                  event.track('Send Gif');
                  let isConsentConfirmed;
                  try {
                    isConsentConfirmed = await transcriptView.confirmConsent();
                  } catch {
                    // Consent was explicitly denied, return without sending the message
                    return;
                  }
                  transcriptView.sendMessage({
                    message: '',
                    isConsentConfirmed,
                    externalAttachments: [
                      {
                        url: gif.media_formats.nanogif.url,
                        mimeType: 'image/gif',
                      },
                    ],
                  });
                  close();
                }}
              />
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <MessengerModalPartial
      title={t('SendGifModal.title')}
      close={() => {
        close();
        event.track('Click Gif modal dismiss');
      }}
      status={status.value}
      compactHeader
    >
      <MarketInputText
        className="SendGifModal__search"
        // Tenor requires the exact copy "Search Tenor." as the placeholder text for attribution.
        // https://developers.google.com/tenor/guides/attribution
        placeholder="Search Tenor."
        onMarketInputValueChange={(event: MarketInputValueChangeEvent) => {
          setSearchQuery(event.detail.value);
          textDebounceOnChange(event.detail.value);
        }}
        ref={inputRef}
        value={searchQuery}
        data-testid="SendGifModal__search"
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <SearchIcon />
        </MarketAccessory>
        {searchQuery && (
          <MarketAccessory
            slot="trailing-accessory"
            onClick={() => {
              setSearchQuery('');
            }}
            size="icon"
          >
            <ClearIcon />
          </MarketAccessory>
        )}
      </MarketInputText>
      <div className="SendGifModal__results">{columnElements}</div>
    </MessengerModalPartial>
  );
});

export default SendGifModal;
