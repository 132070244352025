import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { MarketButton, MarketCheckbox, MarketRow } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { KEY_MESSAGES_PLUGIN_GA } from 'src/stores/FeatureFlagStore';
import './DoNotDisturbModal.scss';

export type DoNotDisturbModalProps = {
  onConfirm?: () => void;
};

/**
 * A confirmation modal asking users to acknowledge the effects of "Do Not Disturb."
 *
 * @param {() => void} [onConfirm]
 * Callback executed when the DoNotDisturb setting is confirmed.
 */
const DoNotDisturbModal = observer(
  ({ onConfirm }: DoNotDisturbModalProps): ReactElement => {
    const { event, settings, modal, user, subscription, featureFlag } =
      useMessengerControllerContext();
    const { t } = useTranslation();
    const [confirmedUnderstanding, setConfirmedUnderstanding] = useState(false);

    // This checkbox basically says "I understand 'Do Not Disturb'," which enables
    // the "Confirm" button for this modal.
    const confirmCheckbox = (
      <MarketRow
        onMarketRowSelected={() => setConfirmedUnderstanding(true)}
        onMarketRowDeselected={() => setConfirmedUnderstanding(false)}
        controlPosition="leading"
        interactive
        data-testid="DoNotDisturbModal__checkbox"
      >
        <MarketCheckbox slot="control" />
        <label slot="label">{t('DoNotDisturbModal.checkbox')}</label>
      </MarketRow>
    );

    // Construct the modal contents. This is basically a bunch of text and a double-confirmation
    // that the user wants to enable "Do Not Disturb."
    return (
      <MessengerModalPartial
        title={t('DoNotDisturbModal.title')}
        close={() => {
          event.track('Click Do Not Disturb Modal Dismiss');
          modal.closeModal();
        }}
        primaryButton={
          <MarketButton
            rank="primary"
            onClick={() => {
              onConfirm?.();
              event.track('Click Do Not Disturb Modal Confirm');
              settings.setUserDoNotDisturb(true);
              modal.closeModal();
            }}
            disabled={!confirmedUnderstanding || undefined}
          >
            {t('DoNotDisturbModal.confirm')}
          </MarketButton>
        }
        status={null}
      >
        <p className="paragraph-30">{t('DoNotDisturbModal.are_you_sure')}</p>
        <div className="paragraph-30">
          <ul className="DoNotDisturbModal__list">
            {featureFlag.get(KEY_MESSAGES_PLUGIN_GA) && (
              <li>
                <Trans i18nKey="DoNotDisturbModal.fine_print.messages_plugin">
                  {''}
                  <span className="semibold-30">
                    The Text Us button will be disabled
                  </span>
                  {
                    ' and you will no longer receive new customer inquiries via Square Messages'
                  }
                </Trans>
              </li>
            )}
            <li>
              <Trans i18nKey="DoNotDisturbModal.fine_print.no_notifications">
                {'You will '}
                <span className="semibold-30">
                  no longer receive notifications for customer replies
                </span>
                {
                  ' to Receipts, Marketing Campaigns, Invoices, Payment Links, Appointments, and more'
                }
              </Trans>
            </li>
            <li>
              {t('DoNotDisturbModal.fine_print.auto_reply', {
                businessName: user.businessName,
              })}
            </li>
            {subscription.isSubscribed && (
              <li>
                {t('DoNotDisturbModal.fine_print.voicemail', {
                  businessName: user.businessName,
                })}
              </li>
            )}
            <li>{t('DoNotDisturbModal.fine_print.assistant_section')}</li>
          </ul>
        </div>
        {confirmCheckbox}
      </MessengerModalPartial>
    );
  },
);

export default DoNotDisturbModal;
