/**
 * An icon representing a generic file.
 */
import React, { ReactElement } from 'react';

function GenericFileIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="20"
      fill="none"
      viewBox="0 0 14 20"
    >
      <title>File</title>
      <path
        fill="#000"
        fillOpacity=".9"
        d="M8 0H3C1.34 0 0 1.34 0 3v14c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V6L8 0Zm3.17 6H8V2.83L11.17 6ZM12 17c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v6h6v9Z"
      />
    </svg>
  );
}

export default GenericFileIcon;
