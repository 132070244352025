import React, { ReactElement, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  MarketAccessory,
  MarketRow,
  MarketTooltip,
} from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import LocationPinIcon from 'src/svgs/LocationPinIcon';
import CopyIcon from 'src/svgs/CopyIcon';
import { Unit } from 'src/MessengerTypes';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import './BusinessNumberRow.scss';

export type BusinessNumberRowProps = {
  unit: Unit;
  onCopy?: () => void;
};

/**
 * Renders an individual row for a provided business number.
 *
 * @param {Unit} unit
 * A unit with a dedicated number.
 */
const BusinessNumberRow = observer(
  ({ unit, onCopy }: BusinessNumberRowProps): ReactElement => {
    const { subscription } = useMessengerControllerContext();
    const { t } = useTranslation();
    const copyTooltipRef = useRef<HTMLMarketTooltipElement>(null);

    const handleOnCopy = (): void => {
      navigator.clipboard.writeText(
        unit.subscription?.dedicatedNumber?.displayDedicatedNumber || '',
      );
      onCopy?.();
      copyTooltipRef?.current?.openTooltip?.();
    };

    const isDisabled = subscription.isSubscriptionDelinquent;

    return (
      <MarketRow
        interactive
        transient
        disabled={isDisabled || undefined}
        onClick={handleOnCopy}
        onKeyDown={(e) => onKeyDownEnter(e, handleOnCopy)}
        data-testid="BusinessNumberRow"
      >
        <MarketAccessory
          slot="leading-accessory"
          size="image"
          className={classNames('BusinessNumberRow__location-pin', {
            BusinessNumberRow__disabled: isDisabled,
          })}
        >
          <LocationPinIcon />
        </MarketAccessory>
        <label slot="label">{unit.name}</label>
        <p slot="subtext">{unit.address}</p>
        <label slot="side-label" className="BusinessNumberRow__side-text">
          {unit.subscription?.dedicatedNumber?.displayDedicatedNumber}
        </label>
        {unit.subscription?.isPendingCancellation && (
          <p slot="side-subtext" className="BusinessNumberRow__side-text">
            {t('SettingsPage.business_numbers.pending_cancellation')}
          </p>
        )}
        {!isDisabled && (
          <MarketTooltip
            slot="trailing-accessory"
            interaction="click"
            popoverPlacement="top"
            ref={copyTooltipRef}
          >
            <span slot="content">
              {t('SettingsPage.business_numbers.copy_text')}
            </span>
            <span slot="trigger" className="BusinessNumberRow__copy-icon">
              <CopyIcon />
            </span>
          </MarketTooltip>
        )}
      </MarketRow>
    );
  },
);

export default BusinessNumberRow;
