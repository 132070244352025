import React, { Component, ReactElement, ReactNode, RefObject } from 'react';
import { observer } from 'mobx-react';
import {
  Appointment,
  CheckoutLink,
  Coupon,
  Estimate,
  Feedback,
  FeedbackFacet,
  Invoice,
  LocalUtterance,
  Marketing,
  MediumTimestamp as Timestamp,
  Order,
  Receipt,
  TranscriptViewItem as TranscriptViewItemType,
} from 'src/MessengerTypes';
import CustomerEventCard from 'src/pages/TranscriptViewPage/components/CustomerEventCard/CustomerEventCard';
import MerchantEventCard from 'src/pages/TranscriptViewPage/components/MerchantEventCard/MerchantEventCard';
import GeneralEventCard from 'src/pages/TranscriptViewPage/components/GeneralEventCard/GeneralEventCard';
import AppointmentIcon from 'src/svgs/AppointmentIcon';
import MarketingIcon from 'src/svgs/MarketingIcon';
import ReceiptIcon from 'src/svgs/ReceiptIcon';
import CouponPercentageIcon from 'src/svgs/CouponPercentageIcon';
import HappyIcon from 'src/svgs/HappyIcon';
import SadIcon from 'src/svgs/SadIcon';
import {
  moneyToCurrencyString,
  paymentToCurrencyString,
} from 'src/utils/moneyUtils';
import { useDate } from 'src/utils/timeUtils';
import Utterance from 'src/pages/TranscriptViewPage/components/Utterance/Utterance';
import {
  Medium,
  Utterance as UtteranceProto,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import MediumTimestamp from 'src/pages/TranscriptViewPage/components/MediumTimestamp/MediumTimestamp';
import { t } from 'i18next';
import GeneralEventBanner from 'src/pages/TranscriptViewPage/components/GeneralEventBanner/GeneralEventBanner';
import Logger from 'src/Logger';
import OrderIcon from 'src/svgs/OrderIcon';
import InvoiceIcon from 'src/svgs/InvoiceIcon';
import EstimateIcon from 'src/svgs/EstimateIcon';
import CheckoutLinkCardIcon from 'src/svgs/CheckoutLinkCardIcon';
import CustomerImage from 'src/components/CustomerImage/CustomerImage';
import {
  getCouponUrl,
  getCustomerMarketingUrl,
  getEditAppointmentUrl,
  getEditRecurringAppointmentUrl,
  getTransactionLink,
} from 'src/utils/url';
import FormSubmissionEventCard from 'src/pages/TranscriptViewPage/components/FormSubmissionEventCard/FormSubmissionEventCard';
import MessagesPluginEntryEventCard from 'src/pages/TranscriptViewPage/components/MessagesPluginEntryEventCard/MessagesPluginEntryEventCard';
import MessengerControllerContext from 'src/context/MessengerControllerContext';
import MessengerController from 'src/MessengerController';
import VoicemailEventCard from 'src/pages/TranscriptViewPage/components/VoicemailEventCard/VoicemailEventCard';
import AppointmentEventBanner from 'src/pages/TranscriptViewPage/components/AppointmentEventBanner/AppointmentEventBanner';
import SquareGoReviewEventCard from 'src/pages/TranscriptViewPage/components/SquareGoReviewCard/SquareGoReviewEventCard';
import MissedCallEventCard from 'src/pages/TranscriptViewPage/components/MissedCallEventCard/MissedCallEventCard';

export type TranscriptViewItemProps = {
  item: TranscriptViewItemType;
  isLastItem?: boolean;
  itemRef?: RefObject<HTMLDivElement>;
};

/**
 * This component extracts the information of a TranscriptViewItem object and decides
 * what kind of component to use to display it. A conversation view item is an element
 * that is rendered in the list of the TranscriptViewPage. It represents a message
 * or event that happen between the buyer and seller, such as utterances or appointment
 * events.
 *
 * Rendering is done by checking the 2 types of a TranscriptViewItem:
 * 1) dataType: this marks the type of information stored in an item. For example,
 * 'UTTERANCE' will contain a text and its metadata such as speaker type. 'APPOINTMENT' will
 * contain the appointment date, service, and staff information.
 * 2) componentType: this refers to how the item should be rendered. For example,
 * 'CUSTOMER_EVENT_CARD' looks like an utterance on the customer side with extra information above
 * the text. 'GENERAL_EVENT_CARD' is a card that is centered in the middle of a conversation.
 *
 * For component types, there are 2 high level visual categories:
 * - Card: A rounded-rectangle that contains content based on the dataType. It is inline with
 * other cards in a conversation.
 * - Banner: A rectangle that spans the full width of Messenger. It sticks to the top and is
 * independent of the list of cards.
 *
 * @example
 * Basic usage:
 * <TranscriptViewItem item={positiveFeedbackEvent} />
 *
 * With optional:
 * <TranscriptViewItem
 *   item={positiveFeedbackEvent}
 *   isLastItem={index === arr.length}
 * />
 * @param {TranscriptViewItemType} item
 * The event that we want to display.
 * @param {boolean} [isLastItem]
 * (Optional) If true, this is the last item in the list. Used to show
 * send status on utterance.
 * @param {RefObject<HTMLDivElement>} [itemRef]
 * (Optional) Ref for the TranscriptViewItem element.
 * @author klim
 */
class TranscriptViewItem extends Component<TranscriptViewItemProps> {
  static contextType = MessengerControllerContext;
  declare context: MessengerController;

  /**
   * Method to return the customer image element for the current conversation view item.
   * Returns undefined if no customer image should be shown.
   */
  getCustomerImage = (): ReactElement | undefined => {
    const { item } = this.props;
    const { transcriptView } = this.context;
    const { displayName } = transcriptView.transcript;

    if (!item.showCustomerImage) {
      return undefined;
    }

    return (
      <CustomerImage
        customerInitials={displayName?.initials}
        imageUrl={displayName?.profileImageUrl}
        customerName={displayName?.name || t('TranscriptViewPage.unknown_user')}
      />
    );
  };

  /**
   * Renders a regular utterance, which includes backend retrieved utterances
   * and local utterances.
   */
  renderUtterance = (): ReactElement => {
    const { item, isLastItem, itemRef } = this.props;
    const { data } = item;
    const localUtterance = data as LocalUtterance;
    const utterance = localUtterance.utterance;

    // Determine if we need to show send status
    let showSendStatus = false;
    if (
      utterance.sendStatus === UtteranceProto.SendStatus.PENDING ||
      utterance.sendStatus === UtteranceProto.SendStatus.FAILED ||
      isLastItem
    ) {
      showSendStatus = true;
    }

    return (
      <Utterance
        localUtterance={localUtterance}
        customerImage={this.getCustomerImage()}
        showSentStatus={showSendStatus}
        key={utterance.id ?? utterance.metadata?.clientId}
        utteranceRef={itemRef}
      />
    );
  };

  /**
   * Renders the timestamp with the medium (if utterance) or product (if contextual event),
   * and the unit that this item represents, if present.
   */
  renderTimestamp = (): ReactElement => {
    const { item } = this.props;
    const { timestampMillis, data } = item;
    const timestamp = data as Timestamp;

    return (
      <MediumTimestamp
        timestampMillis={timestampMillis}
        medium={timestamp.medium ?? Medium.MEDIUM_UNRECOGNIZED}
      />
    );
  };

  /**
   * Renders an appointment card to denote an appointment that has started/completed. Shows
   * the service, staff, and status of it as well. Item can be clicked that links to
   * the Appointments page.
   */
  renderAppointmentGeneralEventCard = (): ReactElement => {
    const { item } = this.props;
    const { event, featureFlag } = this.context;
    const { timestampMillis, data } = item;
    const appointment = data as Appointment;

    let title = '';
    let subtitle = '';
    let link: string | undefined = undefined;

    // title
    if (appointment.itemNames) {
      title = appointment.itemNames?.join(', ');
    } else {
      title = t('ContextualEvent.appointment.appointment');
    }

    // subtitle
    const staffNames = appointment.staffNames?.join(', ') ?? '';
    let status = '';
    switch (appointment.statusCode) {
      case 'CANCELLED_BY_BUYER':
        status = t('ContextualEvent.appointment.status.cancelled_by_buyer');
        break;
      case 'CANCELLED_BY_SELLER':
        status = t('ContextualEvent.appointment.status.cancelled_by_seller');
        break;
      case 'NO_SHOW':
        status = t('ContextualEvent.appointment.status.no_show');
        break;
      case 'PENDING':
        status = t('ContextualEvent.appointment.status.pending');
        break;
      default:
    }
    if (staffNames !== '' && status !== '') {
      subtitle = `${staffNames}・${status}`;
    } else {
      subtitle = staffNames || status;
    }

    // link
    if (appointment.reservationId) {
      link = appointment.isRecurring
        ? getEditRecurringAppointmentUrl(
            appointment.reservationId,
            `${appointment.dateStart}`,
            featureFlag.useAppSubdomain,
          )
        : getEditAppointmentUrl(
            appointment.reservationId,
            featureFlag.useAppSubdomain,
          );
    }

    return (
      <GeneralEventCard
        timestampMillis={timestampMillis}
        icon={<AppointmentIcon />}
        title={title}
        subtitle={subtitle}
        link={link}
        linkText={t('ContextualEvent.view.appointment')}
        track={() => event.track('Click View Appointment Past')}
      />
    );
  };

  /**
   * Renders a customer feedback card, which includes its sentiment, facet, and associated sale.
   * If there is a comment or attached utterance, render the text as well.
   */
  renderFeedbackCustomerEventCard = (): ReactElement => {
    const { item, itemRef } = this.props;
    const { event, user, featureFlag } = this.context;
    const { timestampMillis, data, attachedUtterance } = item;
    const feedback = data as Feedback;

    let icon: ReactElement = <div />;
    let title = '';
    let subtitle = '';
    let description: string | undefined = undefined;
    let utterance: string | undefined = undefined;
    let link = '';

    // icon, title
    if (feedback.sentiment === 'POSITIVE') {
      icon = <HappyIcon />;
      title = t('ContextualEvent.feedback.sentiment.positive');
    }
    if (feedback.sentiment === 'NEGATIVE') {
      title = t('ContextualEvent.feedback.sentiment.negative');
      icon = <SadIcon />;
    }

    // subtitle
    const date = useDate(timestampMillis);
    if (feedback.payment && feedback.payment.amount) {
      link = getTransactionLink(
        feedback.paymentToken ?? '',
        featureFlag.useAppSubdomain,
      );
      const currencyAmount = paymentToCurrencyString(
        feedback.payment,
        user.locale,
      );
      subtitle = t('ContextualEvent.feedback.payment', {
        currencyAmount,
        date,
      });
    } else {
      subtitle = t('common.time.on_date', {
        date,
      });
    }

    // description
    if (feedback.facets) {
      const facetsText = feedback.facets.map((facet: FeedbackFacet) => {
        switch (facet) {
          case 'INVALID':
            return t('ContextualEvent.feedback.facets.invalid');
          case 'OTHER':
            return t('ContextualEvent.feedback.facets.other');
          case 'PRODUCT_QUALITY':
            return t('ContextualEvent.feedback.facets.product_quality');
          case 'WAIT_TIME':
            return t('ContextualEvent.feedback.facets.wait_time');
          case 'CUSTOMER_SERVICE':
            return t('ContextualEvent.feedback.facets.customer_service');
          case 'PRODUCT_SELECTION':
            return t('ContextualEvent.feedback.facets.product_selection');
          case 'QUALITY':
            return t('ContextualEvent.feedback.facets.quality');
          case 'THOROUGHNESS':
            return t('ContextualEvent.feedback.facets.thoroughness');
          case 'ENVIRONMENT':
            return t('ContextualEvent.feedback.facets.environment');
          case 'TIMELINESS':
            return t('ContextualEvent.feedback.facets.timeliness');
          case 'ROUTE_TAKEN':
            return t('ContextualEvent.feedback.facets.route_taken');
          case 'VEHICLE':
            return t('ContextualEvent.feedback.facets.vehicle');
          case 'TRAVEL_TIME':
            return t('ContextualEvent.feedback.facets.travel_time');
          case 'DRIVER':
            return t('ContextualEvent.feedback.facets.driver');
          case 'OFFERINGS':
            return t('ContextualEvent.feedback.facets.offerings');
          case 'PROFESSIONALISM':
            return t('ContextualEvent.feedback.facets.professionalism');
          case 'SELECTION':
            return t('ContextualEvent.feedback.facets.selection');
          case 'AMBIANCE':
            return t('ContextualEvent.feedback.facets.ambiance');
          case 'ORGANIZATION':
            return t('ContextualEvent.feedback.facets.organization');
          case 'LOCATION':
            return t('ContextualEvent.feedback.facets.location');
          case 'SERVICE':
            return t('ContextualEvent.feedback.facets.service');
          case 'UNSPECIFIED':
            return t('ContextualEvent.feedback.facets.unspecified');
          case 'PERFORMANCE_LENGTH':
            return t('ContextualEvent.feedback.facets.performance_length');
          case 'EVENT_LENGTH':
            return t('ContextualEvent.feedback.facets.event_length');
          case 'MOVIE_LENGTH':
            return t('ContextualEvent.feedback.facets.movie_length');
          case 'UNKNOWN_CHARGE':
            return t('ContextualEvent.feedback.facets.unknown_charge');
          case 'UNDELIVERED':
            return t('ContextualEvent.feedback.facets.undelivered');
          default:
            return t('ContextualEvent.feedback.facets.other');
        }
      });
      description = facetsText.join(', ');
    }

    // utterance
    if (feedback.comment) {
      utterance = feedback.comment;
    } else if (
      attachedUtterance?.utterance &&
      attachedUtterance.utterance.plainText
    ) {
      utterance = attachedUtterance.utterance.plainText;
    }

    return (
      <CustomerEventCard
        timestampMillis={timestampMillis}
        icon={icon}
        title={title}
        subtitle={subtitle}
        description={description}
        utterance={utterance}
        customerImage={this.getCustomerImage()}
        link={link}
        linkText={t('ContextualEvent.view.transaction')}
        track={() => event.track('Click View Transaction Feedback')}
        reactions={attachedUtterance?.utterance?.metadata?.reactions?.reactions}
        cardRef={itemRef}
        id={attachedUtterance?.utterance.id}
      />
    );
  };

  /**
   * Render a customer reply to receipt as a card, which includes the sale.
   */
  renderReceiptCustomerEventCard = (): ReactElement => {
    const { item, itemRef } = this.props;
    const { event, user, featureFlag } = this.context;
    const { timestampMillis, data, attachedUtterance } = item;
    const receipt = data as Receipt;

    let title = '';
    let subtitle = '';
    const description: string | undefined = undefined;
    let utterance: string | undefined = undefined;
    let link = '';

    // title
    title = t('ContextualEvent.receipt.title');

    // subtitle
    const date = useDate(timestampMillis);
    if (receipt.payment && receipt.payment.amount) {
      link = getTransactionLink(
        receipt.paymentToken ?? '',
        featureFlag.useAppSubdomain,
      );
      const currencyAmount = paymentToCurrencyString(
        receipt.payment,
        user.locale,
      );
      subtitle = t('ContextualEvent.feedback.payment', {
        currencyAmount,
        date,
      });
    } else {
      subtitle = t('common.time.on_date', {
        date,
      });
    }

    // utterance
    if (receipt.comment) {
      utterance = receipt.comment;
    } else if (
      attachedUtterance?.utterance &&
      attachedUtterance.utterance.plainText
    ) {
      utterance = attachedUtterance.utterance.plainText;
    }

    return (
      <CustomerEventCard
        timestampMillis={timestampMillis}
        icon={<ReceiptIcon />}
        title={title}
        subtitle={subtitle}
        description={description}
        utterance={utterance}
        customerImage={this.getCustomerImage()}
        link={link}
        linkText={t('ContextualEvent.view.transaction')}
        track={() => event.track('Click View Transaction Receipt')}
        reactions={attachedUtterance?.utterance?.metadata?.reactions?.reactions}
        cardRef={itemRef}
        id={attachedUtterance?.utterance.id}
      />
    );
  };

  /**
   * Render a reply to a marketing campaign as a card.
   */
  renderMarketingCustomerEventCard = (): ReactElement => {
    const { item, itemRef } = this.props;
    const { event, featureFlag } = this.context;
    const { timestampMillis, data, attachedUtterance } = item;
    const marketing = data as Marketing;

    let title = '';
    let subtitle = '';
    const description: string | undefined = undefined;
    let utterance: string | undefined = undefined;
    let link = '';

    // title
    title = t('ContextualEvent.marketing.title');

    // subtitle
    const date = useDate(timestampMillis);
    if (marketing.name) {
      link =
        marketing.url ?? getCustomerMarketingUrl(featureFlag.useAppSubdomain);
      subtitle = `${marketing.name} ${t('common.time.on_date', {
        date,
      })}`;
    } else {
      subtitle = t('common.time.on_date', {
        date,
      });
    }

    // utterance
    if (marketing.comment) {
      utterance = marketing.comment;
    } else if (
      attachedUtterance?.utterance &&
      attachedUtterance.utterance.plainText
    ) {
      utterance = attachedUtterance.utterance.plainText;
    }

    return (
      <CustomerEventCard
        timestampMillis={timestampMillis}
        icon={<MarketingIcon />}
        title={title}
        subtitle={subtitle}
        description={description}
        utterance={utterance}
        customerImage={this.getCustomerImage()}
        link={link}
        linkText={t('ContextualEvent.view.campaign')}
        track={() => event.track('Click View Campaign')}
        reactions={attachedUtterance?.utterance?.metadata?.reactions?.reactions}
        cardRef={itemRef}
        id={attachedUtterance?.utterance.id}
      />
    );
  };

  /**
   * Render a coupon that the merchant had sent as a card, which includes the name and the
   * expiration.
   */
  renderCouponMerchantEventCard = (): ReactElement => {
    const { item, isLastItem, itemRef } = this.props;
    const { event } = this.context;
    const { timestampMillis, data, attachedUtterance } = item;
    const coupon = data as Coupon;

    let title = '';
    let subtitle = '';
    let link = undefined;

    // title
    if (coupon.name) {
      title = coupon.name;
    }

    // subtitle
    if (coupon.expirationMillis) {
      subtitle = t('ContextualEvent.coupon.expires', {
        date: useDate(coupon.expirationMillis),
      });
    }

    // link
    if (coupon.id) {
      link = getCouponUrl(coupon.id);
    }

    return (
      <MerchantEventCard
        timestampMillis={timestampMillis}
        icon={<CouponPercentageIcon />}
        title={title}
        subtitle={subtitle}
        link={link}
        linkText={t('ContextualEvent.view.coupon')}
        utterance={attachedUtterance}
        showSentStatus={isLastItem}
        track={() => event.track('Click View Coupon')}
        cardRef={itemRef}
        id={attachedUtterance?.utterance.id}
      />
    );
  };

  /**
   * Render an order update as a card, which includes the status, value, and item count.
   */
  renderOrderMerchantEventCard = (): ReactElement => {
    const { item, isLastItem, itemRef } = this.props;
    const { event, user } = this.context;
    const { timestampMillis, data, attachedUtterance } = item;
    const order = data as Order;

    let title = t('ContextualEvent.type.order');
    let subtitle = '';
    const link = order.url;

    // title
    if (order.event) {
      switch (order.event) {
        case UtteranceProto.Metadata.Order.OrderEvent.ORDER_CONFIRMED:
          title = t('ContextualEvent.order.confirmed');
          break;
        case UtteranceProto.Metadata.Order.OrderEvent.ORDER_READY_FOR_PICKUP:
          title = t('ContextualEvent.order.pickup');
          break;
        case UtteranceProto.Metadata.Order.OrderEvent.ORDER_SHIPPED:
          title = t('ContextualEvent.order.shipped');
          break;
        case UtteranceProto.Metadata.Order.OrderEvent.ORDER_CANCELLED:
          title = t('ContextualEvent.order.cancelled');
          break;
        default:
      }
    }

    // subtitle
    if (order.amount && order.numItems) {
      const adjustedAmount = {
        ...order.amount,
      };

      if (
        order.event ===
          UtteranceProto.Metadata.Order.OrderEvent.ORDER_CANCELLED &&
        adjustedAmount.amount
      ) {
        // Order amounts are always positive for canceled orders,
        // but need to be displayed as negative.
        adjustedAmount.amount *= -1;
      }

      const amount = moneyToCurrencyString(adjustedAmount, user.locale);
      const itemCount = t('ContextualEvent.order.item', {
        count: order.numItems,
      });
      subtitle = `${amount}・${itemCount}`;
    }

    return (
      <MerchantEventCard
        timestampMillis={timestampMillis}
        icon={<OrderIcon />}
        title={title}
        subtitle={subtitle}
        link={link}
        linkText={t('ContextualEvent.view.order')}
        utterance={attachedUtterance}
        showSentStatus={isLastItem}
        track={() => event.track('Click View Order')}
        cardRef={itemRef}
        id={attachedUtterance?.utterance.id}
      />
    );
  };

  /**
   * Render an invoice update as a card, which includes the status, value, and name.
   */
  renderInvoiceMerchantEventCard = (): ReactElement => {
    const { item, isLastItem, itemRef } = this.props;
    const { event, user } = this.context;
    const { timestampMillis, data, attachedUtterance } = item;
    const invoice = data as Invoice;

    let title = t('ContextualEvent.type.invoice');
    let subtitle = '';
    const link = invoice.url;

    // title
    if (invoice.event) {
      switch (invoice.event) {
        case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_CREATED:
          title = t('ContextualEvent.invoice.created');
          break;
        case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_UPDATED:
          title = t('ContextualEvent.invoice.updated');
          break;
        case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_PAID:
          title = t('ContextualEvent.invoice.paid');
          break;
        case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_CANCELLED:
          title = t('ContextualEvent.invoice.canceled');
          break;
        case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_DECLINED:
          title = t('ContextualEvent.invoice.declined');
          break;
        case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_REFUNDED:
          title = t('ContextualEvent.invoice.refunded');
          break;
        case UtteranceProto.Metadata.Invoice.InvoiceEvent
          .INVOICE_BANK_TRANSFER_PAYMENT_INITIATED:
          title = t('ContextualEvent.invoice.bank_transfer_initiated');
          break;
        case UtteranceProto.Metadata.Invoice.InvoiceEvent
          .INVOICE_BANK_TRANSFER_PAYMENT_COMPLETED:
          title = t('ContextualEvent.invoice.bank_transfer_completed');
          break;
        case UtteranceProto.Metadata.Invoice.InvoiceEvent
          .INVOICE_BANK_CHARGE_CONFIRMATION_REQUEST:
          title = t('ContextualEvent.invoice.bank_confirmation_request');
          break;
        default:
      }
    }

    // subtitle
    if (invoice.amount && invoice.name) {
      const amount = moneyToCurrencyString(invoice.amount, user.locale);
      subtitle = `${amount}・${invoice.name}`;
    }

    return (
      <MerchantEventCard
        timestampMillis={timestampMillis}
        icon={<InvoiceIcon />}
        title={title}
        subtitle={subtitle}
        link={link}
        linkText={t('ContextualEvent.view.invoice')}
        utterance={attachedUtterance}
        showSentStatus={isLastItem}
        track={() => event.track('Click View Invoice')}
        cardRef={itemRef}
        id={attachedUtterance?.utterance.id}
      />
    );
  };

  /**
   * Render an estimate update as a card, which includes the status, value, and name.
   */
  renderEstimateMerchantEventCard = (): ReactElement => {
    const { item, isLastItem, itemRef } = this.props;
    const { event, user } = this.context;
    const { timestampMillis, data, attachedUtterance } = item;
    const estimate = data as Estimate;

    let title = t('ContextualEvent.type.estimate');
    let subtitle = '';
    const link = estimate.url;

    // title
    if (estimate.event) {
      switch (estimate.event) {
        case UtteranceProto.Metadata.Estimate.EstimateEvent.ESTIMATE_CREATED:
          title = t('ContextualEvent.estimate.created');
          break;
        case UtteranceProto.Metadata.Estimate.EstimateEvent.ESTIMATE_UPDATED:
          title = t('ContextualEvent.estimate.updated');
          break;
        case UtteranceProto.Metadata.Estimate.EstimateEvent.ESTIMATE_ACCEPTED:
          title = t('ContextualEvent.estimate.accepted');
          break;
        case UtteranceProto.Metadata.Estimate.EstimateEvent.ESTIMATE_CANCELLED:
          title = t('ContextualEvent.estimate.canceled');
          break;
        default:
      }
    }

    // subtitle
    if (estimate.amount && estimate.name) {
      const amount = moneyToCurrencyString(estimate.amount, user.locale);
      subtitle = `${amount}・${estimate.name}`;
    }

    return (
      <MerchantEventCard
        timestampMillis={timestampMillis}
        icon={<EstimateIcon />}
        title={title}
        subtitle={subtitle}
        link={link}
        linkText={t('ContextualEvent.view.estimate')}
        utterance={attachedUtterance}
        showSentStatus={isLastItem}
        track={() => event.track('Click View Estimate')}
        cardRef={itemRef}
        id={attachedUtterance?.utterance.id}
      />
    );
  };

  /**
   * Renders a checkout link card.
   */
  renderCheckoutLinkMerchantEventCard = (): ReactElement => {
    const { item, isLastItem, itemRef } = this.props;
    const { event, user } = this.context;
    const { timestampMillis, data, attachedUtterance } = item;
    const checkoutLink = data as CheckoutLink;

    let title = '';
    let subtitle = '';
    let link = undefined;
    let icon = <CheckoutLinkCardIcon />;

    // title
    if (checkoutLink.title) {
      title = checkoutLink.title;
    } else {
      title = t('ContextualEvent.CheckoutLink.title');
    }

    // subtitle
    if (checkoutLink.amount && checkoutLink.amount.amount) {
      subtitle = moneyToCurrencyString(checkoutLink.amount, user.locale);
    } else {
      subtitle = t('ContextualEvent.CheckoutLink.customer_enters');
    }

    // link
    if (checkoutLink.url) {
      link = checkoutLink.url;
    }

    // icon
    if (checkoutLink.imageUrl) {
      icon = (
        <img
          data-testid="TranscriptViewItem__image"
          src={checkoutLink.imageUrl}
          alt={title}
        />
      );
    }

    return (
      <MerchantEventCard
        timestampMillis={timestampMillis}
        icon={icon}
        title={title}
        subtitle={subtitle}
        link={link}
        linkText={t('ContextualEvent.CheckoutLink.view_link')}
        utterance={attachedUtterance}
        showSentStatus={isLastItem}
        track={() => event.track('Click View Payment Link')}
        cardRef={itemRef}
        id={attachedUtterance?.utterance.id}
      />
    );
  };

  render(): ReactNode {
    const { item, itemRef } = this.props;
    const { dataType, componentType } = item;

    // Utterance
    if (dataType === 'UTTERANCE' && componentType === 'UTTERANCE_CARD') {
      return this.renderUtterance();
    }

    // Medium timestamp
    if (dataType === 'TIMESTAMP' && componentType === 'TIMESTAMP') {
      return this.renderTimestamp();
    }

    // Appointment general event card
    if (dataType === 'APPOINTMENT' && componentType === 'GENERAL_EVENT_CARD') {
      return this.renderAppointmentGeneralEventCard();
    }

    // Feedback customer event card
    if (dataType === 'FEEDBACK' && componentType === 'CUSTOMER_EVENT_CARD') {
      return this.renderFeedbackCustomerEventCard();
    }

    // Receipt customer event card
    if (dataType === 'RECEIPT' && componentType === 'CUSTOMER_EVENT_CARD') {
      return this.renderReceiptCustomerEventCard();
    }

    // Marketing customer event card
    if (dataType === 'MARKETING' && componentType === 'CUSTOMER_EVENT_CARD') {
      return this.renderMarketingCustomerEventCard();
    }

    // Form submission customer event card
    if (
      dataType === 'FORM_SUBMISSION' &&
      componentType === 'CUSTOMER_EVENT_CARD'
    ) {
      return (
        <FormSubmissionEventCard
          item={item}
          customerImage={this.getCustomerImage()}
          cardRef={itemRef}
        />
      );
    }

    // Messages plugin entry event card
    if (
      dataType === 'MESSAGES_PLUGIN_SUBMISSION' &&
      componentType === 'CUSTOMER_EVENT_CARD'
    ) {
      return (
        <MessagesPluginEntryEventCard
          item={item}
          customerImage={this.getCustomerImage()}
          cardRef={itemRef}
        />
      );
    }

    if (dataType === 'VOICEMAIL' && componentType === 'CUSTOMER_EVENT_CARD') {
      return (
        <VoicemailEventCard
          item={item}
          customerImage={this.getCustomerImage()}
          cardRef={itemRef}
        />
      );
    }

    // Coupon merchant event card
    if (dataType === 'COUPON' && componentType === 'MERCHANT_EVENT_CARD') {
      return this.renderCouponMerchantEventCard();
    }

    // Order merchant event card
    if (dataType === 'ORDER' && componentType === 'MERCHANT_EVENT_CARD') {
      return this.renderOrderMerchantEventCard();
    }

    // Invoice merchant event card
    if (dataType === 'INVOICE' && componentType === 'MERCHANT_EVENT_CARD') {
      return this.renderInvoiceMerchantEventCard();
    }

    // Estimate merchant event card
    if (dataType === 'ESTIMATE' && componentType === 'MERCHANT_EVENT_CARD') {
      return this.renderEstimateMerchantEventCard();
    }

    // Checkout link merchant event card
    if (
      dataType === 'CHECKOUT_LINK' &&
      componentType === 'MERCHANT_EVENT_CARD'
    ) {
      return this.renderCheckoutLinkMerchantEventCard();
    }

    // Appointment general event banner
    if (
      dataType === 'APPOINTMENT' &&
      componentType === 'GENERAL_EVENT_BANNER'
    ) {
      // TODO(wdetlor): Remove this line and corresponding method definition when the customer blade is dialled up
      return (
        <GeneralEventBanner>
          <AppointmentEventBanner item={item} />
        </GeneralEventBanner>
      );
    }

    // Square Go Review
    if (
      dataType === 'SQUARE_GO_REVIEW' &&
      componentType === 'CUSTOMER_EVENT_CARD'
    ) {
      return (
        <SquareGoReviewEventCard
          item={item}
          customerImage={this.getCustomerImage()}
          cardRef={itemRef}
        />
      );
    }

    if (
      dataType === 'INBOUND_CALL' &&
      componentType === 'CUSTOMER_EVENT_CARD'
    ) {
      return (
        <MissedCallEventCard
          item={item}
          customerImage={this.getCustomerImage()}
          cardRef={itemRef}
        />
      );
    }

    Logger.warn('Unable to render TranscriptViewItem');
    return null;
  }
}

export default observer(TranscriptViewItem);
