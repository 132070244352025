import { ITranscript } from 'src/gen/squareup/messenger/v3/messenger_service';
import type MessengerController from 'src/MessengerController';
import Transcript, { mapTranscriptFromProto } from './objects/Transcript';
import { TranscriptIdAlternative } from 'src/MessengerTypes';

/**
 * The single source of truth for any transcript data contained in the web application.
 * Other stores will read from here in order to read transcript data and write updates.
 */
class TranscriptsStore {
  private _stores: MessengerController;

  private _transcripts: Map<number, Transcript> = new Map();

  constructor(stores: MessengerController) {
    this._stores = stores;
  }

  /**
   * Sets transcripts data in the store. If a transcript object with this ID already exists, it updates
   * the data in the existing object. Otherwise, a new transcript object is created and set in the store.
   *
   * @param {ITranscript} transcript
   * The transcript object to set in the store.
   */
  set = (transcript: ITranscript): void => {
    const id = transcript?.id as number;
    if (this._transcripts.has(id)) {
      (this._transcripts.get(id) as Transcript).set(
        mapTranscriptFromProto(transcript),
      );
      return;
    }
    this._transcripts.set(
      id,
      Transcript.createFromProto(this._stores, transcript),
    );
  };

  /**
   * Sets transcripts data in the store without utterances. Used when we want to update the transcript's
   * details, but keeping utterances that were fetched previously intact.
   *
   * @param {ITranscript} transcript
   * The transcript object to set in the store.
   */
  setWithoutUtterances = (transcript: ITranscript): void => {
    this.set({
      ...transcript,
      details: { ...transcript.details, utterances: undefined },
    });
  };

  /**
   * Retrieves a transcript at a given ID. If no transcript exists for that ID, creates an empty
   * Transcript object with only the ID set.
   *
   * @param {number} id
   * The ID of the transcript to retrieve.
   */
  get = (id: number): Transcript => {
    if (!this._transcripts.has(id)) {
      this._transcripts.set(id, new Transcript(this._stores, { id }));
    }
    return this._transcripts.get(id) as Transcript;
  };

  /**
   * Retrieves a transcript object by an ID other than the transcript ID. Requires contacting the server
   * each time in order to associate the alternative ID with a unique transcript ID.
   *
   * @param {TranscriptIdAlternative} id
   * Some identifier other than a transcript ID that the server can use to retrieve a transcript by.
   */
  getByAlternativeId = async (
    id: TranscriptIdAlternative,
  ): Promise<Transcript> => {
    const [transcriptProto, cursor] =
      await this._stores.api.transcripts.getTranscriptWithUtterances({
        id,
      });

    // We do not set utterances here because applyTranscriptUpdate will do so.
    // We do this mainly to set the id and customerTokens that is required to
    // loadCustomers().
    this.setWithoutUtterances(transcriptProto);

    const transcript = this.get(transcriptProto.id as number);

    if (transcript.seekUtteranceId) {
      transcript.clearUtterances();
    }

    try {
      transcript.loadCustomers();
      await transcript.applyTranscriptUpdate({
        transcript: transcriptProto,
      });

      // Read more about this cursor condition here:
      // https://docs.google.com/document/d/1op0OYqpVxWaYturRI0j-ZVpEmkbVdKoD98rvCl9BhSE/edit?usp=sharing
      if (transcript.backwardCursor === undefined) {
        transcript.setBackwardCursor(cursor);
      }

      transcript.loadFutureContextualEvent();
      transcript.loadPhotosForGallery();
      transcript.status = 'SUCCESS';
    } catch (error) {
      transcript.status = 'ERROR';
      throw error;
    }
    return transcript;
  };

  /**
   * Checks if a transcript exists in the store.
   *
   * @param {number} id
   * The ID of the transcript to check.
   */
  has = (id: number): boolean => {
    return this._transcripts.has(id);
  };

  clear = (): void => {
    this._transcripts.clear();
  };
}

export default TranscriptsStore;
