import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import './EventCard.scss';
import { useTranslation } from 'react-i18next';
import { renderISO, useDate, useTime } from 'src/utils/timeUtils';
import {
  MarketAccessory,
  MarketButton,
  MarketButtonGroup,
  MarketContentCard,
  MarketRow,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import classNames from 'classnames';

export type SharedEventCardProps = {
  timestampMillis?: number;
  icon: ReactElement;
  title: string;
  subtitle?: string | ReactElement;
  link?: string;
  linkText?: string;
  track?: () => void;
  id?: number;
};

export type EventCardProps = SharedEventCardProps & {
  description?: string;
  body?: ReactNode;
  hasError?: boolean;
};

/**
 * The base component and styling for CustomerEventCard and MerchantEventCard,
 * which consist of a header portion for the icon and title, a body for
 * utterance message (if any), and footer for a button to link to another page
 * (e.g. coupon card will link to the coupon page)
 *
 * @example
 * Basic usage:
 * <EventCard
 *   type="CUSTOMER"
 *   timestampMillis={123553401000}
 *   icon={<CouponPercentageIcon />}
 *   title="Your coupon"
 *   subtitle="$10 off"
 * />
 *
 * With optional:
 * <EventCard
 *   type="CUSTOMER"
 *   timestampMillis={123553401000}
 *   icon={<CouponPercentageIcon />}
 *   title="Your coupon"
 *   subtitle="$10 off"
 *   description="Use by Mar 10"
 *   body="Here is your coupon!"
 *   link="/coupon/123"
 *   linkText="View Coupon"
 * />
 * @param {number} [timestampMillis]
 * (Optional) Time when this event happened, in millisecond. Shown when hovered.
 * @param {ReactElement} icon
 * Icon representing this event. It should generally be a square of size 32x32.
 * @param {string} title
 * Title of event.
 * @param {string | ReactElement} [subtitle]
 * (Optional) Usually additional information of the event.
 * @param {string} [description]
 * (Optional) Additional description of the event.
 * @param {string | ReactElement} [body]
 * (Optional) Content to fill the body section.
 * @param {string} [link]
 * (Optional) If present, show a button that will open a new tab to the link.
 * @param {string} [linkText]
 * (Optional) Should be present if link is provided. Text to show in the link button.
 * @param {Function} [track]
 * (Optional) If present, call this function when link is clicked. Used mainly to
 * trigger a track on an event.
 * @param {boolean} [hasError]
 * (Optional) If true, render the border red. Used when the utterance attached to this
 * event fails to send.
 * @param {number} [id]
 * (Optional) Utterance id for this event card. Used to highlight the card when the user
 * clicks on a search result.
 * @author klim
 */
const EventCard = observer((props: EventCardProps): ReactElement => {
  const {
    timestampMillis,
    icon,
    title,
    subtitle,
    description,
    body,
    link,
    linkText,
    track,
    hasError,
    id,
  } = props;
  const { t } = useTranslation();
  const {
    transcriptView: {
      transcript: { seekUtteranceId },
    },
  } = useMessengerControllerContext();

  /**
   * Prepare the timestamp component. This appears when the user hovers
   * over the main text area.
   */
  const timestamp = timestampMillis && (
    <time
      data-testid="UtteranceTimestamp"
      className="Utterance__timestamp"
      dateTime={renderISO(timestampMillis)}
    >
      {t('common.time.time_on_date', {
        date: useDate(timestampMillis),
        time: useTime(timestampMillis),
      })}
    </time>
  );

  /**
   * Render description, if present.
   */
  let descriptionComponent;
  if (description) {
    descriptionComponent = (
      <div className="EventCard__description">{description}</div>
    );
  }

  /**
   * Render body, if present.
   */
  let bodyComponent;
  if (body) {
    bodyComponent = <div>{body}</div>;
  }

  /**
   * Prepare function for link. Opens the link in a new tab
   * and track if present.
   */
  const linkWrapper = (): void => {
    if (link) {
      window.open(link, '_blank');
    }
    if (track) {
      track();
    }
  };

  /**
   * Render button for link, if linkText is present.
   */
  let linkComponent;
  if (link && linkText) {
    linkComponent = (
      <MarketButtonGroup alignment="fill">
        <MarketButton onClick={linkWrapper}>{linkText}</MarketButton>
      </MarketButtonGroup>
    );
  }

  const isSelectedUtterance = Boolean(id) && id === seekUtteranceId;

  return (
    <div className="EventCard" data-testid="EventCard">
      <div
        className={classNames('EventCard__content', {
          'EventCard__content--error': hasError,
          'EventCard__content--with-overlay': isSelectedUtterance,
        })}
        data-testid="EventCard__content"
      >
        <MarketContentCard>
          <MarketRow>
            <MarketAccessory
              className="EventCard__icon"
              slot="leading-accessory"
              size="image"
            >
              {icon}
            </MarketAccessory>
            <label slot="label">{title}</label>
            {subtitle && (
              <p className="EventCard__row__subtext" slot="subtext">
                {subtitle}
              </p>
            )}
          </MarketRow>
          {(description || body) && (
            <div className="EventCard__body">
              {descriptionComponent}
              {bodyComponent}
            </div>
          )}
          {linkComponent}
        </MarketContentCard>
      </div>
      {timestamp}
    </div>
  );
});

export default EventCard;
