import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import TranscriptsListPageHeader from 'src/pages/TranscriptsListPage/components/TranscriptsListPageHeader/TranscriptsListPageHeader';
import TranscriptsListPage from 'src/pages/TranscriptsListPage/TranscriptsListPage';
import SearchPage from 'src/pages/SearchPage/SearchPage';
import { Transition } from 'src/MessengerTypes';
import SearchBarV2 from 'src/components/SearchBar/SearchBarV2';
import { KEY_SEARCH } from 'src/stores/FeatureFlagStore';

export type TranscriptsListPageContainerProps = {
  transitionDirection: Transition;
};

/**
 * Component that contains the pages related to displaying lists of transcripts.
 *
 * @param {Transition} transitionDirection
 * Determines which direction the content slides in from.
 * If true, the content transitions from the right edge
 * of the drawer into the view. Else, transition from
 * the left edge of the drawer.
 */
const TranscriptsListPageContainer = observer(
  ({
    transitionDirection,
  }: TranscriptsListPageContainerProps): ReactElement => {
    const {
      navigation,
      transcriptsList,
      assistantTranscriptsList,
      featureFlag,
      searchV2,
    } = useMessengerControllerContext();
    const { t } = useTranslation();

    const isAssistantPage =
      navigation.primary.currentPageName === 'ASSISTANT_TRANSCRIPTS_LIST';
    const isSearchPage = navigation.primary.currentPageName === 'SEARCH';

    const searchBarV2Ref = useRef<HTMLMarketInputTextElement>(null);

    // Clear search query and blur search bar when navigating out of search
    const onNavigateBackFromSearch = useCallback(() => {
      searchV2.setQuery('', 'exit');
      searchBarV2Ref?.current?.blur();
    }, [searchV2]);

    useEffect(() => {
      // On mobile-web, using the browser back button to navigate back
      // should also clear the search query and blur the search bar
      if (navigation.navStoreForUrl.currentPageName === 'TRANSCRIPTS_LIST') {
        onNavigateBackFromSearch();
      }
    }, [navigation.navStoreForUrl.currentPageName, onNavigateBackFromSearch]);

    return (
      <>
        <TranscriptsListPageHeader
          isAssistantPage={isAssistantPage}
          title={
            isAssistantPage
              ? t('AssistantTranscriptsListPage.title')
              : undefined
          }
        />
        {featureFlag.get(KEY_SEARCH) && !isAssistantPage && (
          <SearchBarV2
            inputRef={searchBarV2Ref}
            onNavigateBack={onNavigateBackFromSearch}
          />
        )}
        {isSearchPage ? (
          <SearchPage transitionDirection={transitionDirection} />
        ) : (
          <TranscriptsListPage
            key={
              isAssistantPage
                ? 'AssistantTranscriptsListPage'
                : 'TranscriptsListPage'
            }
            id={
              isAssistantPage
                ? 'ASSISTANT_TRANSCRIPTS_LIST'
                : 'TRANSCRIPTS_LIST'
            }
            transcriptsList={
              isAssistantPage ? assistantTranscriptsList : transcriptsList
            }
            transitionDirection={transitionDirection}
            isAssistantPage={isAssistantPage}
          />
        )}
      </>
    );
  },
);

export default TranscriptsListPageContainer;
