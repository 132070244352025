import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import EmptyState from 'src/components/EmptyState/EmptyState';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { getDashboardPricingAndSubscriptionsUrl } from 'src/utils/url';
import { MarketButton } from 'src/components/Market';
import MobilePaymentIcon from 'src/svgs/MobilePaymentIcon';
import { useLongDateFromMicroseconds } from 'src/utils/timeUtils';

/**
 * An empty state to show when the unit's M+ subscription has been successfully
 * verified and activated in the M+ v2 onboarding flow.
 */
const MessagesPlusSuccessEmptyState = observer((): ReactElement => {
  const { t } = useTranslation();
  const { navigation, subscription, featureFlag } =
    useMessengerControllerContext();
  const { freeTrialEndAt } = subscription;

  const description = freeTrialEndAt
    ? t('MessagesPlusSuccessEmptyState.free_trial_description', {
        date: useLongDateFromMicroseconds(freeTrialEndAt),
      })
    : t('MessagesPlusSuccessEmptyState.active_subscription_description');

  return (
    <EmptyState
      icon={<MobilePaymentIcon />}
      title={t('MessagesPlusSuccessEmptyState.title')}
      description={description}
      button={
        <MarketButton
          onClick={() => {
            navigation.navStoreForUrl.navigateTo('NEW_MESSAGE');
          }}
        >
          {t('MessagesPlusSuccessEmptyState.button_text')}
        </MarketButton>
      }
      secondaryButton={
        <MarketButton
          href={getDashboardPricingAndSubscriptionsUrl(
            featureFlag.useAppSubdomain,
          )}
          target="_blank"
        >
          {t('MessagesPlusSuccessEmptyState.manage_subscription_button_text')}
        </MarketButton>
      }
    />
  );
});

export default MessagesPlusSuccessEmptyState;
