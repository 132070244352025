/**
 * A small "x" icon. This is used to clear an input bar,
 * as opposed to CloseIcon, which is a slightly different
 * "x" icon that's used to close all of Messenger.
 */

import React, { ReactElement } from 'react';

function ClearIcon(): ReactElement {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Clear</title>
      <path d="M16 16l8 8M24 16l-8 8" stroke="#71767B" strokeWidth="2" />
    </svg>
  );
}

export default ClearIcon;
