import { makeAutoObservable, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import type MessengerController from 'src/MessengerController';
import { DEFAULT_PAGE_SIZE } from 'src/api/SearchApi';
import Logger from 'src/Logger';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { mediumToEventNameString } from 'src/utils/transcriptUtils';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

// One minute session timeout in milliseconds
const SESSION_TIMEOUT_MS = 60000;

export type CompleteSessionReason =
  | 'timeout'
  | 'query_changed'
  | 'assistant_toggle'
  | 'no_results'
  | 'fetch_failed'
  | 'exit';

type ContactClickMetadata = {
  target: 'contact';
  contactToken?: string;
  rank: number;
  medium?: Medium;
};

type UtteranceClickMetadata = {
  target: 'utterance';
  utteranceId: number;
  rank: number;
};

type ClickMetadata = ContactClickMetadata | UtteranceClickMetadata;

class SearchLoggingStore {
  private _stores: MessengerController;

  /**
   * Unique identifier for the current search session.
   */
  private _sessionId?: string;

  /**
   * Search query for the current search session.
   */
  private _query?: string;

  /**
   * Timeout for the current search session.
   * When this timeout is reached, the session will end and be logged.
   */
  private _timer?: NodeJS.Timeout;

  /**
   * Timestamp when the current search session started.
   */
  private _sessionStartAt?: number;

  /**
   * Timestamp when the current search session ended.
   */
  private _sessionEndAt?: number;

  /**
   * Timestamp when the user last clicked in the search pane.
   */
  private _lastClickedAt?: number;

  /**
   * Whether the user has clicked on any utterance search results.
   */
  private _hasClickedUtterances = false;

  /**
   * Whether the user has clicked on any contact search results.
   */
  private _hasClickedContacts = false;

  constructor(stores: MessengerController) {
    makeAutoObservable<
      SearchLoggingStore,
      '_lastClickedAt' | '_sessionStartAt'
    >(this);
    this._stores = stores;

    // Extend timeout whenever user clicks in the search pane.
    reaction(
      () => this._lastClickedAt,
      () => this._lastClickedAt !== undefined && this._startNewTimeout(),
    );
  }

  /**
   * Whether the current search session has a start time.
   */
  get hasSessionStartTime(): boolean {
    return Boolean(this._sessionStartAt);
  }

  // Starts a new timeout for the session.
  // This can be used to extend the timeout if the user is still interacting with the search results.
  private _startNewTimeout = (): void => {
    if (this._timer) clearTimeout(this._timer);
    this._timer = setTimeout(() => {
      this.endSession('timeout');
    }, SESSION_TIMEOUT_MS);
  };

  /**
   * Resets the session state.
   */
  private _reset = (): void => {
    if (this._timer) clearTimeout(this._timer);

    this._timer = undefined;
    this._sessionId = undefined;
    this._query = undefined;
    this._sessionStartAt = undefined;
    this._sessionEndAt = undefined;
    this._lastClickedAt = undefined;
    this._hasClickedUtterances = false;
    this._hasClickedContacts = false;
  };

  /**
   * Starts a new search session and resets the session state.
   * This is called when a user changes the search query or changes the Assistant toggle.
   *
   * @param {string} query Search query
   */
  startSession = (query: string): void => {
    this._reset();

    this._sessionStartAt = Date.now();
    this._startNewTimeout();
    this._sessionId = uuidv4();
    this._query = query;
  };

  /**
   * Ends the search session and triggers logging.
   *
   * @param {CompleteSessionReason} [reason]
   * (Optional) Reason for ending the session.
   * If this is not provided, the reason will default to the last set sessionEndReason, if any.
   */
  endSession = (reason?: CompleteSessionReason): void => {
    this._sessionEndAt = Date.now();
    if (this._timer) clearTimeout(this._timer);
    this._logSession(reason);
  };

  /**
   * Logs the search session.
   * If the session start or end time is missing, or the query is empty, all session data will be reset
   * and no logging will occur.
   *
   * @param {CompleteSessionReason} reason
   * (Optional) Reason for ending the session.
   */
  private _logSession = (reason?: CompleteSessionReason): void => {
    if (!this._sessionStartAt || !this._sessionEndAt || !this._query) {
      this._reset();
      return;
    }

    const sessionData = {
      session_id: this._sessionId,
      query: this._query,
      session_start_utc: new Date(this._sessionStartAt).toISOString(),
      session_end_utc: new Date(this._sessionEndAt).toISOString(),
      complete_session_reason: reason,
      messages_results: this._utteranceResults,
      contact_tokens: this._contactTokens,
      has_clicked_messages: this._hasClickedUtterances,
      has_clicked_contacts: this._hasClickedContacts,
      page_limit: DEFAULT_PAGE_SIZE,
      is_assistant_toggle_on:
        this._stores.searchV2.utterances.includeAutomatedUtterances,
    };

    if (!reason) {
      Logger.logWithSentry(
        'SearchLoggingStore: No reason provided for ending the search session',
        'warning',
        sessionData,
      );
    }

    this._stores.event.track('Complete Search Session', sessionData);

    this._reset();
  };

  /**
   * Utterance id results for the current search session, used for logging.
   */
  private get _utteranceResults(): number[] {
    return this._stores.searchV2.utterances.search.results.map(
      ({ utterance }) => utterance.id as number,
    );
  }

  /**
   * Contact token results for the current search session, used for logging.
   */
  private get _contactTokens(): string[] {
    return this._stores.searchV2.customers.results.map(({ token }) => token);
  }

  /**
   * Starts or extends the current search session, when the user interacts with the search pane.
   */
  private _startOrExtendSession = (): void => {
    if (!this.hasSessionStartTime) {
      // Session hasn't started so cannot rely on the internal this._query state
      this.startSession(this._stores.searchV2.query);
    } else {
      this._lastClickedAt = Date.now();
    }
  };

  /**
   * Tracks user interactions (clicks or scrolls) in the search pane.
   * User interactions will extend the session timeout.
   * If there is no active session, a new session will be started.
   * If a search result is clicked, the metadata will be logged.
   *
   * @param {ClickMetadata} [metadata]
   * (Optional) Metadata for the search result that was clicked.
   * If not provided, the interaction will only extend the session timeout.
   */
  logInteraction = (metadata?: ClickMetadata): void => {
    this._startOrExtendSession();
    if (!metadata) return;

    this._stores.event.track('Click Search Result', {
      session_id: this._sessionId,
      utterance_id:
        metadata.target === 'utterance' ? metadata.utteranceId : undefined,
      contact_token:
        metadata.target === 'contact' ? metadata.contactToken : undefined,
      rank: metadata.rank,
      medium:
        metadata.target === 'contact' && metadata.medium
          ? mediumToEventNameString[metadata.medium]
          : undefined,
    });

    if (metadata.target === 'utterance') {
      this._hasClickedUtterances = true;
    } else if (metadata.target === 'contact') {
      this._hasClickedContacts = true;
    }
  };

  /**
   * When a contact search result has multiple contact methods, this logs the
   * user's choice of contact method.
   *
   * @param {Contact['token']} [contactToken]
   * Contact token for the customer result.
   * @param {Medium} [medium]
   * The medium that the user selected for opening the transcript.
   * @param {string} [unitToken]
   * Unit token that the user wants to open the transcript for.
   */
  logContactMethodClick = (
    contactToken?: Contact['token'],
    medium?: Medium,
    unitToken?: string,
  ): void => {
    this._startOrExtendSession();

    this._stores.event.track('Choose Contact Create Message', {
      session_id: this._sessionId,
      contact_token: contactToken,
      medium: medium ? mediumToEventNameString[medium] : undefined,
      unit_token: unitToken,
    });
  };
}

export default SearchLoggingStore;
