import { makeAutoObservable } from 'mobx';
import type MessengerController from 'src/MessengerController';
import Api from 'src/api/Api';
import {
  MessagesMicrophonePermissionDeniedError,
  MessagesMissingMicrophoneError,
} from 'src/types/Errors';
import Logger from 'src/Logger';

export const MAX_RECORDING_DURATION_MILLISECONDS = 60000; // 60 seconds, defined by UX

/**
 * Find an audio mime type to use that is supported in the current browser. Current support is:
 * audio/webm - Chrome, Firefox, Edge
 * audio/mp4 - Safari
 */
const getSupportedMimeType = (): string => {
  if (MediaRecorder.isTypeSupported('audio/webm')) {
    return 'audio/webm';
  }
  if (MediaRecorder.isTypeSupported('audio/mp4')) {
    return 'audio/mp4';
  }

  const message =
    'SoundRecordingStore:getSupportedMimeType - No supported mime type found for this browser.';
  Logger.logWithSentry(message, 'error');
  throw new Error(message);
};

/**
 * Store that is responsible for recording audio from the user.
 */
class SoundRecordingStore {
  private _stores: MessengerController;
  private _api: Api;

  // Callback to be executed with the audio blob of the recording once available. Used to return audio blob in the stop method.
  private _resolveStopWithAudioBlob?: (blob: Blob | PromiseLike<Blob>) => void;

  private _stream?: MediaStream;

  private _recorder?: MediaRecorder;

  private _chunks: Blob[] = [];

  private _startTime = 0;

  private _currentTime = 0;

  private _interval?: NodeJS.Timer;

  isRecording = false;

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this._api = stores.api;
  }

  private _onDataAvailable = (event: BlobEvent): void => {
    this._chunks.push(event.data);
  };

  private _onStop = (): void => {
    const audioBlob = new Blob(this._chunks, { type: getSupportedMimeType() });

    this._resolveStopWithAudioBlob?.(audioBlob);

    this.clearRecorder();
  };

  clearRecorder = (): void => {
    this._recorder?.removeEventListener('dataavailable', this._onDataAvailable);
    this._recorder?.removeEventListener('stop', this._onStop);
    this._recorder = undefined;
    this._stream?.getTracks().forEach((track) => {
      track.stop();
    });
    this._stream = undefined;
    this.isRecording = false;
    if (this._interval) clearInterval(this._interval);
  };

  reset = (): void => {
    this.clearRecorder();
    this._startTime = 0;
    this._currentTime = 0;
    this._chunks = [];
  };

  start = async (): Promise<void> => {
    try {
      this._stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      this._recorder = new MediaRecorder(this._stream, {
        mimeType: getSupportedMimeType(),
      });
    } catch (error) {
      // Errors from https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
      if ((error as Error)?.name === 'NotAllowedError') {
        // User did not give us microphone permissions
        throw new MessagesMicrophonePermissionDeniedError(
          'Access to microphone denied',
        );
      }

      if ((error as Error)?.name === 'NotFoundError') {
        // No audio input found on the device
        throw new MessagesMissingMicrophoneError('No microphone found');
      }

      throw error;
    }

    this._recorder.addEventListener('dataavailable', this._onDataAvailable);
    this._recorder.addEventListener('stop', this._onStop);

    this._chunks = [];

    this._startTime = Date.now();
    this._currentTime = Date.now();
    this._interval = setInterval(() => {
      this._currentTime = Date.now();
      if (this.runTime > MAX_RECORDING_DURATION_MILLISECONDS) {
        this.stop();
      }
    }, 500);

    this._recorder?.start();

    this.isRecording = true;
  };

  // Resolves with a blob of the audio recorded.
  stop = (): Promise<Blob> => {
    this._recorder?.stop();

    // MediaRecorder does not immediately return the chunks but rather queues a few tasks before triggering a stop event
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/stop
    // Promise is used here so that we can collect the chunks once the stop event has occurred and then resolve
    return new Promise((resolve) => {
      this._resolveStopWithAudioBlob = resolve;
    });
  };

  // The running time of the audio being recorded, in milliseconds
  get runTime(): number {
    return this._currentTime - this._startTime;
  }
}

export default SoundRecordingStore;
