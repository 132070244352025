import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MessengerSettings } from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  MarketButton,
  MarketDivider,
  MarketList,
  MarketRow,
  MarketSelect,
} from 'src/components/Market';
import AppointmentIcon from 'src/svgs/AppointmentIcon';
import { getBookingChannelsUrl, getBuyerBookingFlowUrl } from 'src/utils/url';
import { SHADOW_REACT_ROOT_SELECTOR } from 'src/utils/shadowDomUtils';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import './MessagesPluginSettings.scss';
import { KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED } from 'src/stores/FeatureFlagStore';
import GlobeIcon from 'src/svgs/GlobeIcon';
import WebsiteSettingRow from 'src/components/WebsiteSettingRow/WebsiteSettingRow';

/**
 * Renders the UI for the Messages Plugin settings on the Settings Page.
 */
const MessagesPluginSettings = observer((): ReactElement => {
  const { user, featureFlag, settings, event, navigation } =
    useMessengerControllerContext();
  const { t } = useTranslation();

  const {
    pluginEnabledOnAppts,
    pluginResponseTime,
    userPluginEnabledOnAppts,
    userPluginResponseTime,
    setUserPluginEnabledOnAppts,
    setUserPluginResponseTime,
    hasABookingSite,
    sqOnlineSettings,
    setUserPluginEnabledOnSqOnlineSite,
    hasSqOnlineSettings,
    hasSqOnlinePermissions,
  } = settings;

  const hasSingleSqOnlineSite =
    hasSqOnlineSettings && sqOnlineSettings.length === 1;

  const onUserPluginEnabledOnApptsChanged = (enabled: boolean): void => {
    setUserPluginEnabledOnAppts(enabled);
    event.track('Click Text Us Toggle', {
      enabled,
      setting_source: 'messages_settings',
      surface: 'APPOINTMENTS_BOOKING_SITE',
    });
  };

  const onClickEnableOnlineBooking = (): void => {
    event.track('Click Enable Online Booking from Text Us');
  };

  return (
    <div>
      <h3 className="MessagesPluginSettings__title">
        {t('MessagesPluginSettings.title')}
      </h3>
      <p className="paragraph-30 MessagesPluginSettings__description">
        {t('MessagesPluginSettings.description')}
      </p>
      <img
        src="https://conversations-production-f.squarecdn.com/resources/inbox-settings-plugin.png"
        alt={t('MessagesPluginSettings.image_alt_text')}
        className="MessagesPluginSettings__image"
      />
      {hasABookingSite.boolValue ||
      featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED) ? (
        <>
          <h5 className="MessagesPluginSettings__surfaces-title">
            {t('MessagesPluginSettings.surfaces.title')}
          </h5>
          {hasABookingSite.boolValue &&
            pluginEnabledOnAppts.appliesToCurrentEmployee && (
              <WebsiteSettingRow
                selected={
                  userPluginEnabledOnAppts ?? pluginEnabledOnAppts.boolValue
                }
                onMarketRowSelected={() =>
                  onUserPluginEnabledOnApptsChanged(true)
                }
                onMarketRowDeselected={() =>
                  onUserPluginEnabledOnApptsChanged(false)
                }
                dataTestId="MessagesPluginSettings__appts-row"
                icon={<AppointmentIcon />}
                websiteLabel={t('MessagesPluginSettings.surfaces.appts_title')}
                description={t(
                  'MessagesPluginSettings.surfaces.appts_description',
                )}
                url={getBuyerBookingFlowUrl(
                  user.merchantToken,
                  featureFlag.useAppSubdomain,
                )}
              />
            )}
          {featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED) &&
            hasSqOnlineSettings &&
            hasSqOnlinePermissions && (
              <WebsiteSettingRow
                selected={
                  hasSingleSqOnlineSite &&
                  sqOnlineSettings[0].pluginEnabled?.boolValue
                }
                onMarketRowSelected={() => {
                  if (hasSingleSqOnlineSite) {
                    setUserPluginEnabledOnSqOnlineSite(
                      sqOnlineSettings[0],
                      true,
                    );
                  } else {
                    navigation.sheet.navigateTo('SQ_ONLINE_SETTINGS');
                  }
                }}
                onMarketRowDeselected={() => {
                  if (!hasSingleSqOnlineSite) return;
                  setUserPluginEnabledOnSqOnlineSite(
                    sqOnlineSettings[0],
                    false,
                  );
                }}
                icon={<GlobeIcon />}
                websiteLabel={
                  hasSingleSqOnlineSite
                    ? t(
                        'MessagesPluginSettings.surfaces.sq_online_title_single',
                      )
                    : t(
                        'MessagesPluginSettings.surfaces.sq_online_title_multiple',
                      )
                }
                description={t(
                  'MessagesPluginSettings.surfaces.sq_online_description',
                )}
                url={
                  hasSingleSqOnlineSite ? sqOnlineSettings[0].url : undefined
                }
                variant={hasSingleSqOnlineSite ? 'regular' : 'drill'}
                dataTestId="MessagesPluginSettings__sq-online-row"
              />
            )}
          <MarketDivider margin="small" size="thin" />
          <h5>{t('MessagesPluginSettings.customize_title')}</h5>
          <MarketSelect
            popoverContainer={SHADOW_REACT_ROOT_SELECTOR}
            value={`${
              userPluginResponseTime ??
              pluginResponseTime.pluginResponseTimeValue
            }`}
            onMarketSelectValueDidChange={(e) => {
              const newResponseTimeValue = Number.parseInt(
                e.detail.value as string,
                10,
              );
              setUserPluginResponseTime(newResponseTimeValue);
              event.track('Update Response Time', {
                response_time:
                  MessengerSettings.PluginResponseTime[newResponseTimeValue],
              });
            }}
            data-testid="MessagesPluginSettings__response-time-select"
          >
            <label>{t('MessagesPluginSettings.response_time.title')}</label>
            <MarketList slot="list">
              <MarketRow value={`${MessengerSettings.PluginResponseTime.ASAP}`}>
                <label slot="label">
                  {t('MessagesPluginSettings.response_time.asap')}
                </label>
              </MarketRow>
              <MarketRow
                value={`${MessengerSettings.PluginResponseTime.FEW_HOURS}`}
              >
                <label slot="label">
                  {t('MessagesPluginSettings.response_time.few_hours')}
                </label>
              </MarketRow>
              <MarketRow
                value={`${MessengerSettings.PluginResponseTime.WITHIN_DAY}`}
              >
                <label slot="label">
                  {t('MessagesPluginSettings.response_time.within_day')}
                </label>
              </MarketRow>
            </MarketList>
          </MarketSelect>
        </>
      ) : null}
      {!featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED) &&
        !hasABookingSite.boolValue && (
          <MarketButton
            rank="primary"
            className="MessagesPluginSettings__enable-online-booking-button"
            href={getBookingChannelsUrl(featureFlag.useAppSubdomain)}
            target="_blank"
            onClick={onClickEnableOnlineBooking}
            onKeyDown={(e) => onKeyDownEnter(e, onClickEnableOnlineBooking)}
          >
            {t('MessagesPluginSettings.enable_online_booking_button_label')}
          </MarketButton>
        )}
    </div>
  );
});

export default MessagesPluginSettings;
