import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MarketLink } from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import TooltipCloseIcon from 'src/svgs/TooltipCloseIcon';
import { MESSAGES_PLUGIN_OPT_OUT_FORM_URL } from 'src/utils/url';
import './MessagesPluginOnBuyerBookingFlowLaunchBanner.scss';
import { KEY_MESSAGES_PLUGIN_GA } from 'src/stores/FeatureFlagStore';
import Link from 'src/components/Link/Link';

/**
 * Banner to show the user when the Messages Plugin is launched on the Buyer Booking Flow.
 */
const MessagesPluginOnBuyerBookingFlowLaunchBanner = observer(
  (): ReactElement => {
    const { tooltip, event, featureFlag, navigation } =
      useMessengerControllerContext();
    const { t } = useTranslation();

    useEffect(() => {
      event.track('View Messages Plugin On BBF Launch Announcement');
    }, [event]);

    const onDismiss = (): void => {
      event.track('Click Messages Plugin On BBF Launch Announcement Dismiss');
      tooltip.dismiss('MESSAGES_PLUGIN_ON_BBF_LAUNCH_BANNER');
    };

    const onTurnOffTextUs = (): void => {
      event.track('Click Turn Off Text Us', {
        source: 'plugin-on-bbf-announcement',
      });
    };

    return (
      <div className="MessagesPluginOnBuyerBookingFlowLaunchBanner__container">
        <div className="MessagesPluginOnBuyerBookingFlowLaunchBanner__content">
          <div className="MessagesPluginOnBuyerBookingFlowLaunchBanner__title-row">
            <h3 className="MessagesPluginOnBuyerBookingFlowLaunchBanner__title">
              {t('MessagesPluginOnBuyerBookingFlowLaunchBanner.title')}
            </h3>
            <div
              className="MessagesPluginOnBuyerBookingFlowLaunchBanner__close"
              data-testid="MessagesPluginOnBuyerBookingFlowLaunchBanner__close"
              onClick={onDismiss}
              onKeyDown={(e) => onKeyDownEnter(e, onDismiss)}
              role="button"
              tabIndex={0}
            >
              <TooltipCloseIcon />
            </div>
          </div>
          <p className="MessagesPluginOnBuyerBookingFlowLaunchBanner__description">
            {t('MessagesPluginOnBuyerBookingFlowLaunchBanner.description')}
          </p>
          <img
            src="https://conversations-production-f.squarecdn.com/resources/messages-plus/text-us-plugin-on-bbf.png"
            alt={t(
              'MessagesPluginOnBuyerBookingFlowLaunchBanner.image_alt_text',
            )}
            className="MessagesPluginOnBuyerBookingFlowLaunchBanner__image"
          />
          <div className="MessagesPluginOnBuyerBookingFlowLaunchBanner__actions-row">
            {featureFlag.get(KEY_MESSAGES_PLUGIN_GA) ? (
              <Link
                onClick={() => {
                  onTurnOffTextUs();
                  tooltip.dismiss('MESSAGES_PLUGIN_ON_BBF_LAUNCH_BANNER');
                  navigation.openSheet('SETTINGS');
                }}
              >
                <span className="MessagesPluginOnBuyerBookingFlowLaunchBanner__text-button">
                  {t(
                    'MessagesPluginOnBuyerBookingFlowLaunchBanner.turn_off_label',
                  )}
                </span>
              </Link>
            ) : (
              <MarketLink
                href={MESSAGES_PLUGIN_OPT_OUT_FORM_URL}
                target="_blank"
                onClick={onTurnOffTextUs}
                onKeyDown={(e) => onKeyDownEnter(e, onTurnOffTextUs)}
                data-testid="MessagesPluginOnBuyerBookingFlowLaunchBanner__text-button-link"
              >
                <span className="MessagesPluginOnBuyerBookingFlowLaunchBanner__text-button">
                  {t(
                    'MessagesPluginOnBuyerBookingFlowLaunchBanner.turn_off_label',
                  )}
                </span>
              </MarketLink>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default MessagesPluginOnBuyerBookingFlowLaunchBanner;
