import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './LinkPluginPage.scss';
import { MessengerModalFullContent } from 'src/components/MessengerModalFull';
import {
  MarketHeader,
  MarketList,
  MarketRow,
  MarketAccessory,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import LocationPinIcon from 'src/svgs/LocationPinIcon';
import { LinkPluginPage as LinkPluginPageType } from 'src/MessengerTypes';
import { useTranslation } from 'react-i18next';
import StatusBanner from 'src/components/StatusBanner/StatusBanner';

/**
 * This component (rendered as a sheet at /link-plugin) enables the user to select among
 * a list of active units to link to a Wordpress site via the Messages WP Plugin.
 *
 * @example
 * <LinkPluginPage />
 * @author teresalin
 */
const LinkPluginPage = observer((): ReactElement => {
  const { user, navigation, status } = useMessengerControllerContext();
  const { t } = useTranslation();

  const page = navigation.sheet.currentPage as LinkPluginPageType;

  let areParamsIncomplete = !page.url || !page.websiteName;

  // Strip protocol and query params from url
  // i.e. http://www.wordpress.com/abc?unit=123 -> www.wordpress.com/abc
  let urlHostAndPath;
  try {
    const urlObj = page.url ? new URL(page.url) : undefined;
    urlHostAndPath = urlObj
      ? `${urlObj.hostname}${urlObj.pathname === '/' ? '' : urlObj.pathname}`
      : undefined;
  } catch {
    // Url value is malformed
    areParamsIncomplete = true;
  }

  const title =
    page.websiteName && urlHostAndPath
      ? t('LinkPluginPage.title_with_website_name', {
          websiteName: page.websiteName,
          url: urlHostAndPath,
        })
      : t('LinkPluginPage.default_title');

  return (
    <>
      <MarketHeader disableCloseButton></MarketHeader>
      <MessengerModalFullContent status={status.value}>
        <>
          <div className="LinkPluginPage__content__header">{title}</div>
          {areParamsIncomplete ? (
            <StatusBanner
              label={t('LinkPluginPage.error_message_insufficient_params')}
              type="ERROR"
            />
          ) : (
            <MarketList interactive>
              {user.activeUnits.map((unit) => (
                <MarketRow
                  key={unit.token}
                  value={unit.token}
                  onClick={() => {
                    if (window.opener?.postMessage) {
                      window.opener.postMessage(unit.token, page.url);
                    } else {
                      status.setError({
                        display: 'BANNER',
                        type: 'ERROR',
                        scope: 'SHEET',
                        label: t(
                          'LinkPluginPage.error_message_no_window_opener',
                        ),
                      });
                    }
                  }}
                >
                  <MarketAccessory
                    slot="leading-accessory"
                    size="image"
                    className="LinkPluginPage__location-pin"
                  >
                    <LocationPinIcon />
                  </MarketAccessory>
                  <label slot="label">{unit.name}</label>
                  <p slot="subtext">{unit.address}</p>
                </MarketRow>
              ))}
            </MarketList>
          )}
        </>
      </MessengerModalFullContent>
    </>
  );
});

export default LinkPluginPage;
