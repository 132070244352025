/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/token';
import './tender';
import '../common/currency';
import './creator_details';
import './client_details';
import './types';
import '../common/fee';
import './cash_drawer_details';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "Refund": {
            "fields": {
              "token": {
                "type": "squareup.common.token.TokenPair",
                "id": 1,
                "rule": "optional"
              },
              "state": {
                "type": "squareup.bills.Refund.State",
                "id": 2,
                "rule": "optional"
              },
              "linkedBillServerToken": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "linkedBillOrderReferenceId": {
                "type": "string",
                "id": 24,
                "rule": "optional"
              },
              "linkedTenderServerToken": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              },
              "readOnlyLinkedTenderType": {
                "type": "squareup.bills.Tender.Type",
                "id": 14,
                "rule": "optional"
              },
              "amount": {
                "type": "squareup.common.Money",
                "id": 5,
                "rule": "optional"
              },
              "creatorDetails": {
                "type": "squareup.bills.CreatorDetails",
                "id": 6,
                "rule": "optional"
              },
              "reasonOption": {
                "type": "squareup.bills.Refund.ReasonOption",
                "id": 21,
                "rule": "optional",
                "options": {
                  "default": "UNKNOWN_REASON"
                }
              },
              "reason": {
                "type": "string",
                "id": 7,
                "rule": "optional"
              },
              "checkoutRefundId": {
                "type": "string",
                "id": 22,
                "rule": "optional"
              },
              "clientDetails": {
                "type": "squareup.bills.ClientDetails",
                "id": 8,
                "rule": "optional"
              },
              "sendEmail": {
                "type": "bool",
                "id": 9,
                "rule": "optional"
              },
              "skippedValidations": {
                "type": "squareup.bills.Refund.SkippedValidation",
                "id": 10,
                "rule": "repeated"
              },
              "failureReason": {
                "type": "squareup.bills.Refund.FailureReason",
                "id": 11,
                "rule": "optional"
              },
              "requestedAt": {
                "type": "squareup.bills.DatePair",
                "id": 12,
                "rule": "optional"
              },
              "riskReview": {
                "type": "squareup.bills.RiskReview",
                "id": 13,
                "rule": "optional"
              },
              "tenderReservation": {
                "type": "squareup.bills.TenderReservation",
                "id": 15,
                "rule": "optional"
              },
              "refundedReportingAmounts": {
                "type": "squareup.bills.Tender.Amounts.ReportingAmounts",
                "id": 16,
                "rule": "optional"
              },
              "readOnlyRefundedAt": {
                "type": "squareup.bills.ISO8601Date",
                "id": 17,
                "rule": "optional"
              },
              "reportingDetails": {
                "type": "squareup.bills.Refund.ReportingDetails",
                "id": 18,
                "rule": "optional"
              },
              "readOnlyDestination": {
                "type": "squareup.bills.Refund.Destination",
                "id": 19,
                "rule": "optional"
              },
              "refundType": {
                "type": "squareup.bills.Refund.RefundType",
                "id": 23,
                "rule": "optional",
                "options": {
                  "default": "LINKED"
                }
              },
              "feeRefundPolicy": {
                "type": "squareup.common.RefundPolicy",
                "id": 25,
                "rule": "optional"
              },
              "cashDrawerDetails": {
                "type": "squareup.bills.CashDrawerDetails",
                "id": 26,
                "rule": "optional"
              }
            },
            "nested": {
              "State": {
                "values": {
                  "UNKNOWN": 0,
                  "NEW": 1,
                  "PENDING_AUTHORIZATION": 2,
                  "PENDING": 3,
                  "CANCELED": 4,
                  "SUCCESS": 5,
                  "FAILED": 6
                }
              },
              "ReasonOption": {
                "values": {
                  "UNKNOWN_REASON": 0,
                  "RETURNED_GOODS": 1,
                  "ACCIDENTAL_CHARGE": 2,
                  "CANCELED_ORDER": 3,
                  "OTHER_REASON": 4,
                  "FRAUDULENT_CHARGE": 5
                }
              },
              "SkippedValidation": {
                "values": {
                  "DO_NOT_USE": 0,
                  "RISK_EXPOSURE": 1,
                  "BLOCKED_RISK_RESERVATIONS": 2
                }
              },
              "FailureReason": {
                "values": {
                  "FR_DO_NOT_USE": 0,
                  "UNAUTHORIZED": 1,
                  "BILL_INCOMPLETE": 2,
                  "TENDER_NOT_FOUND": 3,
                  "TENDER_INCOMPLETE": 4,
                  "CURRENCY_CODE_MISMATCH": 5,
                  "INSUFFICIENT_AMOUNT_REMAINING": 6,
                  "RISK_DENIED": 7,
                  "MOBILE_STAFF_UNAUTHORIZED": 8,
                  "EMPLOYEE_UNAUTHORIZED": 9,
                  "CARD_PRESENCE_REQUIRED": 10,
                  "CARD_MISMATCH": 11,
                  "CARD_RESERVATION_DECLINED": 12,
                  "TENDER_NO_LONGER_ELIGIBLE": 13,
                  "INSUFFICIENT_MERCHANT_BALANCE": 14,
                  "MULTIPLE_TENDERS_AWAITING_TIP": 15,
                  "MERCHANT_UNAUTHORIZED": 16,
                  "COMPLIANCE_DENIED": 17,
                  "GENERIC_FAILURE": 18,
                  "ZERO_AMOUNT_REFUND_ON_NON_ZERO_AMOUNT_TENDER": 19,
                  "AMOUNT_TOO_HIGH": 20,
                  "NOT_ALLOWED_BY_TENDER": 21,
                  "DISPUTED_TENDER": 22,
                  "UNLINKED_REFUND_NO_TWO_FACTOR_AUTH": 23
                }
              },
              "SettlementDetails": {
                "fields": {
                  "settlementToken": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "settlementCreatedAt": {
                    "type": "squareup.bills.ISO8601Date",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              },
              "ReportingDetails": {
                "fields": {
                  "linkedTender": {
                    "type": "squareup.bills.Tender",
                    "id": 1,
                    "rule": "optional"
                  },
                  "readOnlySettlementDetails": {
                    "type": "squareup.bills.Refund.SettlementDetails",
                    "id": 2,
                    "rule": "optional"
                  },
                  "linkedCreatorDetails": {
                    "type": "squareup.bills.CreatorDetails",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              },
              "Destination": {
                "fields": {
                  "giftCardDestination": {
                    "type": "squareup.bills.Refund.Destination.GiftCardDestination",
                    "id": 1
                  },
                  "tenderDestination": {
                    "type": "squareup.bills.Tender",
                    "id": 2
                  }
                },
                "nested": {
                  "GiftCardDestination": {
                    "fields": {
                      "giftCardToken": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "giftCardPanSuffix": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      },
                      "type": {
                        "type": "squareup.bills.Refund.Destination.GiftCardDestination.Type",
                        "id": 4,
                        "rule": "optional",
                        "options": {
                          "default": "PHYSICAL"
                        }
                      },
                      "giftCardBalance": {
                        "type": "squareup.common.Money",
                        "id": 5,
                        "rule": "optional"
                      }
                    },
                    "nested": {
                      "Type": {
                        "values": {
                          "TYPE_UNKNOWN": 0,
                          "PHYSICAL": 1,
                          "DIGITAL": 2
                        }
                      }
                    }
                  },
                  "details": {
                    "oneof": [
                      "giftCardDestination",
                      "tenderDestination"
                    ]
                  }
                }
              },
              "RefundType": {
                "values": {
                  "REFUND_TYPE_DO_NOT_USE": 0,
                  "LINKED": 1,
                  "UNLINKED": 2
                }
              }
            }
          },
          "RiskReview": {
            "fields": {
              "state": {
                "type": "squareup.bills.RiskReview.State",
                "id": 1,
                "rule": "optional"
              },
              "comment": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              },
              "failureReason": {
                "type": "squareup.bills.Refund.FailureReason",
                "id": 5,
                "rule": "optional"
              },
              "reviewSystem": {
                "type": "squareup.bills.RiskReview.ReviewSystem",
                "id": 3
              },
              "reviewerToken": {
                "type": "string",
                "id": 4
              }
            },
            "nested": {
              "State": {
                "values": {
                  "UNKNOWN": 0,
                  "PENDING": 1,
                  "APPROVED": 2,
                  "REJECTED": 3
                }
              },
              "ReviewSystem": {
                "values": {
                  "RS_UNKNOWN": 0,
                  "REFUNDS_ARBITER": 1
                }
              },
              "reviewer": {
                "oneof": [
                  "reviewSystem",
                  "reviewerToken"
                ]
              }
            }
          },
          "TenderReservation": {
            "fields": {
              "state": {
                "type": "squareup.bills.TenderReservation.State",
                "id": 1,
                "rule": "optional"
              },
              "failureReason": {
                "type": "squareup.bills.Refund.FailureReason",
                "id": 2,
                "rule": "optional"
              }
            },
            "nested": {
              "State": {
                "values": {
                  "TR_STATE_UNKNOWN": 0,
                  "APPROVED": 1,
                  "REJECTED": 2
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.Refund && !$root.nested?.squareup?.nested?.bills?.nested?.Refund?.nested?.State && !$root.nested?.squareup?.nested?.bills?.nested?.Refund?.nested?.ReasonOption && !$root.nested?.squareup?.nested?.bills?.nested?.Refund?.nested?.SkippedValidation && !$root.nested?.squareup?.nested?.bills?.nested?.Refund?.nested?.FailureReason && !$root.nested?.squareup?.nested?.bills?.nested?.Refund?.nested?.SettlementDetails && !$root.nested?.squareup?.nested?.bills?.nested?.Refund?.nested?.ReportingDetails && !$root.nested?.squareup?.nested?.bills?.nested?.Refund?.nested?.Destination && !$root.nested?.squareup?.nested?.bills?.nested?.Refund?.nested?.Destination?.nested?.GiftCardDestination && !$root.nested?.squareup?.nested?.bills?.nested?.Refund?.nested?.Destination?.nested?.GiftCardDestination?.nested?.Type && !$root.nested?.squareup?.nested?.bills?.nested?.Refund?.nested?.RefundType && !$root.nested?.squareup?.nested?.bills?.nested?.RiskReview && !$root.nested?.squareup?.nested?.bills?.nested?.RiskReview?.nested?.State && !$root.nested?.squareup?.nested?.bills?.nested?.RiskReview?.nested?.ReviewSystem && !$root.nested?.squareup?.nested?.bills?.nested?.TenderReservation && !$root.nested?.squareup?.nested?.bills?.nested?.TenderReservation?.nested?.State) {
	$root.addJSON(jsonData);
}

/**
 * To be consistent with all other APIs, the same entity is used to request creation.
 * In a creation request, only the client token is populated.
 *
 * Whenever this proto is updated, considering updating the Conduit ETL so that the new
 * field appears in Vertica. The ETL can be found:
 * https://git.sqcorp.co/projects/HAD/repos/conduit-pipelines/browse/pipelines/feeds/bill_event/schema/bill-event-bill-refund.vsql
 *
 * SOURCE Refund @ squareup/bills/refund_v3.proto at 27:1
 */
export const Refund = $root.lookupType('squareup.bills.Refund');
fixType(Refund, {
  token: { required: true },
  state: { required: true },
  amount: { required: true },
  reason: { notEmpty: true },
  sendEmail: { required: true },
  requestedAt: { required: true },
});
Builder.createAndAttachToType(Refund);
/**
 * SOURCE State @ squareup/bills/refund_v3.proto at 35:3
 */
Refund.State = $root.lookupEnum('squareup.bills.Refund.State').values;
/**
 * SOURCE ReasonOption @ squareup/bills/refund_v3.proto at 77:3
 */
Refund.ReasonOption = $root.lookupEnum('squareup.bills.Refund.ReasonOption').values;
/**
 * SOURCE SkippedValidation @ squareup/bills/refund_v3.proto at 115:3
 */
Refund.SkippedValidation = $root.lookupEnum('squareup.bills.Refund.SkippedValidation').values;
/**
 * SOURCE FailureReason @ squareup/bills/refund_v3.proto at 125:3
 */
Refund.FailureReason = $root.lookupEnum('squareup.bills.Refund.FailureReason').values;
/**
 * SOURCE SettlementDetails @ squareup/bills/refund_v3.proto at 205:3
 */
Refund.SettlementDetails = $root.lookupType('squareup.bills.Refund.SettlementDetails');
fixType(Refund.SettlementDetails);
Builder.createAndAttachToType(Refund.SettlementDetails);
/**
 * SOURCE ReportingDetails @ squareup/bills/refund_v3.proto at 212:3
 */
Refund.ReportingDetails = $root.lookupType('squareup.bills.Refund.ReportingDetails');
fixType(Refund.ReportingDetails);
Builder.createAndAttachToType(Refund.ReportingDetails);
/**
 * Identifies the destination for the refunded value, if different from the
 * linked (i.e. source) tender.
 *
 * SOURCE Destination @ squareup/bills/refund_v3.proto at 232:3
 */
Refund.Destination = $root.lookupType('squareup.bills.Refund.Destination');
fixType(Refund.Destination);
Builder.createAndAttachToType(Refund.Destination);
/**
 * Identifies a gift card onto which value will be refunded. This message's fields will always
 * be populated for successful refunds.
 *
 * SOURCE GiftCardDestination @ squareup/bills/refund_v3.proto at 237:5
 */
Refund.Destination.GiftCardDestination = $root.lookupType('squareup.bills.Refund.Destination.GiftCardDestination');
fixType(Refund.Destination.GiftCardDestination);
Builder.createAndAttachToType(Refund.Destination.GiftCardDestination);
/**
 * SOURCE Type @ squareup/bills/refund_v3.proto at 243:7
 */
Refund.Destination.GiftCardDestination.Type = $root.lookupEnum('squareup.bills.Refund.Destination.GiftCardDestination.Type').values;
/**
 * SOURCE RefundType @ squareup/bills/refund_v3.proto at 273:3
 */
Refund.RefundType = $root.lookupEnum('squareup.bills.Refund.RefundType').values;
/**
 * Contains risk review reservation details for a given refund.
 *
 * SOURCE RiskReview @ squareup/bills/refund_v3.proto at 303:1
 */
export const RiskReview = $root.lookupType('squareup.bills.RiskReview');
fixType(RiskReview, {
  state: { required: true },
});
Builder.createAndAttachToType(RiskReview);
/**
 * SOURCE State @ squareup/bills/refund_v3.proto at 304:3
 */
RiskReview.State = $root.lookupEnum('squareup.bills.RiskReview.State').values;
/**
 * SOURCE ReviewSystem @ squareup/bills/refund_v3.proto at 320:3
 */
RiskReview.ReviewSystem = $root.lookupEnum('squareup.bills.RiskReview.ReviewSystem').values;
/**
 * Contains details on the tender-specific reservation flow.
 *
 * SOURCE TenderReservation @ squareup/bills/refund_v3.proto at 339:1
 */
export const TenderReservation = $root.lookupType('squareup.bills.TenderReservation');
fixType(TenderReservation, {
  state: { required: true },
});
Builder.createAndAttachToType(TenderReservation);
/**
 * SOURCE State @ squareup/bills/refund_v3.proto at 340:3
 */
TenderReservation.State = $root.lookupEnum('squareup.bills.TenderReservation.State').values;
