import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';
import { MarketAccessory, MarketButton } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import EmptyState from 'src/components/EmptyState/EmptyState';
import SearchGraphicIcon from 'src/svgs/SearchGraphicIcon';
import AlertIcon from 'src/svgs/AlertIcon';
import CustomerSearchResultList from 'src/pages/SearchPage/components/CustomerSearchResultList/CustomerSearchResultList';
import MessengerList from 'src/components/MessengerList/MessengerList';
import MessengerListItem from 'src/components/MessengerListItem/MessengerListItem';
import CustomerImage from 'src/components/CustomerImage/CustomerImage';
import { HighlightSegment } from 'src/MessengerTypes';
import { isFailedSendStatus } from 'src/utils/transcriptUtils';
import { getSearchResultPreviewText } from 'src/pages/SearchPage/utils';
import './SearchPageContent.scss';

/**
 * Renders the main content for the search page.
 */
const SearchPageContent = observer((): ReactElement => {
  const { navigation, searchV2, searchLogger } =
    useMessengerControllerContext();
  const { t } = useTranslation();

  if (searchV2.query === '') {
    return (
      <EmptyState
        icon={<SearchGraphicIcon />}
        title={t('SearchPage.title')}
        description={t('SearchPage.description')}
      />
    );
  }

  if (searchV2.status === 'ERROR') {
    return (
      <EmptyState
        icon={<AlertIcon />}
        title={t('SearchPage.error_title')}
        button={
          <MarketButton onClick={searchV2.retry}>
            {t('common.retry')}
          </MarketButton>
        }
      />
    );
  }

  if (!searchV2.hasResults) {
    return (
      <EmptyState
        icon={<SearchGraphicIcon />}
        title={t('SearchPage.no_results_title', {
          searchQuery: searchV2.query,
        })}
      />
    );
  }

  return (
    <div className="SearchPageContent__container">
      <MessengerList
        loadNextPage={searchV2.utterances.search.next}
        loadMoreStatus={searchV2.utterances.search.loadNextStatus}
        hasNextPage={searchV2.utterances.search.hasNextPage}
        content={
          <div className="SearchPageContent__top-content-container">
            <CustomerSearchResultList />
            {searchV2.utterances.search.results.length > 0 && (
              <h3 className="SearchPageContent__utterances-title">
                {t('common.messenger')}
              </h3>
            )}
          </div>
        }
        initialScrollPosition={0}
      >
        {searchV2.utterances.search.results.map((result, index) => {
          const customerName: string =
            result.displayName?.name || t('TranscriptsListItem.unknown_user');
          const id: number = result.utterance.id as number;
          const highlightSegments: HighlightSegment[] = (
            result.highlightSegments || []
          ).map((segment) => [
            segment.startOffset as number,
            segment.endOffset as number,
          ]);
          return (
            <MessengerListItem
              key={id}
              id={id}
              onClick={() => {
                searchLogger.logInteraction({
                  target: 'utterance',
                  utteranceId: id,
                  rank: index + 1,
                });
                navigation.openTranscript(
                  result.transcriptId,
                  'utterance_click_from_search',
                  id,
                );
              }}
              accessory={
                <MarketAccessory slot="leading-accessory" size="image">
                  <CustomerImage
                    customerInitials={result.displayName?.initials}
                    imageUrl={result.displayName?.profileImageUrl}
                    customerName={customerName}
                  />
                </MarketAccessory>
              }
              time={result.utterance.spokenAtMillis || 0}
              title={customerName}
              description={getSearchResultPreviewText(
                result.utterance,
                highlightSegments,
              )}
              isUnread={!result.isRead}
              isUrgent={isFailedSendStatus(result.utterance.sendStatus)}
              isAssistant={
                result.utterance.speakerType ===
                  Utterance.SpeakerType.SERVICE ||
                result.utterance.speakerType === Utterance.SpeakerType.BOT
              }
            />
          );
        })}
        {!searchV2.utterances.search.hasNextPage && (
          <p className="SearchPageContent__end-of-results">
            {t('SearchPage.end_of_results_text')}
          </p>
        )}
      </MessengerList>
    </div>
  );
});

export default SearchPageContent;
