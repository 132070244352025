import { action, makeAutoObservable } from 'mobx';
import { Howl } from 'howler';
import type MessengerController from 'src/MessengerController';
import { LoadingStatus } from 'src/MessengerTypes';

/**
 * Store containing state for an individual sound.
 */
class Sound {
  private _stores: MessengerController;
  private _howl: Howl;
  // Interval used to keep observable position state up to date with the howler js sound file
  private _interval: NodeJS.Timeout | null = null;

  id?: number;

  url: string;

  mimeType?: string;

  token?: string;

  status: LoadingStatus = 'LOADING';

  isPlaying = false;

  duration = 0;

  position = 0;

  constructor(
    stores: MessengerController,
    sound: { id?: number; url: string; mimeType?: string; token?: string },
  ) {
    makeAutoObservable(this);

    this._stores = stores;

    this.id = sound.id;
    this.url = sound.url;
    this.mimeType = sound.mimeType;
    this.token = sound.token;

    this._howl = new Howl({
      src: [`${this.url}${this.token ? `?token=${this.token}` : ''}`],
      html5: true,
      preload: true,
      onend: action(() => {
        this._handleSoundEnd();
        this.seek(0);
      }),
      onstop: this._handleSoundEnd,
      onload: action(() => {
        this.duration = this._howl.duration();
        this.status = 'SUCCESS';
      }),
      onloaderror: action(() => {
        this.status = 'ERROR';
      }),
    });
  }

  play = (): void => {
    this.isPlaying = true;
    this._setSeekInterval();
    this._howl.play();
  };

  pause = (): void => {
    this._handleSoundEnd();
    this._howl.pause();
  };

  stop = (): void => {
    this.pause();
    this.seek(0);
  };

  _handleSoundEnd = (): void => {
    this.isPlaying = false;
    this._clearSeekInterval();
  };

  seek = (position: number): void => {
    // Clear interval and resume after seeking to avoid brief intermediate state where this.position !== this._howl.seek()
    const isPlaying = this.isPlaying;
    if (isPlaying) {
      this._clearSeekInterval();
    }

    this.position = position;
    this._howl.seek(position);

    if (isPlaying) {
      this._setSeekInterval();
    }
  };

  private _clearSeekInterval = (): void => {
    if (this._interval !== null) {
      clearInterval(this._interval);
    }
  };

  private _setSeekInterval = (): void => {
    this._interval = setInterval(() => {
      this.position = this._howl.seek();
    }, 100);
  };
}

export default Sound;
