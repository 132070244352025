import { makeAutoObservable } from 'mobx';
import type MessengerController from 'src/MessengerController';
import Api from 'src/api/Api';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { getPrimaryContactMethod } from 'src/utils/transcriptUtils';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

/**
 * Store containing all the cached customer metadata across the life of the application.
 */
class CustomersStore {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * The mapping of customer tokens to customer information that describes the customer.
   */
  _customerDetails = new Map<string, Contact>();

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this._api = stores.api;
  }

  loadCustomers = async (customerTokens: string[]): Promise<void> => {
    const newCustomerTokens = customerTokens.filter(
      (token) => !this._customerDetails.has(token),
    );

    if (newCustomerTokens.length === 0) {
      // If there are no new customer tokens, return early to prevent an unnecessary API call
      return;
    }

    const customers = await this._api.contacts.getContacts(newCustomerTokens);

    customers.forEach((customer) =>
      this._customerDetails.set(customer.token, customer),
    );
  };

  has(customerToken: string): boolean {
    return this._customerDetails.has(customerToken);
  }

  get(customerToken: string): Contact | undefined {
    return this._customerDetails.get(customerToken);
  }

  /**
   * Get all the primary contact methods of a specific medium based on a list of customer tokens.
   *
   * @param {string[]} customerTokens
   * @param {Medium} medium
   */
  getAllContactMethods(
    customerTokens: string[],
    medium: Medium,
  ): Contact.ContactMethod[] {
    const contactMethods: Contact.ContactMethod[] = [];

    // Get all primary email contacts
    customerTokens.forEach((customerToken) => {
      const customer = this.get(customerToken);
      const contactMethod =
        customer && getPrimaryContactMethod(customer, medium);
      if (contactMethod) contactMethods.push(contactMethod);
    });

    // Deduplicate email contacts
    return contactMethods.filter(
      (contact, index) =>
        index ===
        contactMethods.findIndex(
          (otherContact) =>
            otherContact.contactHandle === contact.contactHandle,
        ),
    );
  }
}

export default CustomersStore;
