/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "common": {
                "nested": {
                  "ReleaseStatus": {
                    "values": {
                      "PUBLIC": 1,
                      "BETA": 2,
                      "UPCOMING": 3,
                      "INTERNAL": 4,
                      "EXCLUDED": 5,
                      "ALPHA": 6,
                      "DEPRECATED": 7,
                      "RETIRED": 8
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.ReleaseStatus) {
	$root.addJSON(jsonData);
}

/**
 * Release status with PUBLIC as default. These alter what is added to documentation
 * in the doc build pipeline, you can read more in the wiki on docs architecture:
 * https://wiki.sqcorp.co/display/REG/Developer+Documentation, and find where
 * each of these are deployed to (see the connectv2-docs project).
 *
 * A standard release process for a big feature release
 * (e.g. exposing a new endpoint.) should be ALPHA -> BETA -> PUBLIC. By ending life
 * of a feature, the status goes from BETA or PUBLIC to DEPRECATED.
 * See go/plathome for more details.
 *
 * SOURCE ReleaseStatus @ squareup/connect/v2/common/options.proto at 33:1
 */
export const ReleaseStatus = $root.lookupEnum('squareup.connect.v2.common.ReleaseStatus').values;
