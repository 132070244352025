/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "rolodex": {
        "nested": {
          "ListContactsSortType": {
            "values": {
              "DISPLAY_NAME_ASCENDING": 1,
              "CREATED_AT_DESCENDING": 2,
              "LAST_VISITED_AT_DESCENDING": 3,
              "SPEND_TOTAL_DESCENDING": 4,
              "SPEND_AVERAGE_DESCENDING": 5,
              "COUNT_TRANSACTIONS_DESCENDING": 6,
              "DISPLAY_NAME_DESCENDING": 7,
              "CREATED_AT_ASCENDING": 8,
              "LAST_VISITED_AT_ASCENDING": 9,
              "SPEND_TOTAL_ASCENDING": 10,
              "SPEND_AVERAGE_ASCENDING": 11,
              "COUNT_TRANSACTIONS_ASCENDING": 12,
              "FIRST_VISITED_AT_ASCENDING": 13,
              "FIRST_VISITED_AT_DESCENDING": 14,
              "RELEVANCE_SCORE_ASCENDING": 15,
              "RELEVANCE_SCORE_DESCENDING": 16
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.rolodex?.nested?.ListContactsSortType) {
	$root.addJSON(jsonData);
}

/**
 * ****************************************************************************
 * Contacts
 * ****************************************************************************
 *
 * SOURCE ListContactsSortType @ squareup/rolodex/external.proto at 41:1
 */
export const ListContactsSortType = $root.lookupEnum('squareup.rolodex.ListContactsSortType').values;
