import { createReactComponent } from '@market/react';
import type { JSX } from '@market/react';

export const MESSAGES_MARKET_COMPONENT_PREFIX = 'msg';

/**
 * The purpose of this file is to create a namespaced version of each market component
 * that Messages uses. This will prevent conflicts between different market versions
 * that are used the host apps Messages integrates with.
 *
 * Any new Market components that are used in Messages should be created here and imported
 * from this file, instead of the react-bindings directly.
 */
export const MarketAccessory = createReactComponent<
  JSX.MarketAccessory,
  HTMLMarketAccessoryElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-accessory`);
export const MarketActivityIndicator = createReactComponent<
  JSX.MarketActivityIndicator,
  HTMLMarketActivityIndicatorElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-activity-indicator`);
export const MarketBanner = createReactComponent<
  JSX.MarketBanner,
  HTMLMarketBannerElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-banner`);
export const MarketButton = createReactComponent<
  JSX.MarketButton,
  HTMLMarketButtonElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-button`);
export const MarketButtonDropdown = createReactComponent<
  JSX.MarketButtonDropdown,
  HTMLMarketButtonDropdownElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-button-dropdown`);
export const MarketButtonGroup = createReactComponent<
  JSX.MarketButtonGroup,
  HTMLMarketButtonGroupElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-button-group`);
export const MarketCheckbox = createReactComponent<
  JSX.MarketCheckbox,
  HTMLMarketCheckboxElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-checkbox`);
export const MarketContentCard = createReactComponent<
  JSX.MarketContentCard,
  HTMLMarketContentCardElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-content-card`);
export const MarketContext = createReactComponent<
  JSX.MarketContext,
  HTMLMarketContextElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-context`);
export const MarketDialog = createReactComponent<
  JSX.MarketDialog,
  HTMLMarketDialogElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-dialog`);
export const MarketEmptyState = createReactComponent<
  JSX.MarketEmptyState,
  HTMLMarketEmptyStateElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-empty-state`);
export const MarketField = createReactComponent<
  JSX.MarketField,
  HTMLMarketFieldElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-field`);
export const MarketFooter = createReactComponent<
  JSX.MarketFooter,
  HTMLMarketFooterElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-footer`);
export const MarketHeader = createReactComponent<
  JSX.MarketHeader,
  HTMLMarketHeaderElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-header`);
export const MarketInputText = createReactComponent<
  JSX.MarketInputText,
  HTMLMarketInputTextElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-input-text`);
export const MarketLink = createReactComponent<
  JSX.MarketLink,
  HTMLMarketLinkElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-link`);
export const MarketList = createReactComponent<
  JSX.MarketList,
  HTMLMarketListElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-list`);
export const MarketModalFull = createReactComponent<
  JSX.MarketModalFull,
  HTMLMarketModalFullElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-modal-full`);
export const MarketModalPartial = createReactComponent<
  JSX.MarketModalPartial,
  HTMLMarketModalPartialElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-modal-partial`);
export const MarketPill = createReactComponent<
  JSX.MarketPill,
  HTMLMarketPillElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-pill`);
export const MarketRadio = createReactComponent<
  JSX.MarketRadio,
  HTMLMarketRadioElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-radio`);
export const MarketRow = createReactComponent<
  JSX.MarketRow,
  HTMLMarketRowElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-row`);
export const MarketSelect = createReactComponent<
  JSX.MarketSelect,
  HTMLMarketSelectElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-select`);
export const MarketTab = createReactComponent<
  JSX.MarketTab,
  HTMLMarketTabElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-tab`);
export const MarketTabs = createReactComponent<
  JSX.MarketTabs,
  HTMLMarketTabsElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-tabs`);
export const MarketTabList = createReactComponent<
  JSX.MarketTabList,
  HTMLMarketTabListElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-tab-list`);
export const MarketTextarea = createReactComponent<
  JSX.MarketTextarea,
  HTMLMarketTextareaElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-textarea`);
export const MarketToast = createReactComponent<
  JSX.MarketToast,
  HTMLMarketToastElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-toast`);
export const MarketToaster = createReactComponent<
  JSX.MarketToaster,
  HTMLMarketToasterElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-toaster`);
export const MarketTooltip = createReactComponent<
  JSX.MarketTooltip,
  HTMLMarketTooltipElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-tooltip`);
export const MarketToggle = createReactComponent<
  JSX.MarketToggle,
  HTMLMarketToggleElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-toggle`);
export const MarketPopover = createReactComponent<
  JSX.MarketPopover,
  HTMLMarketPopoverElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-popover`);
export const MarketBlade = createReactComponent<
  JSX.MarketBlade,
  HTMLMarketBladeElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-blade`);
export const MarketDivider = createReactComponent<
  JSX.MarketDivider,
  HTMLMarketDividerElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-divider`);
export const MarketTable = createReactComponent<
  JSX.MarketTable,
  HTMLMarketTableElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-table`);
export const MarketTableRow = createReactComponent<
  JSX.MarketTableRow,
  HTMLMarketTableRowElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-table-row`);
export const MarketTableColumn = createReactComponent<
  JSX.MarketTableColumn,
  HTMLMarketTableColumnElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-table-column`);
export const MarketTableCell = createReactComponent<
  JSX.MarketTableCell,
  HTMLMarketTableCellElement
>(`${MESSAGES_MARKET_COMPONENT_PREFIX}-market-table-cell`);
