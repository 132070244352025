import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './PlanCard.scss';
import {
  MarketContentCard,
  MarketList,
  MarketPill,
  MarketRow,
} from 'src/components/Market';
import CheckmarkIcon from 'src/svgs/CheckmarkIcon';
import { IMoney } from 'src/gen/squareup/connect/v2/common/money';
import { moneyToCurrencyString } from 'src/utils/moneyUtils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { useTranslation } from 'react-i18next';
import type { JSX } from '@market/react';

export type PlanCardProps = {
  title: string;
  descriptionList: string[];
  featureList: string[];
  price: IMoney;
  button: JSX.MarketButton;
  isRecommended?: boolean;
};

/**
 * A card to display information of a plan of Messages subscription.
 *
 * @example
 * Basic usage:
 * <PlanCard
 *   title="Free"
 *   description="Basic tier of Messages"
 *   featureList={['Reply to Messages', 'Send payment link']}
 *   price={subscription.price}
 *   button={<MarketButton>Upgrade</MarketButton>}
 * />
 * @param {string} title
 * Title of the card, typically the tier name.
 * @param {string[]} descriptionList
 * Description(s) of tier.
 * @param {string[]} featureList
 * List of string description of the features.
 * @param {IMoney} price
 * Pricing of the tier.
 * @param {MarketButton} button
 * The button rendered at the bottom.
 * @param {boolean} [isRecommended]
 * (Optional) If true, show 'Recommended' pill by title.
 * @author klim
 */
const PlanCard = observer((props: PlanCardProps): ReactElement => {
  const { title, descriptionList, featureList, price, button, isRecommended } =
    props;
  const { user } = useMessengerControllerContext();
  const { t } = useTranslation();

  return (
    <MarketContentCard className="PlanCard">
      <div className="PlanCard__content">
        <div className="PlanCard__title">
          {title}
          {isRecommended && (
            <MarketPill className="PlanCard__pill" variant="emphasis">
              {t('common.recommended')}
            </MarketPill>
          )}
        </div>
        {descriptionList.map((description) => (
          <p key={description}>{description}</p>
        ))}
        <MarketList>
          {featureList.map((feature) => (
            <MarketRow key={feature}>
              <label slot="label">{feature}</label>
              <CheckmarkIcon slot="trailing-accessory" />
            </MarketRow>
          ))}
        </MarketList>
      </div>
      <div className="PlanCard__footer">
        <div className="heading-30">
          {t('MessagesPlusPricingPage.per_month', {
            price: moneyToCurrencyString(price, user.locale),
          })}
        </div>
        <div className="paragraph-30">
          {t('MessagesPlusPricingPage.per_location')}
        </div>
        {button}
      </div>
    </MarketContentCard>
  );
});

export default PlanCard;
