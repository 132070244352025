import React, { Component, ReactElement, ReactNode, RefObject } from 'react';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import RequestPaymentIcon from 'src/svgs/RequestPaymentIcon';
import {
  amountToCurrencyString,
  currencyCodeToKey,
} from 'src/utils/moneyUtils';
import NewCustomerIcon from 'src/svgs/NewCustomerIcon';
import SuggestionRow from './components/SuggestionRow/SuggestionRow';
import './Suggestions.scss';
import GoogleIcon from 'src/svgs/GoogleIcon';
import MessengerControllerContext from 'src/context/MessengerControllerContext';
import type MessengerController from 'src/MessengerController';
import { Suggestion } from 'src/gen/squareup/messenger/v3/messenger_service';
import { getSuggestionTrackingMetadata } from 'src/utils/suggestionUtils';
import CouponSuggestion from './components/CouponSuggestion/CouponSuggestion';
import InvoiceSuggestion from './components/InvoiceSuggestion/InvoiceSuggestion';
import PhotoSuggestion from './components/PhotoSuggestion/PhotoSuggestion';
import { KEY_REQUEST_GOOGLE_REVIEW } from 'src/stores/FeatureFlagStore';
import AssistantIcon from 'src/svgs/AssistantIcon';
import AppointmentIcon from 'src/svgs/AppointmentIcon';
import { getCreateAppointmentUrl } from 'src/utils/url';

const M_PLUS_SUGGESTIONS = new Set([Suggestion.SuggestionType.SEND_COUPON]);

export type SuggestionsProps = {
  suggestions: Suggestion[];
  messageInput: string;
  setMessageInput: (text: string) => void;
  requestReview: () => void;
  photoInputRef: RefObject<HTMLInputElement>;
};

/**
 * A section that appears at the end of TranscriptViewItemsList that suggest
 * actions the user can take. This can either be a text reply, or actions like
 * sending a coupon, checkout link, etc. Smart replies will be hidden when the
 * input bar is not empty.
 *
 * @example
 * Basic usage:
 * <Suggestions
 *   suggestions={[...]}
 *   messageInput={message}
 *   setMessageInput={setMessage}
 *   requestReview={() => {...}}
 * />
 * @param {Suggestion[]} suggestions
 * The suggestions to render.
 * @param {string} messageInput
 * The text in the input bar, used to hide smart reply suggestions if the user
 * is already typing something.
 * @param {Function} setMessageInput
 * Function to set the text in the input bar, for smart replies.
 * @param {Function} requestReview
 * Function to call to request a review from the customer.
 * @param {RefObject} photoInputRef
 * A ref to the photo input used to upload photos.
 * @author klim
 */
class Suggestions extends Component<SuggestionsProps> {
  static contextType = MessengerControllerContext;
  declare context: MessengerController;

  componentDidMount(): void {
    this.trackViewSuggestions();
  }

  componentDidUpdate(prevProps: SuggestionsProps): void {
    const { suggestions: oldSuggestions } = prevProps;
    const { suggestions } = this.props;

    // When suggestions change, re-track view events
    if (oldSuggestions !== suggestions) {
      this.trackViewSuggestions();
    }
  }

  /**
   * Track view events of each suggestion. Happen during initial mount
   * and subsequent suggestions change.
   */
  trackViewSuggestions = (): void => {
    const { suggestions } = this.props;
    const { event, transcriptView } = this.context;
    suggestions.forEach((suggestion) => {
      event.track('View Smart Action', {
        suggestion_id: suggestion.suggestionId,
        action_type_name:
          Suggestion.SuggestionType[suggestion.suggestionType].toLowerCase(),
        needs_upgrade:
          M_PLUS_SUGGESTIONS.has(suggestion.suggestionType) &&
          transcriptView.isNotSubscribedToMPlus,
        transcript_id: transcriptView.transcript.id,
      });
    });
  };

  /**
   * Track click of a suggestion.
   *
   * @param {Suggestion} suggestion
   */
  trackClickSuggestion = (suggestion: Suggestion): void => {
    const { event, transcriptView } = this.context;
    event.track('Click Smart Action', {
      ...getSuggestionTrackingMetadata(suggestion),
      needs_upgrade:
        M_PLUS_SUGGESTIONS.has(suggestion.suggestionType) &&
        transcriptView.isNotSubscribedToMPlus,
      transcript_id: transcriptView.transcript.id,
    });
  };

  /**
   * Render a smart reply that inserts the suggested text into the
   * input bar when clicked.
   *
   * @param {Suggestion} suggestion
   */
  renderSuggestReplies = (suggestion: Suggestion): ReactElement | null => {
    const { setMessageInput } = this.props;
    const text = suggestion.suggestionMetadata?.reply?.text ?? '';

    if (!text) {
      return null;
    }

    return (
      <SuggestionRow
        key={suggestion.suggestionId}
        onClick={() => {
          this.trackClickSuggestion(suggestion);
          setMessageInput(text);
        }}
        text={text}
      />
    );
  };

  /**
   * Render a smart reply that inserts the suggested OBS booking link into the
   * input bar when clicked.
   *
   * @param {Suggestion} suggestion
   */
  renderBookingLink = (suggestion: Suggestion): ReactElement | null => {
    const { setMessageInput } = this.props;
    const text = suggestion.suggestionMetadata?.bookingLink?.bookingUrl;

    if (!text) {
      return null;
    }

    return (
      <SuggestionRow
        key={suggestion.suggestionId}
        onClick={() => {
          this.trackClickSuggestion(suggestion);
          setMessageInput(text);
        }}
        text={t('SmartSuggestions.booking_link')}
        icon={<AppointmentIcon />}
      />
    );
  };

  /**
   * Renders a request for payment that will open the checkout link modal
   * with the suggested amount when clicked.
   *
   * @param {Suggestion} suggestion
   */
  renderRequestPayment = (suggestion: Suggestion): ReactElement => {
    const { modal, user } = this.context;
    const { locale } = user;
    const amount = suggestion.suggestionMetadata?.payment?.amount;
    let paymentText;

    if (amount) {
      paymentText = t('SmartSuggestions.request_amount', {
        amount: amountToCurrencyString(
          amount.amount,
          currencyCodeToKey(amount.currency),
          locale,
        ),
      });
    } else {
      paymentText = t('SmartSuggestions.request');
    }

    return (
      <SuggestionRow
        key={suggestion.suggestionId}
        onClick={() => {
          this.trackClickSuggestion(suggestion);
          modal.openCheckoutLinkModal(amount?.amount);
        }}
        text={paymentText}
        icon={<RequestPaymentIcon />}
      />
    );
  };

  /**
   * Renders a button to open the New Customer modal with the contact info
   * prefilled when clicked
   *
   * @param {Suggestion} suggestion
   */
  renderCreateCustomer = (suggestion: Suggestion): ReactElement => {
    const { modal } = this.context;

    return (
      <SuggestionRow
        key={suggestion.suggestionId}
        onClick={() => {
          this.trackClickSuggestion(suggestion);
          modal.openCreateCustomerSuggestionModal(
            suggestion.suggestionMetadata?.customerInfo,
          );
        }}
        text={t('SmartSuggestions.create_customer')}
        icon={<NewCustomerIcon />}
      />
    );
  };

  /**
   * Renders a button to open the create appointment page when clicked
   *
   * @param {Suggestion} suggestion
   */
  renderCreateAppointment = (suggestion: Suggestion): ReactElement => {
    const { transcriptView, featureFlag } = this.context;
    const { transcript } = transcriptView;

    return (
      <SuggestionRow
        key={suggestion.suggestionId}
        onClick={() => {
          this.trackClickSuggestion(suggestion);
          window.open(
            getCreateAppointmentUrl(
              transcript.customerToken,
              transcript.sellerKey,
              featureFlag.useAppSubdomain,
            ),
            '_blank',
          );
        }}
        text={t('SmartSuggestions.create_appointment')}
        icon={<AppointmentIcon />}
      />
    );
  };

  /**
   * Render a button to request review.
   *
   * @param {Suggestion} suggestion
   */
  renderRequestReview = (suggestion: Suggestion): ReactElement | null => {
    const { requestReview } = this.props;
    const { featureFlag } = this.context;

    if (!featureFlag.get(KEY_REQUEST_GOOGLE_REVIEW)) {
      return null;
    }

    return (
      <SuggestionRow
        key={suggestion.suggestionId}
        onClick={() => {
          this.trackClickSuggestion(suggestion);
          requestReview();
        }}
        text={t('SmartSuggestions.request_review')}
        icon={<GoogleIcon />}
      />
    );
  };

  render(): ReactNode {
    const { messageInput, suggestions, photoInputRef } = this.props;

    // Do not show smart replies if there is already text in the input bar
    let filteredSuggestions = suggestions;
    if (messageInput !== '') {
      filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.suggestionType !==
            Suggestion.SuggestionType.SUGGEST_REPLIES &&
          suggestion.suggestionType !==
            Suggestion.SuggestionType.REQUEST_REVIEW,
      );
    }

    if (filteredSuggestions.length > 0) {
      const content: (ReactElement | null)[] = filteredSuggestions
        .map((suggestion) => {
          switch (suggestion.suggestionType) {
            case Suggestion.SuggestionType.SUGGEST_REPLIES:
              return this.renderSuggestReplies(suggestion);
            case Suggestion.SuggestionType.REQUEST_PAYMENT:
              return this.renderRequestPayment(suggestion);
            case Suggestion.SuggestionType.CREATE_CUSTOMER:
              return this.renderCreateCustomer(suggestion);
            case Suggestion.SuggestionType.REQUEST_REVIEW:
              return this.renderRequestReview(suggestion);
            case Suggestion.SuggestionType.CREATE_APPOINTMENT:
              return this.renderCreateAppointment(suggestion);
            case Suggestion.SuggestionType.SEND_BOOKING_LINK:
              return this.renderBookingLink(suggestion);
            case Suggestion.SuggestionType.SEND_COUPON:
              return (
                <CouponSuggestion
                  key={suggestion.suggestionId}
                  suggestion={suggestion}
                  trackClickSuggestion={() =>
                    this.trackClickSuggestion(suggestion)
                  }
                />
              );
            case Suggestion.SuggestionType.SEND_INVOICE:
              return (
                <InvoiceSuggestion
                  key={suggestion.suggestionId}
                  trackClickSuggestion={() =>
                    this.trackClickSuggestion(suggestion)
                  }
                />
              );
            case Suggestion.SuggestionType.SEND_PHOTO:
              return (
                <PhotoSuggestion
                  key={suggestion.suggestionId}
                  photoInputRef={photoInputRef}
                  trackClickSuggestion={() =>
                    this.trackClickSuggestion(suggestion)
                  }
                />
              );
            default:
              return null;
          }
        })
        .filter((element) => element !== null);

      if (content.length > 0) {
        return (
          <div className="Suggestions" data-testid="Suggestions">
            <div className="Suggestions__title">
              <AssistantIcon />
              {t('SmartSuggestions.suggested')}
            </div>
            <div className="Suggestions__body">{content}</div>
          </div>
        );
      }
    }

    return <></>;
  }
}

export default observer(Suggestions);
