import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MarketButton,
  MarketHeader,
  MarketLink,
  MarketList,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MessengerModalFullContent } from 'src/components/MessengerModalFull';
import MarketBackIcon from 'src/svgs/MarketBackIcon';
import { getMPlusSubscriptionDashboardUrl } from 'src/utils/url';
import NumberRow from 'src/components/NumberRow/NumberRow';
import './BusinessNumbersPage.scss';

/**
 * Top level component that renders the Business Numbers page. Represents a full listing
 * of all the business numbers owned by an individual merchant.
 */
const BusinessNumbersPage = observer((): ReactElement => {
  const { navigation, status, user, subscription, event, featureFlag } =
    useMessengerControllerContext();
  const { t } = useTranslation();
  const count = user.unitsWithPendingOrDedicatedNumbers?.length;
  const mPlusSubscriptionDashboardUrl = getMPlusSubscriptionDashboardUrl(
    featureFlag.useAppSubdomain,
  );
  return (
    <>
      <MarketHeader showNavigation compact>
        <MarketButton
          rank="secondary"
          slot="navigation"
          onClick={() => navigation.sheet.navigateBack()}
        >
          <MarketBackIcon slot="icon" />
        </MarketButton>
        <h2>{t('SettingsPage.business_numbers.page_title')}</h2>
      </MarketHeader>
      <MessengerModalFullContent status={status.value}>
        {!subscription.isExpiring && (
          <p className="paragraph-30 BusinessNumbersPage__numbers-desc-text">
            {subscription.isSubscriptionDelinquent ? (
              <Trans
                i18nKey="SettingsPage.business_numbers.terminated_text_multiple_with_count"
                count={count}
              >
                {"We've terminated the following {{count}} numbers. "}
                <MarketLink
                  href={mPlusSubscriptionDashboardUrl}
                  target="_self"
                  onClick={() => {
                    event.track('Click Settings Get Numbers Back');
                  }}
                >
                  Get them back
                </MarketLink>
              </Trans>
            ) : (
              <Trans
                i18nKey="SettingsPage.business_numbers.total_numbers_desc"
                count={count}
              >
                {'You have {{ count }} numbers for {{ count }} units. '}
                <MarketLink
                  href={mPlusSubscriptionDashboardUrl}
                  target="_self"
                  onClick={() => {
                    event.track('Click Settings Number Action', {
                      free_trial: subscription.isFreeTrial,
                      action_type_name: 'add_additional_number',
                    });
                  }}
                >
                  Add a number
                </MarketLink>
              </Trans>
            )}
          </p>
        )}
        <MarketList transient>
          {user.unitsWithPendingOrDedicatedNumbers.map((unit) => (
            <NumberRow key={unit.token} unit={unit} />
          ))}
        </MarketList>
      </MessengerModalFullContent>
    </>
  );
});

export default BusinessNumbersPage;
