import DataApi from '@squareup/data-api.js-core/dist/data-api';

/**
 * Following the Integration Guide at the link below, these types were originally defined in the ecosystem-header
 * repository and are expected to be copied to consumer repositories.
 *
 * Integration Guide: https://github.com/squareup/ecosystem-header/blob/main/INTEGRATION.md#typescript-support
 * Types Source: https://github.com/squareup/ecosystem-header/blob/main/src/types/ecosystem-header-example.d.ts
 */
export type Environment =
  | 'production'
  | 'sandbox'
  | 'staging'
  | 'development'
  | 'isolationDevelopment';

export interface EcosystemHeaderApiOptions {
  locale: string;
  target: Element;
  dataApi?: typeof DataApi;
  environment?: Environment;
  activeAppChangedHandler?: (activeApp: object) => void;
  linkClickHandler?: (url: string) => boolean;
  setupGuideClickHandler?: () => void;
  forceHideMessenger?: boolean;
  useAppDomain: boolean;
}

export declare type EcosystemHeader = {
  new (options: EcosystemHeaderApiOptions): Record<string, unknown>;
  toggleSetupGuideIcon: (shouldEnable: boolean) => void;
  toggleAppMenuSpacer: () => void;
  showSetupGuideTooltip: () => void;
  refreshNotifications: () => Promise<void>;
};

export const ECOSYSTEM_HEADER_READY_EVENT = 'ecosystemHeaderReady';
