import {
  Attachment,
  Bucket,
  Cursor,
  GetAttachmentsRequest,
  GetSuggestionsRequest,
  GetTranscriptsRequest,
  GetTranscriptWithUtterancesRequest,
  GetUnreadTranscriptCountRequest,
  IAttachment,
  IGetSuggestionsResponse,
  ITranscript,
  IUtterance,
  Suggestion,
  UpdateTranscriptRequest,
  UpdateUtteranceRequest,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import Services from 'src/services/Services';
import { callV3Rpc } from 'src/utils/apiUtils';
import Logger from 'src/Logger';
import {
  isContactMethod,
  isCustomerToken,
  isTranscriptIdAlternative,
  TranscriptIdAlternative,
} from 'src/MessengerTypes';
import { isAuthError } from 'src/utils/transcriptUtils';

const TRANSCRIPT_LIST_PAGE_SIZE = 25;

const UTTERANCE_LIST_PAGE_SIZE = 30;

const DEFAULT_COUNT_THRESHOLD = 10;

export const DEFAULT_ATTACHMENTS_PAGE_SIZE = 10;

/**
 * Api responsible for operations related to transcripts.
 */
class TranscriptsApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Retrieves a list of transcripts.
   *
   * @param {object} filters
   * The set of filters to apply to the returned list of transcripts, as follows:
   * @param {Bucket} [filters.bucket]
   * The bucket to filter the transcripts by (i.e. assistant, active, etc.).
   * @param {Cursor} [filters.cursor]
   * The page cursor to fetch the list from.
   * @param {string} [filters.customerToken]
   * The customer token to filter the returned list of results by.
   * @param {number} [filters.pageSize]
   * The number of transcripts to return per page.
   */
  getTranscripts = async (filters: {
    bucket?: Bucket;
    cursor?: Cursor;
    customerToken?: string;
    pageSize?: number;
  }): Promise<[readonly ITranscript[], Cursor | undefined]> => {
    const { transcripts, cursor: nextCursor } = await callV3Rpc({
      name: 'GetTranscripts',
      rpc: (x) => this._services.messagesV3.getTranscripts(x),
      request: GetTranscriptsRequest.create({
        bucket: filters?.bucket,
        transcriptPageSize: TRANSCRIPT_LIST_PAGE_SIZE,
        cursor: filters?.cursor,
        transcriptDetailsOptions: {
          includeBucket: true,
          includeIsRead: true,
          includeDisplayName: true,
          includePreviewUtterance: true,
          includeCustomerTokens: true,
        },
        customerToken: filters?.customerToken,
      }),
    });
    return [transcripts, nextCursor];
  };

  /**
   * Retrieve the suggestions associated to a specific utterance.
   *
   * @param {number} utteranceId - the id of the utterance we want to
   * get the suggestions for. This should be the most recent utterance
   * of that transcript
   */
  getSuggestions(utteranceId: number): Promise<Suggestion[]> {
    return callV3Rpc({
      name: 'GetSuggestions',
      rpc: (x) => this._services.messagesV3.getSuggestions(x),
      request: GetSuggestionsRequest.create({
        contextUtteranceId: utteranceId,
        suggestionMode: GetSuggestionsRequest.SuggestionMode.SMART_REPLY,
        supportedSuggestionTypes: [
          Suggestion.SuggestionType.CREATE_CUSTOMER,
          Suggestion.SuggestionType.REQUEST_PAYMENT,
          Suggestion.SuggestionType.REQUEST_REVIEW,
          Suggestion.SuggestionType.SUGGEST_REPLIES,
          Suggestion.SuggestionType.SEND_COUPON,
          Suggestion.SuggestionType.SEND_INVOICE,
          Suggestion.SuggestionType.SEND_PHOTO,
          Suggestion.SuggestionType.CREATE_APPOINTMENT,
          Suggestion.SuggestionType.SEND_BOOKING_LINK,
        ],
      }),
    })
      .then((response: IGetSuggestionsResponse) => {
        return response.suggestions as Suggestion[];
      })
      .catch((error) => {
        if (!isAuthError(error)) {
          // Logging to sentry here as we don't throw and instead return an empty array on error
          Logger.logWithSentry(
            'TranscriptsApi:getSuggestions - Error retrieving suggestions',
            'error',
            { error },
          );
        }
        // In the event of an error, return an empty array to show no suggestions
        return [];
      });
  }

  /**
   * Retrieves a list of attachments for a transcript.
   *
   * @param {object} filters
   * @param {number} filters.transcriptId
   * The transcript id for which the attachments will be fetched.
   * @param {number} filters.attachmentPageSize
   * The maximum number of attachments to return at once. The server may
   * choose to use a smaller page size. Defaults to 10 if no value is
   * specified.
   * @param {Attachment.AttachmentType[]} filters.attachmentTypes
   * Attachment types to filter for.  Only attachments of the given types will be returned.
   * @param {Cursor} [filters.cursor]
   * The page cursor to fetch the list from. If this value is specified,
   * @param {IAttachment['id']} [filters.startAfterAttachmentId]
   * Attachments older than this attachment id will be returned.
   */
  getAttachments = async (filters: {
    transcriptId: number;
    attachmentPageSize?: number;
    attachmentTypes?: Attachment.AttachmentType[];
    cursor?: Cursor;
    startAfterAttachmentId?: IAttachment['id'];
  }): Promise<[readonly IAttachment[], Cursor | undefined]> => {
    const {
      transcriptId,
      attachmentPageSize = DEFAULT_ATTACHMENTS_PAGE_SIZE,
      attachmentTypes,
      cursor,
      startAfterAttachmentId,
    } = filters;
    const { attachments, cursor: nextCursor } = await callV3Rpc({
      name: 'GetAttachments',
      rpc: (x) => this._services.messagesV3.getAttachments(x),
      request: GetAttachmentsRequest.create({
        transcriptId,
        attachmentPageSize,
        attachmentTypes,
        cursor,
        startAfterAttachmentId,
      }),
    });

    return [attachments, nextCursor];
  };

  /**
   * Retrieves the full set of transcript data including the paginated list of utterances associated with it.
   *
   * @param {object} args
   * @param {number | TranscriptIdAlternative} args.id
   * Either the transcript ID of the transcript to fetch data for or
   * an alternative ID the server can use to select the transcript by.
   * @param {number} [args.utterancePageSize]
   * The number of utterances to retrieve with the transcript.
   * @param {Cursor} [args.cursor]
   * The cursor indicating which page of the utterances list to load.
   * @param {number} [args.stopAtUtteranceId]
   * The ID of the utterance to load up until.
   * @param {number} [args.seekUtteranceId]
   * The ID of the utterance to load other utterances around.
   */
  getTranscriptWithUtterances = async ({
    id,
    utterancePageSize,
    cursor,
    stopAtUtteranceId,
    seekUtteranceId,
  }: {
    id: number | TranscriptIdAlternative;
    utterancePageSize?: number;
    cursor?: Cursor;
    stopAtUtteranceId?: number;
    seekUtteranceId?: number;
  }): Promise<[ITranscript, Cursor | undefined, Cursor | undefined]> => {
    const {
      transcript,
      cursor: backwardCursor,
      forwardCursor,
    } = await callV3Rpc({
      name: 'GetTranscriptWithUtterances',
      rpc: (x) => this._services.messagesV3.getTranscriptWithUtterances(x),
      request: GetTranscriptWithUtterancesRequest.create({
        transcriptId: !isTranscriptIdAlternative(id) ? id : undefined,
        customerToken: isCustomerToken(id) ? id.customerToken : undefined,
        contactMethodAndSellerKey: isContactMethod(id) ? id : undefined,
        utterancePageSize: utterancePageSize || UTTERANCE_LIST_PAGE_SIZE,
        cursor,
        stopAtUtteranceId,
        seekUtteranceId,
        transcriptDetailsOptions: {
          includeBucket: true,
          includeIsRead: true,
          includeDisplayName: true,
          includePreviewUtterance: true,
          includeCustomerTokens: true,
          includeConsentStatus: true,
          includeIsBlocked: true,
        },
      }),
    });

    if (!transcript) {
      throw new Error('Transcript missing from response.');
    }

    return [transcript, backwardCursor, forwardCursor];
  };

  /**
   * Retrieves a single utterance from the server. Typically used to get an updated version of an existing utterance.
   *
   * @param {number} id
   * The ID of the utterance to retrieve.
   */
  getUtterance = async (id: number): Promise<IUtterance> => {
    const { transcript } = await callV3Rpc({
      name: 'GetUtterance',
      rpc: (x) => this._services.messagesV3.getTranscriptWithUtterances(x),
      request: GetTranscriptWithUtterancesRequest.create({
        utteranceIds: {
          utteranceIds: [id],
        },
      }),
    });

    if (!transcript) {
      throw new Error('Transcript missing from response.');
    }

    if (transcript.details?.utterances?.length !== 1) {
      throw new Error(
        `Unexpected amount of utterances returned from server (${transcript.details?.utterances?.length} utterances returned)`,
      );
    }

    return transcript.details.utterances[0];
  };

  /**
   * Updates an utterance on the BE to mark it as soft deleted.
   *
   * @param {number} utteranceId
   * The ID of the utterance to mark as soft deleted.
   */
  softDeleteUtterance = async (utteranceId: number): Promise<void> => {
    await callV3Rpc({
      name: 'UpdateUtterance - Soft Delete Utterance',
      rpc: (x) => this._services.messagesV3.updateUtterance(x),
      request: UpdateUtteranceRequest.create({
        utteranceId,
        metadata: {
          softDeleted: true,
        },
      }),
    });
  };

  /**
   * Method to retrieve the unread transcripts count.
   *
   * @param {string} merchantToken
   * The merchant token to check the number of unread transcripts for.
   */
  getUnreadTranscriptsCount = async (
    merchantToken: string,
  ): Promise<number> => {
    try {
      const { unreadTranscriptCount } = await callV3Rpc({
        name: 'GetUnreadTranscriptsCount',
        rpc: (x) => this._services.messagesV3.getUnreadTranscriptCount(x),
        request: GetUnreadTranscriptCountRequest.create({
          merchantToken,
          countThreshold: DEFAULT_COUNT_THRESHOLD,
        }),
      });

      if (unreadTranscriptCount !== 0 && !unreadTranscriptCount) {
        throw new Error(
          `Invalid unread transcripts count returned from server: ${unreadTranscriptCount}`,
        );
      }
      return unreadTranscriptCount;
    } catch (error) {
      if (!isAuthError(error)) {
        // Logging to sentry here as we don't throw and instead return zero on error
        Logger.logWithSentry(
          'TranscriptsApi:getUnreadTranscriptsCount - Error retrieving unread transcripts count from server.',
          'error',
          { merchantToken, error },
        );
      }
      return 0;
    }
  };

  /**
   * Method to update an existing transcript at a given ID.
   *
   * @param {object} args
   * @param {number} args.id
   * The ID of the transcript to update.
   * @param {number} [args.readUntil]
   * ID of the utterance to mark this transcript as read up until.
   * @param {boolean} [args.isBlocked]
   * Flag indicating if this transcript should be marked as blocked or unblocked.
   */
  updateTranscript = async ({
    id,
    readUntil,
    isBlocked,
  }: {
    id: number;
    readUntil?: number;
    isBlocked?: boolean;
  }): Promise<void> => {
    await callV3Rpc({
      name: 'UpdateTranscript',
      rpc: (x) => this._services.messagesV3.updateTranscript(x),
      request: UpdateTranscriptRequest.create({
        transcriptId: id,
        readUntil:
          readUntil !== undefined
            ? {
                value: readUntil,
              }
            : undefined,
        isBlocked: isBlocked !== undefined ? { value: isBlocked } : undefined,
      }),
    });
  };
}

export default TranscriptsApi;
