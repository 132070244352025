import { useEffect, useState } from 'react';

const DEFAULT_MOBILE_SCREEN_WIDTH = '599px';

/**
 * Determines if the mobile view should be rendered for the current screen size.
 *
 * @returns {boolean} True if mobile and false if not
 */
const useIsMobile = (): boolean => {
  const maxWidth =
    window
      .getComputedStyle(document.body)
      .getPropertyValue('--core-breakpoint-narrow-max-width') ||
    DEFAULT_MOBILE_SCREEN_WIDTH;

  const mediaQueryList = window.matchMedia(`(max-width: ${maxWidth})`);
  const [isMobile, setIsMobile] = useState<boolean>(mediaQueryList.matches);

  useEffect(() => {
    const onChange = (event: MediaQueryListEvent): void => {
      setIsMobile(event.matches);
    };
    mediaQueryList.addEventListener?.('change', onChange);
    return () => {
      mediaQueryList.removeEventListener?.('change', onChange);
    };
  }, [mediaQueryList]);

  return isMobile;
};

export default useIsMobile;
