import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { MarketRow } from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import {
  getPrimaryContactMethod,
  mediumToString,
} from 'src/utils/transcriptUtils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

export type MediumSwitcherProps = {
  customer: Contact;
};

/**
 * Component that renders the menu options that allow the user to switch mediums.
 * Intended to be used inside a "More Menu" i.e. MarketList.
 *
 * @param {Contact} customer
 * The customer to render medium switching options for.
 */
const MediumSwitcher = observer(
  ({ customer }: MediumSwitcherProps): ReactElement => {
    const { navigation, transcriptView } = useMessengerControllerContext();
    const { t } = useTranslation();

    const mediums = [Medium.SMS, Medium.EMAIL].filter(
      (medium) => medium !== transcriptView.transcript.medium,
    );

    return (
      <>
        {mediums.map((medium) => {
          const contactMethod = getPrimaryContactMethod(customer, medium);
          if (!contactMethod?.contactHandle) {
            return null;
          }
          const { contactHandle } = contactMethod;
          const mediumString = mediumToString(medium).toLowerCase();
          const onClick = (): void => {
            navigation.openTranscriptByContactMethod(
              {
                contactHandle,
                medium,
                sellerKey: transcriptView.transcript.sellerKey,
              },
              'medium_switcher',
            );
          };
          return (
            <MarketRow
              key={`switch_medium_${mediumString}`}
              onClick={onClick}
              onKeyDown={(e) => onKeyDownEnter(e, onClick)}
            >
              <label slot="label">
                {t('MediumSwitcher.switch_medium_label', { mediumString })}
              </label>
            </MarketRow>
          );
        })}
      </>
    );
  },
);

export default MediumSwitcher;
