import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import type { JSX } from '@market/react';
import './WebsiteSettingRow.scss';
import {
  MarketRow,
  MarketAccessory,
  MarketLink,
  MarketToggle,
} from 'src/components/Market';
import { t } from 'i18next';

export interface WebsiteSettingRowProps extends JSX.MarketRow {
  icon: ReactElement;
  websiteLabel: string;
  description: string;
  url?: string;
  dataTestId?: string;
}

/**
 * Component that renders website settings in the Settings page.
 * Used to render Online Booking Site settings as well as Square Online settings.
 * Extends all props of the MarketRow component.
 *
 * If sites need to be grouped in a separate page, use the `variant: drill` prop
 * combined with the `onMarketRowSelected` prop to navigate to the new page.
 *
 * @param {ReactElement} icon
 * Icon to render on the left side of the row component.
 * @param {string} websiteLabel
 * Label for the website.
 * @param {string} description
 * Description for the website.
 * @param {string} [url]
 * (Optional) URL to navigate to, accompanied by a "View site" CTA.
 * @param {string} [dataTestId]
 * (Optional) Data test ID for the component.
 */
const WebsiteSettingRow = observer(
  (props: WebsiteSettingRowProps): ReactElement => {
    const {
      icon,
      websiteLabel,
      description,
      url,
      dataTestId,
      variant = 'regular',
    } = props;

    return (
      <MarketRow data-testid={dataTestId} {...props}>
        <MarketAccessory size="icon" slot="leading-accessory">
          {icon}
        </MarketAccessory>
        <label slot="label">{websiteLabel}</label>
        <p slot="subtext" className="WebsiteSettingRow__description">
          {description}
        </p>
        {url && (
          <div slot="subtext">
            <MarketLink href={url} target="_blank">
              {t('MessagesPluginSettings.surfaces.cta_label')}
            </MarketLink>
          </div>
        )}
        {variant === 'regular' ? <MarketToggle slot="control" /> : undefined}
      </MarketRow>
    );
  },
);

export default WebsiteSettingRow;
