import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { MarketBanner, MarketButton, MarketLink } from 'src/components/Market';
import {
  getDashboardSubscriptionsAddPaymentUrl,
  M_PLUS_PRIVACY_POLICY_URL,
  M_PLUS_TERMS_URL,
} from 'src/utils/url';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import useSavedSubscriptionPaymentMethod from 'src/hooks/useSavedSubscriptionPaymentMethod';
import './MessagesPlusSubscriptionModal.scss';

/**
 * A modal to show the subscription information of M+ as well as the payment method
 * that will be used. If there is a saved payment method, a button to start the subscription will be shown.
 * Else, the CTA will link to Dashboard's Pricing & Subscription page to add a payment method.
 */
const MessagesPlusSubscriptionModal = observer((): ReactElement => {
  const {
    modal,
    navigation,
    subscription,
    featureFlag,
    unitVerification,
    status,
    event,
  } = useMessengerControllerContext();
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useSavedSubscriptionPaymentMethod();

  useEffect(() => {
    if (subscription.savedPaymentMethodStatus === 'SUCCESS') {
      event.track('View Confirm Payment Method');
    }
  }, [subscription.savedPaymentMethodStatus, event]);

  let primaryButton;
  let content;
  const unitTokens = modal.messagesPlusSubscriptionModalUnitTokens || [];
  switch (subscription.savedPaymentMethodStatus) {
    case 'SUCCESS': {
      if (subscription.savedPaymentMethod) {
        const onClick = async (): Promise<void> => {
          event.track('Click Confirm Payment Method', {
            action_type_name: 'next',
          });
          setIsSaving(true);
          try {
            await unitVerification.submitUpdatedUnitInformation(unitTokens);

            setIsSaving(false);
            navigation.sheet.navigateTo('UNIT_VERIFICATION_SUCCESS');
            modal.closeModal();
          } catch {
            status.setModalError({
              label: t('UnitVerificationForm.submission_error'),
            });
            setIsSaving(false);
          }
        };
        primaryButton = (
          <MarketButton
            rank="primary"
            onClick={onClick}
            onKeyDown={(e) => onKeyDownEnter(e, onClick)}
            isLoading={isSaving || undefined}
            disabled={!subscription.canManage || undefined}
          >
            {t('MessagesPlus.subscribe')}
          </MarketButton>
        );
      } else {
        const onClick = (): void => {
          event.track('Click Confirm Payment Method', {
            action_type_name: 'add_payment_method',
          });
          subscription.saveOnboardingUnits(unitTokens);
          window.open(
            getDashboardSubscriptionsAddPaymentUrl(featureFlag.useAppSubdomain),
            '_self',
          );
        };
        primaryButton = (
          <MarketButton
            rank="primary"
            onClick={onClick}
            onKeyDown={(e) => onKeyDownEnter(e, onClick)}
          >
            {t('MessagesPlusSubscriptionModal.add_payment_method')}
          </MarketButton>
        );
      }

      content = (
        <div className="MessagesPlusSubscriptionModal__container">
          <div className="MessagesPlusSubscriptionModal__description-container">
            <p className="semibold-30">
              {t('MessagesPlusSubscriptionModal.description_title')}
            </p>
            <p>{t('MessagesPlusSubscriptionModal.description')}</p>
          </div>
          {subscription.savedPaymentMethod && (
            <p className="medium-30 MessagesPlusSubscriptionModal__payment-method">
              {subscription.savedPaymentMethod}
            </p>
          )}
          <p className="paragraph-30 MessagesPlusSubscriptionModal__legal-text">
            <Trans i18nKey="MessagesPlusSubscriptionModal.terms_and_conditions">
              {'By subscribing, you agree to the '}
              <MarketLink href={M_PLUS_TERMS_URL} target="_blank">
                Terms
              </MarketLink>
              {' and '}
              <MarketLink href={M_PLUS_PRIVACY_POLICY_URL} target="_blank">
                Privacy Policy
              </MarketLink>
              .
            </Trans>
          </p>
        </div>
      );
      break;
    }
    case 'LOADING': {
      content = <LoadingIndicator />;
      break;
    }
    case 'ERROR': {
      content = !subscription.canManage ? (
        <div className="MessagesPlusSubscriptionModal__banner">
          <MarketBanner variant="critical">
            {t('MessagesPlusPricingPage.unavailable')}
          </MarketBanner>
        </div>
      ) : (
        <MarketBanner variant="critical">
          {t('common.error.try_again')}
        </MarketBanner>
      );
      break;
    }
    default:
  }

  return (
    <MessengerModalPartial
      title={t('MessagesPlusSubscriptionModal.title')}
      close={modal.closeModal}
      primaryButton={primaryButton}
      status={status.value}
    >
      {content}
    </MessengerModalPartial>
  );
});

export default MessagesPlusSubscriptionModal;
