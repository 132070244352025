import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import './MessagesPlusPricingPage.scss';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Currency } from 'src/gen/squareup/connect/v2/common/money';
import PlanCard from './components/PlanCard/PlanCard';
import { MarketBanner, MarketButton } from 'src/components/Market';
import {
  MessengerModalFullHeader,
  MessengerModalFullContent,
} from 'src/components/MessengerModalFull';
import { currencyKeyToCode } from 'src/utils/moneyUtils';
import UnverifiedModal from 'src/pages/TranscriptViewPage/components/UnverifiedModal/UnverifiedModal';
import { KEY_GATE_MESSAGES_PLUS_ON_IDV } from 'src/stores/FeatureFlagStore';

/**
 * This sheet displays the details of Messages Plus, giving a comparisons
 * of the different tiers and a button that links to dashboard subscription
 * UI for the actual subscription flow.
 *
 * @author klim
 */
const MessagesPlusPricingPage = observer((): ReactElement => {
  const { t } = useTranslation();
  const { navigation, user, subscription, event, featureFlag, modal } =
    useMessengerControllerContext();

  const {
    getIdvCompletionStatus,
    isIdvComplete: isIdvAlreadyComplete,
    currencyCode,
    currentEmployee,
  } = user;

  useEffect(() => {
    event.track('View Subscription Pricing');
  }, [event]);

  return (
    <>
      <MessengerModalFullHeader
        title={t('MessagesPlusPricingPage.title')}
        compactTitle
      />
      <MessengerModalFullContent status={null}>
        <div className="MessagesPlusPricingPage__container">
          {!subscription.canManage && (
            <div className="MessagesPlusPricingPage__header">
              <MarketBanner variant="critical">
                {t('MessagesPlusPricingPage.unavailable')}
              </MarketBanner>
            </div>
          )}

          <div className="MessagesPlusPricingPage__body">
            <PlanCard
              title={t('MessagesPlusPricingPage.free.title')}
              descriptionList={[t('MessagesPlusPricingPage.free.desc')]}
              featureList={[
                t('MessagesPlusPricingPage.free.feature_one'),
                t('MessagesPlusPricingPage.free.feature_two'),
                t('MessagesPlusPricingPage.free.feature_three'),
                t('MessagesPlusPricingPage.free.feature_four'),
              ]}
              price={{
                amount: 0,
                currency: currencyKeyToCode(currencyCode) || Currency.USD,
              }}
              button={
                <MarketButton
                  className="PlanCard__button"
                  rank="secondary"
                  onClick={() => {
                    event.track('Click Subscription Pricing Action', {
                      action_type_name: 'back_to_free',
                      is_owner: currentEmployee.isOwner,
                    });
                    navigation.closeSheet();
                  }}
                >
                  {t('MessagesPlusPricingPage.free.button_text')}
                </MarketButton>
              }
            />
            <PlanCard
              title={t('MessagesPlusPricingPage.paid.title')}
              descriptionList={[
                t('MessagesPlusPricingPage.paid.desc'),
                t('MessagesPlusPricingPage.paid.desc_two'),
              ]}
              featureList={[
                t('MessagesPlusPricingPage.paid.feature_one'),
                t('MessagesPlusPricingPage.paid.feature_two'),
                t('MessagesPlusPricingPage.paid.feature_five'),
                t('MessagesPlusPricingPage.paid.feature_three'),
                t('MessagesPlusPricingPage.paid.feature_four'),
              ]}
              price={{
                amount: subscription.pricing?.amount || 0,
                currency: currencyKeyToCode(currencyCode) || Currency.USD,
              }}
              button={
                <MarketButton
                  className="PlanCard__button"
                  rank="primary"
                  target="_self"
                  onClick={async () => {
                    if (
                      featureFlag.get(KEY_GATE_MESSAGES_PLUS_ON_IDV) &&
                      !isIdvAlreadyComplete
                    ) {
                      const isIdvComplete = await getIdvCompletionStatus();

                      if (!isIdvComplete) {
                        event.track('Click Subscription Pricing Action', {
                          action_type_name: subscription.hasPreviouslySubscribed
                            ? 'upgrade'
                            : 'try_free',
                          is_owner: currentEmployee.isOwner,
                          flow_type: 'idv',
                        });

                        modal.openModal('UNVERIFIED_IDENTITY');
                        return;
                      }
                    }

                    event.track('Click Subscription Pricing Action', {
                      action_type_name: subscription.hasPreviouslySubscribed
                        ? 'upgrade'
                        : 'try_free',
                      is_owner: currentEmployee.isOwner,
                      flow_type: 'onboarding',
                    });

                    if (
                      subscription.unitsAvailableForMPlusRegistration.length ===
                      1
                    ) {
                      navigation.sheet.navigateTo({
                        name: 'UNIT_VERIFICATION_FORM',
                        unitToken:
                          subscription.unitsAvailableForMPlusRegistration[0]
                            .token,
                      });
                      return;
                    }

                    navigation.sheet.navigateTo(
                      'MESSAGES_PLUS_START_VERIFICATION',
                    );
                  }}
                  disabled={!subscription.canManage || undefined}
                >
                  {subscription.hasPreviouslySubscribed
                    ? t('MessagesPlusPricingPage.paid.subscribe')
                    : t('MessagesPlusPricingPage.paid.try')}
                </MarketButton>
              }
            />
          </div>
        </div>
        {modal.currentModal === 'UNVERIFIED_IDENTITY' && (
          <UnverifiedModal variant="SUBSCRIPTION_BLOCKED" />
        )}
      </MessengerModalFullContent>
    </>
  );
});

export default MessagesPlusPricingPage;
