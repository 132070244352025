import { makeAutoObservable, reaction, runInAction } from 'mobx';
import {
  IMessengerSettings,
  IVoicemailGreeting,
  MessengerSettings,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import Logger from 'src/Logger';
import {
  LoadingStatus,
  Setting,
  SquareOnlineSetting,
  UnitSetting,
  VoicemailSetting,
} from 'src/MessengerTypes';
import Api from 'src/api/Api';
import type MessengerController from 'src/MessengerController';
import { getGoogleReviewUrl } from 'src/utils/url';
import {
  KEY_REQUEST_GOOGLE_REVIEW,
  KEY_VOICEMAIL_CUSTOMIZATION,
} from './FeatureFlagStore';
import Sound from 'src/stores/objects/Sound';

/**
 * Store responsible for settings.
 *
 * Note: non-user fields are the saved values (matches what exists on the server), while the user fields
 * are the state of those same values that have been edited by the user (i.e. if a switch was toggled off, etc.).
 * These need to be tracked separately because we need the ability to "undo" the user changes if the user
 * navigates out of the settings page w/o saving their updates.
 */
class SettingsStore {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * Setting that controls email notification.
   */
  emailNotification: Setting = {};

  userEmailNotification?: boolean;

  /**
   * Setting that controls push notification.
   */
  pushNotification: Setting = {};

  userPushNotification?: boolean;

  /**
   * Setting that controls Square Assistant.
   */
  squareAssistant: Setting = {};

  userSquareAssistant?: boolean;

  /**
   * Setting that controls Do Not Disturb. Note that the underlying setting
   * is encourageRepliesSetting, which when set to false means do not disturb
   * is active.
   */
  doNotDisturb: Setting = {};

  userDoNotDisturb?: boolean;

  /**
   * Setting indicating whether the Messages Plugin is enabled on the buyer booking flow.
   */
  pluginEnabledOnAppts: Setting = {};

  userPluginEnabledOnAppts?: boolean;

  /**
   * Setting indicating what the Messages Plugin response time is.
   */
  pluginResponseTime: Setting = {};

  userPluginResponseTime?: MessengerSettings.PluginResponseTime;

  /**
   * Setting indicating whether the merchant has a booking site configured.
   * Used to determine whether to show an upsell to the merchant.
   * This property is considered read-only by the BE and should not be updated with a UpdateMessengerSettings request.
   */
  hasABookingSite: Setting = {};

  /**
   * Setting that tracks whether the top level voicemail functionality has been enabled.
   * Voicemail can also be enabled/disabled at the unit level (contained in unitSettings).
   */
  voicemailEnabled: Setting = {};

  userVoicemailEnabled?: boolean;

  /**
   * A map of unit token to unit setting.
   */
  unitSettings: Map<string, UnitSetting> = new Map();

  /**
   * Map of unit tokens to unit settings that the user has edited but that has not yet
   * been persisted to the backend.
   */
  userUnitSettings: Map<string, UnitSetting> = new Map();

  status: LoadingStatus = 'NOT_STARTED';

  /**
   * The status of the action of uploading the voicemail greeting to the server.
   */
  voicemailUploadStatus: LoadingStatus = 'NOT_STARTED';

  /**
   * The status of the action of saving the voicemail customization settings to the server.
   */
  voicemailSaveStatus: LoadingStatus = 'NOT_STARTED';

  /**
   * Mapping of unique sound IDs to sound objects containing voicemails.
   */
  voicemails: Map<string, Sound> = new Map();

  /**
   * Settings for Square Online websites associated with the merchant.
   */
  sqOnlineSettings: SquareOnlineSetting[] = [];

  /**
   * If present, indicates that the user has made a change to enable/disable the
   * plugin on their Square Online site.
   * Only applies when user has 1 Square Online site.
   */
  userPluginEnabledOnSqOnlineSite?: SquareOnlineSetting;

  constructor(stores: MessengerController) {
    makeAutoObservable<SettingsStore, '_setUnitSetting'>(this);

    this._stores = stores;
    this._api = stores.api;

    reaction(
      () => {
        const result = [];
        for (const unitSetting of this.unitSettings.values()) {
          result.push(unitSetting.voicemail);
        }
        return result;
      },
      () => {
        if (!this._stores.featureFlag.get(KEY_VOICEMAIL_CUSTOMIZATION)) {
          return;
        }
        const setVoicemail = (greeting?: IVoicemailGreeting): void => {
          const { url, mimeType, token } = greeting || {};
          if (url && !this.voicemails.has(url)) {
            this.voicemails.set(
              url,
              new Sound(this._stores, { url, mimeType, token }),
            );
          }
        };
        this.unitSettings.forEach((unitSetting) => {
          setVoicemail(unitSetting.voicemail.defaultVoicemailGreeting);
          setVoicemail(unitSetting.voicemail.customVoicemailGreeting);
        });
      },
    );
  }

  /**
   * Set the properties if it does not already exist, if not replace the old
   * values.
   *
   * @param {object} param
   * @param {Setting} [param.emailNotification]
   * @param {Setting} [param.pushNotification]
   * @param {Setting} [param.squareAssistant]
   * @param {Setting} [param.doNotDisturb]
   * @param {Map<string, UnitSetting>}[param.unitSettings]
   * @param {Setting} [param.voicemailEnabled]
   * @param {Setting} [param.pluginEnabledOnAppts]
   * @param {Setting} [param.pluginResponseTime]
   * @param {Setting} [param.hasABookingSite]
   * @param {SquareOnlineSetting[]} [param.sqOnlineSettings]
   */
  private _set = ({
    emailNotification,
    pushNotification,
    squareAssistant,
    doNotDisturb,
    unitSettings,
    voicemailEnabled,
    pluginEnabledOnAppts,
    pluginResponseTime,
    hasABookingSite,
    sqOnlineSettings,
  }: {
    emailNotification?: Setting;
    pushNotification?: Setting;
    squareAssistant?: Setting;
    doNotDisturb?: Setting;
    unitSettings?: Map<string, UnitSetting>;
    voicemailEnabled?: Setting;
    pluginEnabledOnAppts?: Setting;
    pluginResponseTime?: Setting;
    hasABookingSite?: Setting;
    sqOnlineSettings?: SquareOnlineSetting[];
  }): void => {
    if (emailNotification) {
      this.emailNotification = {
        ...this.emailNotification,
        ...emailNotification,
      };
    }

    if (pushNotification) {
      this.pushNotification = {
        ...this.pushNotification,
        ...pushNotification,
      };
    }

    if (squareAssistant) {
      this.squareAssistant = {
        ...this.squareAssistant,
        ...squareAssistant,
      };
    }

    if (doNotDisturb) {
      this.doNotDisturb = {
        ...this.doNotDisturb,
        ...doNotDisturb,
      };
    }

    if (unitSettings) {
      this.unitSettings = new Map([...this.unitSettings, ...unitSettings]);
    }

    if (voicemailEnabled) {
      this.voicemailEnabled = {
        ...this.voicemailEnabled,
        ...voicemailEnabled,
      };
    }

    if (pluginEnabledOnAppts) {
      this.pluginEnabledOnAppts = {
        ...this.pluginEnabledOnAppts,
        ...pluginEnabledOnAppts,
      };
    }

    if (pluginResponseTime) {
      this.pluginResponseTime = {
        ...this.pluginResponseTime,
        ...pluginResponseTime,
      };
    }

    if (hasABookingSite) {
      this.hasABookingSite = {
        ...this.hasABookingSite,
        ...hasABookingSite,
      };
    }

    if (sqOnlineSettings) {
      this.sqOnlineSettings = [...sqOnlineSettings];
    }
  };

  private _setUnitSetting = (unitToken: string, setting: UnitSetting): void => {
    this.unitSettings.set(unitToken, setting);
  };

  init = async (): Promise<void> => {
    this.status = 'LOADING';

    try {
      // Call settings endpoint
      const rawSettings = await this._api.settings.getMessengerSettings();
      if (!rawSettings) {
        throw new Error(
          'Could not find settings in GetMessengerSettings response.',
        );
      }

      // Populate local state
      this._set({
        emailNotification: rawSettings.emailNotificationsEnabled,
        pushNotification: rawSettings.pushNotificationsEnabled,
        squareAssistant: rawSettings.botEnabled,
        doNotDisturb: {
          ...rawSettings.encourageReplies,
          boolValue:
            rawSettings.encourageReplies?.boolValue !== undefined
              ? !rawSettings.encourageReplies.boolValue
              : undefined,
        },
        voicemailEnabled: rawSettings.voicemailEnabled,
        pluginEnabledOnAppts: rawSettings.pluginEnabledOnAppts,
        pluginResponseTime: rawSettings.pluginResponseTime,
        hasABookingSite: rawSettings.hasABookingSite,
        sqOnlineSettings: [...rawSettings.squareOnlineWebsiteSettings],
      });

      // Populate unit settings
      rawSettings.unitSettings.forEach((unitSetting) => {
        const unitToken = unitSetting.unitToken;
        const placeId = unitSetting.reviewGooglePlaceId?.stringValue;

        this._setUnitSetting(unitToken, {
          unitToken,
          placeId: placeId ?? '',
          reviewMessage: unitSetting.reviewRequestCustomText?.stringValue ?? '',
          url: unitSetting.reviewRequestShortUrl?.stringValue ?? '',
          // voicemailEnabled is deprecated with the KEY_VOICEMAIL_CUSTOMIZATION FF in favour of voicemail.isEnabled
          voicemailEnabled: unitSetting.voicemailEnabled?.boolValue || false,
          voicemail: {
            isEnabled: unitSetting.voicemailEnabled?.boolValue || false,
            defaultVoicemailGreeting:
              unitSetting.defaultVoicemailGreeting || {},
            customVoicemailGreeting: unitSetting.customVoicemailGreeting,
            customVoicemailId: unitSetting.customVoicemailId?.stringValue,
            isTextEnabled: unitSetting.voicemailTextEnabled?.boolValue || false,
            textMessage:
              unitSetting.voicemailTextMessageText?.stringValue || '',
            appliesToCurrentEmployee:
              unitSetting.voicemailEnabled?.appliesToCurrentEmployee || true,
          },
        });

        if (
          this._stores.featureFlag.get(KEY_REQUEST_GOOGLE_REVIEW) &&
          placeId
        ) {
          // If there is a placeId, we look up the Google address. We don't
          // have to wait on this and it can async load
          this._api.google
            .getPlaceDetails(placeId)
            .then((details) => {
              const address = `${details.name}, ${details.address}`;
              this._setUnitSetting(unitToken, {
                ...(this.unitSettings.get(unitToken) as UnitSetting),
                address,
              });
            })
            .catch(() => {
              Logger.warn(`Failed to get Google details for unit ${unitToken}`);
            });
        }
      });

      runInAction(() => {
        this.status = 'SUCCESS';
      });
    } catch {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  };

  /**
   * Refreshes the default voicemail greeting for all units. The default voicemail can change when other unit settings
   * are updated, so this method provides a way to refresh the default voicemail when these settings are altered.
   */
  refreshDefaultVoicemail = async (): Promise<void> => {
    try {
      const rawSettings = await this._api.settings.getMessengerSettings();

      rawSettings?.unitSettings.forEach((unitSetting) => {
        const unitToken = unitSetting.unitToken;
        const localUnitSetting = this.unitSettings.get(unitToken);

        if (!localUnitSetting) {
          return;
        }

        this._setUnitSetting(unitToken, {
          ...localUnitSetting,
          voicemail: {
            ...localUnitSetting?.voicemail,
            defaultVoicemailGreeting:
              unitSetting.defaultVoicemailGreeting || {},
          },
        });
      });
    } catch (error) {
      Logger.error('Unable to refresh default voicemail.', undefined, {
        error,
      });
    }
  };

  get hasUnitSettings(): boolean {
    return this.unitSettings.size > 0;
  }

  save = async (): Promise<void> => {
    const emailNotification =
      this.userEmailNotification !== undefined
        ? {
            boolValue: this.userEmailNotification,
          }
        : undefined;
    const pushNotification =
      this.userPushNotification !== undefined
        ? {
            boolValue: this.userPushNotification,
          }
        : undefined;
    const squareAssistant =
      this.userSquareAssistant !== undefined
        ? { boolValue: this.userSquareAssistant }
        : undefined;
    const voicemailEnabled =
      this.userVoicemailEnabled !== undefined
        ? { boolValue: this.userVoicemailEnabled }
        : undefined;
    const pluginEnabledOnAppts =
      this.userPluginEnabledOnAppts !== undefined
        ? { boolValue: this.userPluginEnabledOnAppts }
        : undefined;
    const pluginResponseTime =
      this.userPluginResponseTime !== undefined
        ? {
            pluginResponseTimeValue: this.userPluginResponseTime,
          }
        : undefined;
    const squareOnlineWebsiteSettings = this.userPluginEnabledOnSqOnlineSite
      ? [this.userPluginEnabledOnSqOnlineSite]
      : undefined;
    const messengerSettings: IMessengerSettings = {
      emailNotificationsEnabled: emailNotification,
      pushNotificationsEnabled: pushNotification,
      botEnabled: squareAssistant,
      encourageReplies:
        this.userDoNotDisturb !== undefined
          ? {
              boolValue: !this.userDoNotDisturb,
            }
          : undefined,
      voicemailEnabled,
      pluginEnabledOnAppts,
      pluginResponseTime,
      squareOnlineWebsiteSettings,
    };

    if (this.userUnitSettings.size > 0) {
      const messengerUnitSettings: MessengerSettings.IUnitSettings[] = [];
      this.userUnitSettings.forEach((unitSetting) => {
        messengerUnitSettings.push({
          unitToken: unitSetting.unitToken,
          reviewGooglePlaceId: {
            stringValue: unitSetting.placeId,
          },
          reviewRequestCustomText: {
            stringValue: unitSetting.reviewMessage,
          },
          reviewRequestShortUrl: {
            stringValue: unitSetting.url,
          },
          voicemailEnabled: !this._stores.featureFlag.get(
            KEY_VOICEMAIL_CUSTOMIZATION,
          )
            ? {
                boolValue:
                  this.userVoicemailEnabled !== false &&
                  unitSetting.voicemailEnabled,
              }
            : undefined,
        });
      });
      messengerSettings.unitSettings = messengerUnitSettings;
    }

    try {
      await this._api.settings.saveSettings(messengerSettings);
      this._set({
        emailNotification,
        pushNotification,
        squareAssistant,
        doNotDisturb:
          this.userDoNotDisturb !== undefined
            ? { boolValue: this.userDoNotDisturb }
            : undefined,
        unitSettings:
          this.userUnitSettings.size > 0 ? this.userUnitSettings : undefined,
        voicemailEnabled,
        pluginEnabledOnAppts,
        pluginResponseTime,
        sqOnlineSettings: squareOnlineWebsiteSettings,
      });
    } catch (error) {
      Logger.error('Unable to save settings.');
      throw error;
    }
  };

  setUserEmailNotification = (value: boolean): void => {
    this.userEmailNotification = value;
  };

  setUserPushNotification = (value: boolean): void => {
    this.userPushNotification = value;
  };

  setUserSquareAssistant = (value: boolean): void => {
    this.userSquareAssistant = value;
  };

  setUserDoNotDisturb = (value: boolean): void => {
    this.userDoNotDisturb = value;
  };

  setUserVoicemailEnabled = (value: boolean): void => {
    this.userVoicemailEnabled = value;
  };

  setUserPluginEnabledOnAppts = (value: boolean): void => {
    this.userPluginEnabledOnAppts = value;
  };

  setUserPluginResponseTime = (
    value: MessengerSettings.PluginResponseTime,
  ): void => {
    this.userPluginResponseTime = value;
  };

  setUserPluginEnabledOnSqOnlineSite = (
    setting: SquareOnlineSetting,
    isEnabled: boolean,
  ): void => {
    this.userPluginEnabledOnSqOnlineSite = {
      ...setting,
      pluginEnabled: {
        ...setting.pluginEnabled,
        boolValue: isEnabled,
      },
    };
  };

  setUserUnitSetting = (unitSetting: UnitSetting): void => {
    this.userUnitSettings = new Map([
      ...this.userUnitSettings,
      [unitSetting.unitToken, unitSetting],
    ]);
  };

  clearUserSettings = (): void => {
    this.userEmailNotification = undefined;
    this.userPushNotification = undefined;
    this.userSquareAssistant = undefined;
    this.userDoNotDisturb = undefined;
    this.userVoicemailEnabled = undefined;
    this.userPluginEnabledOnAppts = undefined;
    this.userPluginResponseTime = undefined;
    this.userUnitSettings.clear();
    this.userPluginEnabledOnSqOnlineSite = undefined;
  };

  exitWithoutSaving = (): void => {
    this._stores.modal.closeModal();
    this._stores.navigation.closeSheet();
    this.clearUserSettings();
  };

  close = (): void => {
    if (this.userMadeAChange) {
      this._stores.modal.openModal('UNSAVED');
    } else {
      this.exitWithoutSaving();
    }
  };

  enableVoicemailForUnit = (unitToken: string): void => {
    const setting = (this.userUnitSettings.get(unitToken) ||
      this.unitSettings.get(unitToken)) as UnitSetting;
    this.setUserUnitSetting({ ...setting, voicemailEnabled: true });
  };

  /**
   * Enable voicemail on all units subscribed to M+.
   */
  enableVoicemailOnAllEligibleUnits = (): void => {
    this.unitSettings.forEach((setting, unitToken) => {
      if (this._stores.subscription.isUnitSubscribed(unitToken)) {
        this.setUserUnitSetting({ ...setting, voicemailEnabled: true });
      }
    });
  };

  disableVoicemailForUnit = (unitToken: string): void => {
    const setting = (this.userUnitSettings.get(unitToken) ||
      this.unitSettings.get(unitToken)) as UnitSetting;
    this.setUserUnitSetting({ ...setting, voicemailEnabled: false });
  };

  /**
   * Disable voicemail on all units subscribed to M+.
   */
  disableVoicemailOnAllEligibleUnits = (): void => {
    this.unitSettings.forEach((setting, unitToken) => {
      if (this._stores.subscription.isUnitSubscribed(unitToken)) {
        this.setUserUnitSetting({ ...setting, voicemailEnabled: false });
      }
    });
  };

  uploadVoicemailGreeting = async (
    unitToken: string,
    audioBlob: Blob,
  ): Promise<void> => {
    this.voicemailUploadStatus = 'LOADING';
    try {
      const [customVoicemailId, customVoicemailGreeting] =
        await this._api.file.uploadVoicemailGreeting(audioBlob);

      const { url, mimeType, token } = customVoicemailGreeting;

      if (!url) {
        throw new Error('URL not found in upload voicemail greeting response.');
      }

      this.voicemails.set(
        url,
        new Sound(this._stores, { url, mimeType, token }),
      );

      const unitSetting = this.allUnitSettings.get(unitToken) as UnitSetting;

      this.setUserUnitSetting({
        ...unitSetting,
        voicemail: {
          ...unitSetting?.voicemail,
          customVoicemailGreeting,
          customVoicemailId,
        },
      });

      this.voicemailUploadStatus = 'SUCCESS';
    } catch (error) {
      this.voicemailUploadStatus = 'ERROR';
      throw error;
    }
  };

  saveVoicemailUnitSettings = async (
    unitToken: string,
    voicemailSettings: VoicemailSetting,
  ): Promise<void> => {
    this.voicemailSaveStatus = 'LOADING';

    const { isEnabled, customVoicemailId, isTextEnabled, textMessage } =
      voicemailSettings;

    const unitSetting: Record<string, unknown> = {
      unitToken,
      voicemailEnabled:
        isEnabled !== undefined ? { boolValue: isEnabled } : undefined,
      customVoicemailId:
        customVoicemailId !== undefined
          ? { stringValue: customVoicemailId }
          : undefined,
      voicemailTextEnabled:
        isTextEnabled !== undefined ? { boolValue: isTextEnabled } : undefined,
      voicemailTextMessageText:
        textMessage !== undefined ? { stringValue: textMessage } : undefined,
    };

    const messengerSettings: IMessengerSettings = {
      unitSettings: [unitSetting],
    };

    const newVoicemailSettings: Record<string, unknown> = voicemailSettings;
    const savedVoicemailSettings: Record<string, unknown> | undefined =
      this.unitSettings.get(unitToken)?.voicemail;

    this._stores.event.track('Save Voicemail Greeting Settings', {
      unit_token: unitToken,
      updated_settings: Object.fromEntries(
        ['isEnabled', 'customVoicemailId', 'isTextEnabled', 'textMessage']
          .filter(
            (key) =>
              newVoicemailSettings[key] !== savedVoicemailSettings?.[key],
          )
          .map((key) => [key, newVoicemailSettings[key]]),
      ),
    });

    try {
      await this._api.settings.saveSettings(messengerSettings);
      this._setUnitSetting(unitToken, {
        ...(this.unitSettings.get(unitToken) as UnitSetting),
        voicemail: {
          ...voicemailSettings,
        },
      });
      this.clearUserVoicemailUnitSettings(unitToken);
      this.voicemailSaveStatus = 'SUCCESS';
    } catch (error) {
      this.voicemailSaveStatus = 'ERROR';
      throw error;
    }
  };

  clearUserVoicemailUnitSettings = (unitToken: string): void => {
    this.userUnitSettings.delete(unitToken);
  };

  resetVoicemailStatuses = (): void => {
    this.voicemailUploadStatus = 'NOT_STARTED';
    this.voicemailSaveStatus = 'NOT_STARTED';
  };

  get allUnitSettings(): Map<string, UnitSetting> {
    return new Map([...this.unitSettings, ...this.userUnitSettings]);
  }

  getReviewShortUrlFromPlaceId = (googlePlaceId: string): Promise<string> => {
    return this._api.settings.getReviewShortUrl(
      getGoogleReviewUrl(googlePlaceId),
    );
  };

  get isDoNotDisturbEnabled(): boolean {
    return Boolean(this.userDoNotDisturb ?? this.doNotDisturb.boolValue);
  }

  get userMadeAChange(): boolean {
    return (
      (this.userEmailNotification !== undefined &&
        this.userEmailNotification !== this.emailNotification.boolValue) ||
      (this.userPushNotification !== undefined &&
        this.userPushNotification !== this.pushNotification.boolValue) ||
      (this.userSquareAssistant !== undefined &&
        this.userSquareAssistant !== this.squareAssistant.boolValue) ||
      (this.userDoNotDisturb !== undefined &&
        this.userDoNotDisturb !== this.doNotDisturb.boolValue) ||
      (this.userVoicemailEnabled !== undefined &&
        this.userVoicemailEnabled !== this.voicemailEnabled.boolValue) ||
      (this.userPluginEnabledOnAppts !== undefined &&
        this.userPluginEnabledOnAppts !==
          this.pluginEnabledOnAppts.boolValue) ||
      (this.userPluginResponseTime !== undefined &&
        this.userPluginResponseTime !==
          this.pluginResponseTime.pluginResponseTimeValue) ||
      (this.userPluginEnabledOnSqOnlineSite !== undefined &&
        this.sqOnlineSettings.length &&
        this.sqOnlineSettings[0].pluginEnabled?.boolValue !==
          this.userPluginEnabledOnSqOnlineSite.pluginEnabled?.boolValue) ||
      this.userUnitSettings.size > 0
    );
  }

  userMadeAVoicemailChange = (unitToken: string): boolean => {
    const userUnitSetting = this.userUnitSettings.get(unitToken);
    const unitSetting = this.unitSettings.get(unitToken);
    return (
      userUnitSetting !== undefined &&
      unitSetting !== undefined &&
      (userUnitSetting.voicemail.isEnabled !==
        unitSetting.voicemail.isEnabled ||
        userUnitSetting.voicemail.customVoicemailId !==
          unitSetting.voicemail.customVoicemailId ||
        userUnitSetting.voicemail.isTextEnabled !==
          unitSetting.voicemail.isTextEnabled ||
        userUnitSetting.voicemail.textMessage !==
          unitSetting.voicemail.textMessage)
    );
  };

  get hasSqOnlineSettings(): boolean {
    return this.sqOnlineSettings.length > 0;
  }

  /**
   * Whether the user has permissions to view Sq Online settings.
   * If there are multiple Sq Online sites, only the permissions
   * from the first setting are read.
   */
  get hasSqOnlinePermissions(): boolean {
    return (
      this.sqOnlineSettings[0]?.pluginEnabled?.appliesToCurrentEmployee || false
    );
  }

  /**
   * Updates the Sq Online setting in local state.
   * Throws an error if the setting is not found.
   *
   * @param {MessengerSettings.ISquareOnlineWebsiteSetting} setting
   */
  updateSqOnlineSettingOnSave = (
    setting: MessengerSettings.ISquareOnlineWebsiteSetting,
  ): void => {
    const settingIndex = this.sqOnlineSettings.findIndex(
      (s) => s.id === setting.id,
    );
    if (settingIndex !== -1) {
      this.sqOnlineSettings[settingIndex] = setting;
    } else {
      throw new Error('No Sq Online setting found to update.');
    }
  };
}

export default SettingsStore;
