import {
  CheckConsentRequest,
  ConsentResult,
  ConversationPreviewRequest,
  ConversationPreviewResponse,
  MessagesConfig,
  SendMessageRequest as SDKSendMessageRequest,
  SendMessageResponse as SDKSendMessageResponse,
} from './SdkTypes';
import {
  CheckConsentResponse,
  ConsentStatus,
} from './gen/squareup/messenger/v2/messenger_service';
import {
  ITranscript,
  Medium,
  SendMessageResponse,
  Status,
  Utterance,
} from './gen/squareup/messenger/v3/messenger_service';
import Logger from './Logger';
import {
  checkoutLinkGraphQLToProto,
  protoTranscriptToSDKConversation,
} from './utils/transcriptUtils';
import { when } from 'mobx';
import type MessengerController from './MessengerController';
import { throwError } from './utils/handleResponseStatus';
import Api from './api/Api';

/**
 * This defines the max initialization time for the SDK dependencies, after which
 * initialization is considered to be failed. This time is quite high in order to
 * prevent false positives.
 */
const DEFAULT_SDK_INIT_TIMEOUT = 45000;

/**
 * Check for indications of a suspicious SDK call, such as a merchant directly calling the api,
 * and if detected log the information to sentry.  This is currently only called for the
 * sendMessage SDK api.
 *
 * @param {?} request The request payload sent to the sdk.
 * @param {string} merchantToken The merchant token of the currently logged in merchant.
 */
function logSuspiciousSDKCall(request: unknown, merchantToken: string): void {
  const url = window.location.href;
  const message = 'SDK called from suspicious url';
  const stack = new Error(message).stack || '';
  const lines = stack.split('\n');
  // We only expect to get sendMessage requests from square online.
  // TODO(azhao): currently only sendMessage is logged, but this should be updated if we start
  // tracking other apis.
  // TODO(lfan): during transition phase of app domain separation work, we should monitor URLS
  // both with and without "app". After the work is complete (scheduled EoY 2022), we can remove
  // the URLs without "app", as they would no longer be valid.
  if (
    !url.startsWith(
      'https://squareupstaging.com/dashboard/ecom/online-checkout',
    ) &&
    !url.startsWith('https://squareup.com/dashboard/ecom/online-checkout') &&
    !url.startsWith('https://squareupstaging.com/dashboard/items/library') &&
    !url.startsWith('https://squareup.com/dashboard/items/library') &&
    !url.startsWith('https://squareupstaging.com/dashboard/plans') &&
    !url.startsWith('https://squareup.com/dashboard/plans') &&
    !url.startsWith(
      'https://app.squareupstaging.com/dashboard/ecom/online-checkout',
    ) &&
    !url.startsWith(
      'https://app.squareup.com/dashboard/ecom/online-checkout',
    ) &&
    !url.startsWith(
      'https://app.squareupstaging.com/dashboard/items/library',
    ) &&
    !url.startsWith('https://app.squareup.com/dashboard/items/library') &&
    !url.startsWith('https://app.squareupstaging.com/dashboard/plans') &&
    !url.startsWith('https://app.squareup.com/dashboard/plans')
  ) {
    // The error message is logged to the console as well as sentry. We log a non-alarming message
    // to avoid giving away our tracking.
    Logger.logWithSentry(message, 'warning', {
      message,
      request,
      url,
      stack,
      merchantToken,
    });
  }
  // Suspicious conditions: stack indicates debugger, too short stack.
  else if (
    lines.length < 5 || // too short stack
    lines[lines.length - 1].includes('anonymous') || // chrome debugger
    lines[lines.length - 1].includes('debugger eval code') // ff debugger
  ) {
    Logger.logWithSentry('SDK called with suspicious stack trace', 'warning', {
      message: 'SDK called with suspicious stack trace',
      request,
      url,
      stack,
      merchantToken,
    });
  }
}

export default class MessagesSDKController {
  /**
   * The global MessengerController instance, used by the
   * main React app and the SDK
   */
  private _stores: MessengerController;
  private _api: Api;

  /**
   * An optional function that's called
   */
  _onInit?: ((config: MessagesConfig) => void) | undefined;

  /**
   * Construct a controller to handle requests coming from the
   * SDK, e.g. getConversationPreview. Relies on a MessengerController
   * instance to make the actual requests.
   *
   * @param {MessengerController} stores - Top level root MessengerController
   * associated with the React app.
   * @param {(MessagesConfig) => void} [onInit] - a function to
   * call when {@link MessagesSDKController#init} is called and the
   * API layer has registered all the config that the SDK needs.
   */
  constructor(
    stores: MessengerController,
    onInit?: (config: MessagesConfig) => void,
  ) {
    this._stores = stores;
    this._api = stores.api;
    this._onInit = onInit;
  }

  /**
   * Receive a config and update any corresponding variables in
   * {@link MessengerController}.
   *
   * @param {MessagesConfig} config - auth token, permissions, language,
   * and other current user information
   * @returns {void}
   */
  init = async (config: MessagesConfig): Promise<void> => {
    await this._isAppInitialized();

    // TODO(eblaine): add back locale when dashboard header respect
    if (config.currencyCode) {
      this._stores.user.setCurrencyCode(config.currencyCode);
    }
    if (config.merchantToken) {
      this._stores.user.setMerchantToken(config.merchantToken);
    }
    if (config.timezone) {
      this._stores.user.setTimezone(config.timezone);
    }

    const csrfToken = config?.authorization?.token;
    if (csrfToken) {
      this._stores.services.csrfToken = Promise.resolve(csrfToken);
    }

    if (this._onInit) {
      this._onInit(config);
    }
  };

  /**
   * Get a conversation with a preview utterance, in the format of an SDK Conversation.
   * See src/SdkTypes.ts for reference.
   *
   * @param {ConversationPreviewRequest} request - a rolodex customer token
   * to call GetConversations with
   * @returns {Promise<ConversationPreviewResponse>}
   */
  getConversationPreview = async ({
    customerId,
  }: ConversationPreviewRequest): Promise<ConversationPreviewResponse> => {
    await this._isAppInitialized();
    this._stores.event.track('Use SDK getConversationPreview');

    const [transcripts] = await this._api.transcripts.getTranscripts({
      customerToken: customerId,
      pageSize: 1,
    });
    const transcript: ITranscript = transcripts?.[0];

    if (!transcript || !transcript?.id) {
      // Empty transcript: no messages ever exchanged with this customer
      return {
        conversation: null,
      };
    }

    const sdkConversation = protoTranscriptToSDKConversation(transcript);
    if (sdkConversation == null) {
      throw new Error(
        `No preview utterance for transcript ${JSON.stringify(transcript)}`,
      );
    }

    return {
      conversation: sdkConversation,
    };
  };

  /**
   * The SDK version of sendMessage. Accepts a subset of the request
   * types that the main sendMessage allows (only sends on (medium, customer,
   * unit) tuple) and returns nothing.
   *
   * @param {SDKSendMessageRequest} request - send by customer token. also note
   * that speakerType is specified in string form.
   * @returns {Promise<void>}
   */
  sendMessage = async (
    request: SDKSendMessageRequest,
  ): Promise<SDKSendMessageResponse> => {
    await this._isAppInitialized();
    this._stores.event.track('Use SDK sendMessage');
    logSuspiciousSDKCall(request, this._stores.user.merchantToken);
    this._stores.session.isUsingMessages = true;
    const {
      customerId,
      medium,
      unitToken,
      text,
      checkoutLink,
      confirmedConsent,
    } = request as unknown as SDKSendMessageRequest;
    let mediumProto;
    if (medium.toLowerCase() === 'sms') {
      mediumProto = Medium.SMS;
    } else if (medium.toLowerCase() === 'email') {
      mediumProto = Medium.EMAIL;
    } else {
      throw new Error(`unknown medium ${medium}`);
    }
    let metadata: Utterance.IMetadata | undefined = undefined;
    if (checkoutLink) {
      metadata = {
        checkoutLink: checkoutLinkGraphQLToProto(checkoutLink),
      };
      // TEMPORARY CODE: This is here to investigate a backend SendMessage error. This can be
      // removed once we sufficiently understand the issue and have enough info to decide on a path
      // forward.
      // https://sentry.io/organizations/square-inc/issues/2455941428/?environment=production&project=5552661&query=is%3Aunresolved
      // Ensure checkout link is well-formed. If not, log to Sentry as this is likely
      // an improper use of the SDK.
      if (
        !metadata.checkoutLink?.url ||
        !metadata.checkoutLink?.id ||
        !metadata.checkoutLink?.type
      ) {
        const message =
          'SDK Checkout Link missing required fields. Type, URL, and ID are all required';
        Logger.logWithSentry(message, 'error', {
          checkoutLink: JSON.stringify(metadata.checkoutLink, null, 2),
          href: window.location.href,
          stack: new Error(message).stack || '',
        });
      }
    }

    return this._api.messaging
      .sendMessage({
        id: {
          customerToken: customerId,
          medium: mediumProto,
          sellerKey: unitToken,
        },
        utterance: {
          plainText: text,
          metadata,
        },
        confirmedConsent: Boolean(confirmedConsent),
        stagedAttachments: [],
      })
      .then(() => {
        return {
          status: {
            code: 'SUCCESS',
          },
        } as SDKSendMessageResponse;
      })
      .catch((response: SendMessageResponse) => {
        // Convert response to SDKSendMessageResponse
        let code = 'FAILED';
        if (
          response.status?.code === Status.Code.CONSENT_DENIED ||
          response.status?.code === Status.Code.CONSENT_DENIED_BY_AI
        ) {
          code = 'CONSENT_DENIED';
        }
        return {
          status: { code },
        } as SDKSendMessageResponse;
      });
  };

  /**
   * Check the consent for a unit. For now, this is mocked while we wait
   * on the backend to complete this.
   *
   * @param {CheckConsentRequest} request
   * The medium, customer, and optionally unit to check consent for
   * @returns {Promise<ConsentResult | null>}
   */
  checkConsent = async (
    request: CheckConsentRequest,
  ): Promise<ConsentResult | null> => {
    await this._isAppInitialized();
    this._stores.event.track('Use SDK checkConsent');
    const medium = request.medium === 'SMS' ? Medium.SMS : Medium.EMAIL;
    return this._stores.api.messaging
      .checkConsent({
        unitToken: request.unitToken,
        medium,
        customerToken: request.customerId,
      })
      .then((res: CheckConsentResponse) => {
        let consentStatus;
        switch (res.consentStatus) {
          case ConsentStatus.GRANTED_MARKETING:
            consentStatus = 'GRANTED_MARKETING';
            break;
          case ConsentStatus.GRANTED_TRANSACTIONAL:
            consentStatus = 'GRANTED_TRANSACTIONAL';
            break;
          case ConsentStatus.GRANTED_BY_DEFAULT:
            consentStatus = 'GRANTED_BY_DEFAULT';
            break;
          case ConsentStatus.DENIED_BY_DEFAULT:
            consentStatus = 'DENIED_BY_DEFAULT';
            break;
          case ConsentStatus.DENIED_CONSENT_REQUESTED:
            consentStatus = 'DENIED_CONSENT_REQUESTED';
            break;
          case ConsentStatus.DENIED_BY_AI:
            consentStatus = 'DENIED_BY_AI';
            break;
          case ConsentStatus.DENIED_DUE_TO_ERROR:
            consentStatus = 'DENIED_DUE_TO_ERROR';
            break;
          case ConsentStatus.REVOKED:
            consentStatus = 'REVOKED';
            break;
          default:
            consentStatus = 'DENIED_DUE_TO_ERROR';
        }
        return {
          consentStatus,
          // NOTE(eblaine): If there's no consent copy, wirejs will populate the empty string.
          // This line ensures that either we have a consent copy or the sellerConsentCopy field
          // is absent.
          sellerConsentCopy: res.sellerConsentCopy || undefined,
        } as ConsentResult;
      });
  };

  /**
   * The SDK version of GetUnreadCount. This is a thin wrapper for consistency
   * with the other endpoints.
   */
  getUnreadCount = async (): Promise<number> => {
    await this._isAppInitialized();
    return this._api.transcripts.getUnreadTranscriptsCount(
      this._stores.user.merchantToken,
    );
  };

  /**
   * Returns a promise that resolves when the app is initialized.
   */
  _isAppInitialized = async (): Promise<void> => {
    try {
      await when(() => this._stores.user.isInitialized, {
        timeout: DEFAULT_SDK_INIT_TIMEOUT,
      });
      await when(() => this._stores.featureFlag.isInitialized, {
        timeout: DEFAULT_SDK_INIT_TIMEOUT,
      });
    } catch (error) {
      throwError(
        'MessagesSDKController:_isAppInitialized - Initialization of the Messages app timed out.',
        { error },
      );
    }
    const { status, isAuthenticated, isAuthorized } = this._stores.user;
    if (status === 'ERROR' && isAuthenticated && isAuthorized) {
      // Errors other than the user being unauthenticated or unauthorized are unexpected.
      throwError(
        'MessagesSDKController:_isAppInitialized - Failed to load user meta data.',
      );
    }
  };
}
