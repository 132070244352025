/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "payments": {
        "nested": {
          "attributes": {
            "nested": {
              "model": {
                "nested": {
                  "AttributeTypes": {
                    "nested": {
                      "AttributeType": {
                        "values": {
                          "INVALID": 0,
                          "MERCHANT_TYPE": 1,
                          "SMCC_TERMINAL_ID": 6,
                          "JCB_TERMINAL_ID": 7,
                          "JCB_MERCHANT_NUMBER": 8,
                          "EVERLINK_TERMINAL_ID": 9,
                          "GATEWAY_PREFERENCE": 10,
                          "COMMERCIAL_ENTITY_CHASE": 11,
                          "COMMERCIAL_ENTITY_WELLS": 12,
                          "TRANSACTION_MIN_CENTS": 13,
                          "LOW_RISK": 14,
                          "CONTEXTUAL_ROUTING": 15,
                          "FELICA_TERMINAL_ID": 16,
                          "FELICA_BRAND_PERMISSIONS": 17,
                          "EXCLUDE_L3": 18,
                          "ROUTING_OVERRIDE": 19,
                          "AMEX_SELLER_ID": 20,
                          "METI_MERCHANT_ID": 21,
                          "METI_MERCHANT_BUSINESS_ID": 22,
                          "CHECK_BLOCKLIST": 23,
                          "RISK_MANAGER_ENABLED": 24,
                          "TRIGGERED_RESERVE": 25,
                          "AMEX_SE": 26,
                          "RISK_MANAGER_STATUS": 27,
                          "UK_CNP_REPRICING_OPT_IN": 28,
                          "INVOICE_ACH_CAPPED_PRICE": 29,
                          "EFTPOS_EXTERNAL_ID": 30,
                          "VISA_EXTERNAL_ID": 31,
                          "MASTERCARD_EXTERNAL_ID": 32,
                          "FNS_ID": 33,
                          "ALIPAY_EXTERNAL_ID": 34,
                          "AFTERPAY_ONBOARDING_ID": 35,
                          "CA_CANNABIS_MERCHANT": 36,
                          "UNLINKED_REFUNDS_PERMISSION": 37,
                          "AFTERPAY_ONLINE_PERMISSION": 38,
                          "ATTP_EXTERNAL_ID": 39,
                          "CASHAPPPAY_ONBOARDING_ID": 40,
                          "CASHAPPPAY_ONLINE_PERMISSION": 41,
                          "AFTERPAY_IN_PERSON_PERMISSION": 42,
                          "VISA_SMIRF_INELIGIBLE": 43,
                          "PAYPAY_MERCHANT_ID": 44,
                          "PAYPAY_STORE_ID": 45,
                          "PAYPAY_IN_STORE_PERMISSION": 46,
                          "MASTERCARD_SBIP_ELIGIBLE": 47,
                          "AMEX_OPT_BLUE_SE": 48,
                          "DGFT_MERCHANT_CCID": 49,
                          "DGFT_STORE_ID": 50,
                          "DGFT_ALIPAY_PERMISSION": 52,
                          "DGFT_AU_PAY_PERMISSION": 53,
                          "DGFT_D_BARAI_PERMISSION": 54,
                          "DGFT_MERPAY_PERMISSION": 55,
                          "DGFT_PAYPAY_PERMISSION": 56,
                          "DGFT_RAKUTEN_PAY_PERMISSION": 57,
                          "DGFT_WECHAT_PAY_PERMISSION": 58,
                          "PAYMENT_LIMIT_ENABLED": 59,
                          "UNIQUE_MID": 60,
                          "BYPASS_UNLINKED_REFUNDS_TWO_FACTOR_AUTH_REQUIREMENT": 61,
                          "MASTERCARD_SBIP_INELIGIBLE_DEBIT_PROGRAM": 62,
                          "DISCOVER_MERCHANT_NUMBER": 63,
                          "VISA_SMIRF_CA_INELIGIBLE": 64,
                          "MASTERCARD_SMIRF_CA_INELIGIBLE": 65,
                          "DISCOVER_EXTERNAL_ID": 66,
                          "AMEX_CHASE_SE": 2,
                          "AMEX_CHASE_CE_SE": 3,
                          "AMEX_WELLS_SE": 4,
                          "AMEX_WELLS_CE_SE": 5
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.payments?.nested?.attributes?.nested?.model?.nested?.AttributeTypes?.nested?.AttributeType) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE AttributeTypes @ squareup/payments/attributes/model/model.proto at 14:1
 */
export const AttributeTypes = {};
/**
 * SOURCE AttributeType @ squareup/payments/attributes/model/model.proto at 15:3
 */
AttributeTypes.AttributeType = $root.lookupEnum('squareup.payments.attributes.model.AttributeTypes.AttributeType').values;
