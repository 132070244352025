import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { renderDate } from 'src/utils/timeUtils';
import {
  MarketButton,
  MarketField,
  MarketInputText,
  MarketList,
  MarketRow,
  MarketSelect,
} from 'src/components/Market';
import InputMoney from 'src/components/InputMoney/InputMoney';
import { amountToCurrencyString } from 'src/utils/moneyUtils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Coupon } from 'src/MessengerTypes';
import Logger from 'src/Logger';
import { SHADOW_REACT_ROOT_SELECTOR } from 'src/utils/shadowDomUtils';
import './SendCouponModal.scss';

const COUPON_MAX_AMOUNT = 99999999; // in cents
const COUPON_MAX_PERCENTAGE = 100;
type Mode = 'dollar' | 'percentage';

/**
 * A modal that allows a user-entered fixed priced discount or percentage coupon to be sent.
 *
 * @example
 * Basic usage:
 * <SendCouponModal />
 * @author klim
 */
const SendCouponModal = observer((): ReactElement => {
  const { t } = useTranslation();
  const { user, modal, event, status, transcriptView, api } =
    useMessengerControllerContext();
  const { closeModal: close, couponMetadata } = modal;
  const { merchantToken, locale, currencyCode, currencySymbol } = user;

  const [mode, setMode] = useState<Mode>(
    couponMetadata?.type === 'PERCENTAGE' ? 'percentage' : 'dollar',
  );
  const [amount, setAmount] = useState(couponMetadata?.value ?? 0);
  const [hasError, setHasError] = useState(false);

  // Note(klim): we can only stub Date.now in test, hence new Date() have to be
  // used with Date.now() so that test can pass
  const dateOneYearFromNow = new Date(Date.now());
  dateOneYearFromNow.setFullYear(dateOneYearFromNow.getFullYear() + 1);

  // States and wrapper function to handle loading indicator and errors.
  const [isSending, setIsSending] = useState(false);
  const createAndSendCoupon = async (): Promise<void> => {
    setHasError(false);
    if (
      (mode === 'dollar' && amount > 0 && amount < COUPON_MAX_AMOUNT) ||
      (mode === 'percentage' && amount > 0 && amount < COUPON_MAX_PERCENTAGE)
    ) {
      event.track('Click Send Coupon Modal Send Coupon');
      setIsSending(true);

      let isConsentConfirmed;
      try {
        isConsentConfirmed = await transcriptView.confirmConsent();
      } catch {
        // Consent was explicitly denied, return without sending the message
        setIsSending(false);
        return;
      }
      try {
        const coupon: Coupon = await api.messaging.createCoupon({
          merchantToken,
          customerToken: transcriptView.transcript.customerToken || undefined,
          data:
            mode === 'dollar'
              ? {
                  amount,
                  currencyCode,
                }
              : {
                  percentage: amount,
                },
        });

        transcriptView.sendMessage({
          message: coupon.name ?? '',
          metadata: {
            coupon,
          },
          isConsentConfirmed,
        });

        close();
      } catch (error) {
        Logger.logWithSentry(
          'SendCouponModal:createAndSendCoupon - Error creating and sending a coupon.',
          'error',
          {
            merchantToken,
            error,
          },
        );
        status.setModalError();
      }
      setIsSending(false);
    } else {
      setHasError(true);
    }
  };

  const isDisabled = amount === 0;

  return (
    <MessengerModalPartial
      title={t('SendCouponModal.title')}
      close={() => {
        event.track('Click Send Coupon Modal Dismiss');
        close();
      }}
      primaryButton={
        <MarketButton
          onClick={createAndSendCoupon}
          isLoading={isSending || undefined}
          disabled={isDisabled || undefined}
        >
          {t('common.send')}
        </MarketButton>
      }
      status={status.value}
    >
      <p className="paragraph-30 SendCouponModal_description">
        {t('SendCouponModal.description')}
      </p>
      <form
        className="market-grid-container"
        onSubmit={(event) => {
          event.preventDefault();
          if (!isSending && !isDisabled) {
            createAndSendCoupon();
          }
        }}
      >
        <MarketSelect
          className="market-grid-item-full"
          value={mode}
          onMarketSelectValueDidChange={(event) => {
            setMode(event.detail.value as Mode);
            setAmount(0);
            setHasError(false);
          }}
          onClick={(e) => e.stopPropagation()}
          data-testid="SendCouponModal__select"
          popoverContainer={SHADOW_REACT_ROOT_SELECTOR}
        >
          <label>{t('SendCouponModal.type')}</label>
          <MarketList slot="list">
            <MarketRow value="dollar">
              {t('SendCouponModal.amount', { symbol: currencySymbol })}
            </MarketRow>
            <MarketRow value="percentage">
              {t('SendCouponModal.percentage')}
            </MarketRow>
          </MarketList>
        </MarketSelect>
        <MarketField
          className="market-grid-item-full"
          invalid={hasError}
          data-testid="SendCouponModal__input"
        >
          {mode === 'dollar' ? (
            <InputMoney
              label={t('SendCouponModal.amount_placeholder')}
              currencyCode={currencyCode}
              locale={locale}
              onChange={setAmount}
              initialAmount={amount || undefined}
            />
          ) : (
            <MarketInputText value={amount ? amount.toString() : ''}>
              <label>{t('SendCouponModal.percentage_placeholder')}</label>
              <input
                slot="input"
                value={amount ? amount.toString() : ''}
                onChange={(e) => {
                  setAmount(Number.parseInt(e.target.value, 10));
                }}
                type="number"
                data-testid="SendCouponModal__percentage-input"
              />
            </MarketInputText>
          )}
          <small slot="error">
            {t('SendCouponModal.error_amount', {
              min:
                mode === 'dollar'
                  ? amountToCurrencyString(0, currencyCode, locale)
                  : '0',
              max:
                mode === 'dollar'
                  ? amountToCurrencyString(
                      COUPON_MAX_AMOUNT,
                      currencyCode,
                      locale,
                    )
                  : COUPON_MAX_PERCENTAGE.toString(),
            })}
          </small>
        </MarketField>
      </form>
      <span className="SendCouponModal__footer">
        {t('SendCouponModal.footer', {
          expiration: renderDate(dateOneYearFromNow.getTime()),
        })}
      </span>
      {/* The dropdown does not 'pop out' of the modal, so we add abit of space for the time being */}
      {/* TODO(klim): [#3328] Remove this when we upgrade to Market 3.0 which will fix this */}
      <div style={{ height: '20px' }} />
    </MessengerModalPartial>
  );
});

export default SendCouponModal;
