import React, { ReactElement, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import MessageInput from './components/MessageInput/MessageInput';
import RequestConsentModal from './components/RequestConsentModal/RequestConsentModal';
import SendLimitModal from './components/SendLimitModal/SendLimitModal';
import PhotosGallery from './components/PhotosGallery/PhotosGallery';
import ConfirmBlockModal from './components/ConfirmBlockModal';
import MessageBlockedModal from './components/MessageBlockedModal';
import UnverifiedModal from './components/UnverifiedModal/UnverifiedModal';
import TranscriptViewSkeletonState from './components/TranscriptViewSkeletonState/TranscriptViewSkeletonState';
import useTranscriptErrorHandling from './hooks/useTranscriptErrorHandling';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import TranscriptViewItemsList from './components/TranscriptViewItemsList/TranscriptViewItemsList';
import TranscriptViewHeader from './components/TranscriptViewHeader/TranscriptViewHeader';
import { Transition } from 'src/MessengerTypes';
import OutageBanner from 'src/pages/TranscriptsListPage/components/OutageBanner/OutageBanner';
import UpgradeEmptyState from 'src/components/UpgradeEmptyState/UpgradeEmptyState';
import ProhibitedState from './components/ProhibitedState/ProhibitedState';
import ChatBubblesIcon from 'src/svgs/ChatBubblesIcon';
import CreateCustomerSuggestionModal from './components/CreateCustomerSuggestionModal/CreateCustomerSuggestionModal';
import PaymentAlternativesModal from './components/PaymentAlternativesModal/PaymentAlternativesModal';
import VerificationInProgressState from './components/VerificationInProgressState/VerificationInProgressState';
import { MarketEmptyState } from 'src/components/Market';
import {
  KEY_MESSAGES_PLUGIN_GA,
  KEY_MESSAGES_PLUGIN_ON_BBF,
} from 'src/stores/FeatureFlagStore';
import MessagesPluginOnBuyerBookingFlowLaunchBanner from 'src/components/MessagesPluginOnBuyerBookingFlowLaunchBanner/MessagesPluginOnBuyerBookingFlowLaunchBanner';
import useIsMobile from 'src/hooks/useIsMobile';
import './TranscriptViewPage.scss';
import FileUnsupportedFormatModal from './components/FileUnsupportedFormatModal/FileUnsupportedFormatModal';
import FileVirusDetectedModal from './components/FileVirusDetectedModal/FileVirusDetectedModal';
import BlockedEmptyState from './components/BlockedEmptyState/BlockedEmptyState';
import VerificationFailedRetryableState from './components/VerificationFailedRetryableState/VerificationFailedRetryableState';

export type TranscriptViewPageProps = {
  transitionDirection: Transition;
};

/**
 * Displays all the utterances and pins a MessageInput
 * at the bottom if the user is able to write messages.
 *
 * @example <TranscriptViewPage transitionDirection="RIGHT" />
 * @param {boolean} transitionDirection
 * Determines which direction the content slides in from.
 */
const TranscriptViewPage = observer(
  ({ transitionDirection }: TranscriptViewPageProps): ReactElement => {
    const { t } = useTranslation();
    const {
      transcriptView,
      navigation,
      modal,
      status,
      user,
      event,
      subscription,
      featureFlag,
      tooltip,
    } = useMessengerControllerContext();
    const isMobile = useIsMobile();
    const {
      transcript,
      onTransitionEnd,
      isLoading,
      title,
      isMissingSubscription,
      isPendingOrFailedNonretryableNotProhibited,
      isFailedRetryable,
    } = transcriptView;

    // The ref to the photo/file input, used in MessageInput and Suggestions
    const photoInputRef = useRef<HTMLInputElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    // Display an error if selectedTranscriptId is missing or if the transcript failed to load
    useTranscriptErrorHandling();

    // Set up main content of the page
    let content;
    if (transcript.viewItems.length === 0) {
      if (isMissingSubscription && subscription.isProhibited) {
        content = <ProhibitedState />;
      } else if (isFailedRetryable) {
        content = (
          <VerificationFailedRetryableState
            unitTokens={[transcript.sellerKey]}
          />
        );
      } else if (isPendingOrFailedNonretryableNotProhibited) {
        content = <VerificationInProgressState />;
      } else if (isMissingSubscription) {
        content = <UpgradeEmptyState unitToken={transcript.sellerKey} />;
      } else if (transcript.isBlocked) {
        content = <BlockedEmptyState />;
      } else {
        content = (
          <div className="TranscriptViewPage__null-state">
            <MarketEmptyState>
              <div slot="media">
                <ChatBubblesIcon />
              </div>
              <h3 slot="primary-text">
                {t('TranscriptViewPage.null_state', {
                  name: title,
                })}
              </h3>
            </MarketEmptyState>
          </div>
        );
      }
    } else {
      content = <TranscriptViewItemsList photoInputRef={photoInputRef} />;
    }

    return (
      <>
        {title && <TranscriptViewHeader />}
        <MessengerContent
          transitionDirection={transitionDirection}
          onTransitionEnd={onTransitionEnd}
          // Hide status on the full page as otherwise both views on the full page show separate status banners
          status={!navigation.secondary.isOpen ? status.value : null}
        >
          <div className="TranscriptViewPage" data-testid="TranscriptViewPage">
            {!navigation.secondary.isOpen && <OutageBanner />}
            {isLoading ? (
              <div className="TranscriptViewPage__skeleton-state-container">
                <TranscriptViewSkeletonState showHeader={!title} />
              </div>
            ) : (
              content
            )}
            <MessageInput
              photoInputRef={photoInputRef}
              fileInputRef={fileInputRef}
            />
          </div>
          {modal.currentModal === 'CREATE_CUSTOMER_SUGGESTION' && (
            <CreateCustomerSuggestionModal />
          )}
          {modal.currentModal === 'REQUEST_CONSENT' && <RequestConsentModal />}
          {modal.currentModal === 'SEND_LIMIT' && <SendLimitModal />}
          {modal.currentModal === 'UNVERIFIED_IDENTITY' && <UnverifiedModal />}
          {modal.currentModal === 'PHOTOS_GALLERY' && <PhotosGallery />}
          {modal.currentModal === 'CONFIRM_BLOCK' && (
            <ConfirmBlockModal
              closeModal={modal.closeModal}
              onConfirm={() => {
                event.track('Click Confirm Block', {
                  merchant_token: user.merchantToken,
                  transcript_ids: [transcript.id],
                });
              }}
            />
          )}
          {modal.currentModal === 'MESSAGE_BLOCKED' && (
            <MessageBlockedModal closeModal={modal.closeModal} />
          )}
          {modal.currentModal === 'PAYMENT_ALTERNATIVES' && (
            <PaymentAlternativesModal />
          )}
          {modal.currentModal === 'FILE_UNSUPPORTED_FORMAT' && (
            <FileUnsupportedFormatModal />
          )}
          {modal.currentModal === 'FILE_VIRUS_DETECTED' && (
            <FileVirusDetectedModal />
          )}
          {(featureFlag.get(KEY_MESSAGES_PLUGIN_ON_BBF) ||
            featureFlag.get(KEY_MESSAGES_PLUGIN_GA)) &&
            (isMobile || navigation.application === 'BLADE') &&
            transcript.viewItems.some(
              (viewItem) => viewItem.dataType === 'MESSAGES_PLUGIN_SUBMISSION',
            ) &&
            tooltip.isVisible('MESSAGES_PLUGIN_ON_BBF_LAUNCH_BANNER') && (
              <MessagesPluginOnBuyerBookingFlowLaunchBanner />
            )}
        </MessengerContent>
      </>
    );
  },
);

export default TranscriptViewPage;
