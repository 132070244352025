import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MarketButton, MarketHeader } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  MergeCustomersMessengerPage,
  Status,
  Transition,
} from 'src/MessengerTypes';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import BackIcon from 'src/svgs/MarketBackIcon';
import { useTranslation } from 'react-i18next';
import CustomersList from './components/CustomersList/CustomersList';
import MergeCustomersSkeletonState from './components/MergeCustomersSkeletonState/MergeCustomersSkeletonState';
import './MergeCustomersPage.scss';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { i18nPhoneNumber } from 'src/i18n/formatValues';
import MergeCustomersBanner from './components/MergeCustomersBanner/MergeCustomersBanner';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

export type MergeCustomersPageProps = {
  transitionDirection: Transition;
};

/**
 * Page used as a disambiguation screen that allows the user to select an individual customer
 * to view the details of, when there are multiple possible customers to choose from.
 */
const MergeCustomersPage = observer(
  ({ transitionDirection }: MergeCustomersPageProps): ReactElement | null => {
    const { navigation, status, transcripts, customers, user, tooltip } =
      useMessengerControllerContext();
    const { t } = useTranslation();

    const nav = navigation.tertiary.isOpen
      ? navigation.tertiary
      : navigation.primary;
    const page = nav.currentPage as MergeCustomersMessengerPage;

    if (!page || !page?.transcriptId) {
      return null;
    }

    const transcript = transcripts.get(page.transcriptId);
    const isLoading = transcript.customerDetailsStatus === 'LOADING';

    let statusValue: Status | null;
    if (transcript.customerDetailsStatus === 'ERROR') {
      statusValue = {
        scope: 'BLADE',
        display: 'SCREEN',
        type: 'ERROR',
        label: t('common.error.try_again'),
        action: {
          action: transcript.loadCustomers,
        },
        actionLabel: t('common.retry'),
      };
    } else {
      // Hide status on the full page as otherwise both views on the full page show separate status banners
      statusValue = !navigation.secondary.isOpen ? status.value : null;
    }

    return (
      <>
        <MarketHeader
          className="MessagesPageHeader MergeCustomersPage__header"
          compact
        >
          {nav.canNavigateBack && (
            <MarketButton
              slot="navigation"
              aria-label={t('common.back')}
              onClick={nav.navigateBack}
            >
              <BackIcon slot="icon" />
            </MarketButton>
          )}
          {navigation.tertiary.isOpen ? (
            <h3>
              {t('MergeCustomersPage.title', {
                medium:
                  transcript.medium === Medium.EMAIL
                    ? t('common.mediumLongForm.email')
                    : t('common.mediumLongForm.sms'),
              })}
            </h3>
          ) : (
            <h3>
              {transcript.medium === Medium.SMS
                ? i18nPhoneNumber(transcript.contactId || '', user.countryCode)
                : transcript.contactId}
            </h3>
          )}
        </MarketHeader>
        <MessengerContent
          transitionDirection={transitionDirection}
          isLoading={isLoading}
          loadingIndicator={<MergeCustomersSkeletonState />}
          status={statusValue}
        >
          <div className="MergeCustomersPage__content-container">
            {!navigation.tertiary.isOpen && (
              <h3>
                {t('MergeCustomersPage.title', {
                  medium:
                    transcript.medium === Medium.EMAIL
                      ? t('common.mediumLongForm.email')
                      : t('common.mediumLongForm.sms'),
                })}
              </h3>
            )}
            {tooltip.isVisible('MERGE_CUSTOMERS') && (
              <MergeCustomersBanner contactId={transcript.contactId || ''} />
            )}
            <CustomersList
              customers={
                transcript.customerTokens
                  .map((token) => customers.get(token))
                  .filter((contact) => contact !== undefined) as Contact[]
              }
            />
          </div>
        </MessengerContent>
      </>
    );
  },
);

export default MergeCustomersPage;
